<!-- メイン領域-->
<mat-drawer-container class="main-container" autosize>
  <div class="top">自分の回覧</div>
  <!-- 一覧画面表示領域-->
  <p-table
    #table
    [value]="searchResultsList"
    [paginator]="true"
    [rows]="10"
    [showCurrentPageReport]="true"
    currentPageReportTemplate="{first}~{last}件表示({totalRecords}件中)"
    [rowsPerPageOptions]="[10, 50, 100, 500, 1000]"
    styleClass="p-datatable-striped summary-list"
    selectionMode="single"
    dataKey="item_id"
  >
    <!-- 一覧画面ヘッダー表示-->
    <ng-template pTemplate="header">
      <tr class="p-col-2">
        <!-- APIヘッダーの表示 -->
        <ng-container *ngFor="let column of columnOrder">
          <th [pSortableColumn]="column.field"
          *ngIf="'P' != column.column_pkey;">
            {{ column.header }}
            <p-sortIcon [field]="column.field"></p-sortIcon>
          </th>
        </ng-container>

        <!-- 操作ヘッダーの表示 -->
        <th class="operation">
          <p>操作</p>
        </th>
      </tr>
    </ng-template>

    <!-- 一覧画面ボディ表示-->
    <ng-template pTemplate="body" let-searchResults>
      <tr>
        <!-- 一覧表示-->
        <ng-container *ngFor="let column of columnOrder">
          <!-- 一覧項目値表示 -->
            <td *ngIf="'P' != column.column_pkey;" [ngSwitch]="column?.input_type">
              <span *ngSwitchCase="'NUMBER'">
                {{ searchResults[column.field] | number }}
              </span>
              <span *ngSwitchDefault>
                {{ searchResults[column.field] }}
              </span>
            </td>
        </ng-container>

        <!-- 操作表示 -->
        <td>
          <!-- 編集ボタン -->
          <button
          pButton
          (click)="
            detail_circulation(
              searchResults.content_id,
              searchResults.circulation_id
            )
          "
          icon="pi pi-pencil"
          class="p-button-rounded p-button-success p-mr-2"
          pTooltip="編集"
          tooltipPosition="bottom"
          *ngIf="editButtonDisplayFlag(searchResults)"
          ></button>
        </td>
      </tr>
    </ng-template>
  </p-table>

</mat-drawer-container>

