import { Component, OnInit, ViewChild } from '@angular/core';
import { DbOperationService } from 'src/app/shared/service/db-operation.service';
import { API_URL_ITEM } from 'manager/http-constants_key';
import { SUMMARY_DISPLAY_TEMPLATE } from 'manager/template-constant';
import { LoadingState } from 'src/app/shared/html-parts/loading/loading-state';
import { CommonService } from 'src/app/shared/service/common.service';
import { SUMMARY_DISPLAY_CONSTANT } from './constant';
import { SummaryDisplayDetailComponent } from './summary-display-detail/summary-display-detail.component';
import { SummaryDisplayInputComponent } from './summary-display-input/summary-display-input.component';
import { ActivatedRoute, Router } from '@angular/router';
import {
  MessageData,
  ToastMessageData,
} from 'src/app/shared/html-parts/message-common/message-data';
import { TOAST } from 'src/app/shared/constant/primeng-constants';
import { MESSAGE_CODE } from 'src/app/shared/constant/message-constant';
import { SummaryDisplayService } from './summary-display.service';
import { SESSION_KEY } from 'src/app/shared/constant/session-constants';

@Component({
  selector: 'app-summary-display',
  templateUrl: './summary-display.component.html',
  styleUrls: ['./summary-display.component.scss'],
})

/**
 * サマリー詳細
 */
export class SummaryDisplayComponent implements OnInit {
  /** 詳細画面ダイアログ */
  @ViewChild(SummaryDisplayDetailComponent)
  summaryDisplayDetailComponent: SummaryDisplayDetailComponent;

  /** 入力画面ダイアログ */
  @ViewChild(SummaryDisplayInputComponent)
  summaryDisplayInputComponent: SummaryDisplayInputComponent;

  // 案件ID(クエリパラメータ)
  itemId: string = this.route.snapshot.queryParams.item_id;

  //案件作成者ID(クエリパラメータ)
  itemCreateUserId: string =
    this.route.snapshot.queryParams.item_create_user_id;

  // コンテンツID(クエリパラメータ)
  contentId: string = this.route.snapshot.queryParams.content_id;

  /* 画面用プロパティ */
  // サマリー目次情報格納先
  summaryDisplayList: any[] = new Array();
  // サマリー目次情報行出力数
  summaryRowDisplayNumber: number =
    SUMMARY_DISPLAY_CONSTANT.SUMMARY_ROW_DISPLAY_NUMBER;
  // サマリー目次情報列出力数
  summaryColDisplayNumber: number[] = new Array();

  // サマリーコンテンツリスト格納先
  summaryContentsList: any[] = new Array();

  //承認者リスト格納先
  approverList: any[] = new Array();

  //ログインユーザ情報
  loginUser = JSON.parse(
    window.sessionStorage.getItem(SESSION_KEY.loginUserInformation)
  );

  constructor(
    private dbOperationService: DbOperationService,
    private summaryDisplayService: SummaryDisplayService,
    private loadingState: LoadingState,
    private commonService: CommonService,
    private route: ActivatedRoute,
    private messageData: MessageData,
    private router: Router
  ) {}

  ngOnInit(): void {
    // 案件ID(クエリパラメータ)が存在するか否か
    if (!this.itemId) {
      // 案件ID(クエリパラメータ)が存在しない場合

      // 異常メッセージ
      this.messageData.toastMessage(
        new ToastMessageData({
          severity: TOAST.ERROR,
          summary: this.commonService.msg(MESSAGE_CODE.E00003),
          detail: this.commonService.msg(MESSAGE_CODE.E80004),
        })
      );

      // サマリーリスト画面へ遷移
      this.router.navigate(['pages/summary/summary-list']);
    }

    // 画面ロードフラグをON(ロード中状態)
    this.loadingState.loadStart();

    /* サマリー目次情報取得処理(画面用) */
    this.dbOperationService
      .getDisplayData(
        API_URL_ITEM,
        SUMMARY_DISPLAY_TEMPLATE.SUMMARY_TITLE_DISPLAY_TEMPLATE_ID,
        this.itemId
      )
      .subscribe((response) => {
        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadSleepEnd(0.3);

        // サマリー目次情報が存在するか否か
        if (this.commonService.checkNoneResponse(response)) {
          // サマリー目次情報が存在しない場合

          // 警告メッセージ
          this.messageData.toastMessage(
            new ToastMessageData({
              severity: TOAST.WARN,
              summary: this.commonService.msg(MESSAGE_CODE.E00002),
              detail: this.commonService.msg(
                MESSAGE_CODE.S00001,
                '対象の案件情報'
              ),
            })
          );

          // サマリーリスト画面へ遷移
          this.router.navigate(['pages/summary/summary-list']);

          return;
        }

        // サマリー目次情報の列出力数を計算
        // サマリー目次情報横出力数 = サマリー目次情報 / 設定値の切り上げ
        this.summaryColDisplayNumber = Array(
          Math.ceil(
            response.body.length /
              SUMMARY_DISPLAY_CONSTANT.SUMMARY_ROW_DISPLAY_NUMBER
          )
        )
          .fill(0)
          .map((x, i) => i);

        // サマリー目次情報のJSONをオブジェクトに格納する
        this.summaryDisplayList = response.body;
      });

    // コンテンツ情報リストを取得を実施
    this.getSummaryContentList();

    // 承認者リストを取得を実施
    this.getApprover();
  }

  /**
   * コンテンツ情報リストを取得
   */
  public getSummaryContentList() {
    // 画面ロードフラグをON(ロード中状態)
    this.loadingState.loadStart('getSummaryContentList');

    /* コンテンツリスト取得処理 */
    this.summaryDisplayService
      .getContentsList(this.itemId)
      .subscribe((response) => {
        // コンテンツリストが存在するか否か
        if (!this.commonService.checkNoneResponse(response)) {
          // コンテンツリストのJSONをオブジェクトに格納する
          this.summaryContentsList = response.body;
        } else {
          this.summaryContentsList = new Array();
        }
      });

    // 画面ロードフラグをOFF(ロード終了)
    this.loadingState.loadSleepEnd(0.3, 'getSummaryContentList');
  }

  /**
   * コンテンツ情報リストからコンテンツIDの情報を削除
   * @param contentId コンテンツID
   */
  public deleteSummaryContentList(contentId: string) {
    // サマリーコンテンツリストから該当データを削除
    this.summaryContentsList = this.commonService.deleteArrayObjectValue(
      this.summaryContentsList,
      SUMMARY_DISPLAY_CONSTANT.CONTENT_ID,
      contentId
    );
  }

  /**
   * コンテンツ情報リストから初期選択行を設定
   * @param contentId コンテンツID
   */
  protected selectedSummaryContentsList(contentId: string): boolean {
    // コンテンツID(クエリパラメータ)が存在するか否か
    if (!this.contentId) {
      // コンテンツID(クエリパラメータ)が存在しない場合

      // サマリーコンテンツリストの1行目を選択
      if (contentId == this.summaryContentsList[0].content_id) {
        return true;
      }
      // 2行目以降を非選択
      return false;
    }

    // コンテンツID(クエリパラメータ)がサマリーコンテンツリストに存在するか否か
    if (
      0 >
      this.commonService.getRowNumberArrayObject(
        this.summaryContentsList,
        SUMMARY_DISPLAY_CONSTANT.CONTENT_ID,
        this.contentId
      )
    ) {
      // コンテンツID(クエリパラメータ)がサマリーコンテンツリストに存在しない場合

      // サマリーコンテンツリストの1行目を選択
      if (contentId == this.summaryContentsList[0].content_id) {
        return true;
      }
      // 2行目以降を非選択
      return false;
    }

    // コンテンツID(クエリパラメータ)がサマリーコンテンツリストに存在する場合
    // コンテンツID(クエリパラメータ)とサマリーコンテンツリストが一致する行を選択
    if (contentId == this.contentId) {
      return true;
    }

    // 一致しない行を非選択
    return false;
  }

  /**
   * 戻るボタン
   */
  public return() {
    // サマリーリスト画面へ遷移
    this.router.navigate(['pages/summary/summary-list']);
  }

  /**
   * 詳細情報画面表示
   */
  protected detail() {
    // 詳細画面表示
    this.summaryDisplayDetailComponent.summaryDetail(this.itemId);
  }

  /**
   * コンテンツ登録ボタン出力表示判定
   * @returns true:ボタン表示、false:ボタン非表示
   */
  public insertContentDisplayFlag(): boolean {
    // 承認者リストにログインユーザが存在するかチェック
    const approver = this.approverList.find((user) => {
      return user.user_id === this.loginUser.user_id;
    });
    //権限チェック
    if (
      this.loginUser.admit === '1' ||
      this.loginUser.department_code === '99999' ||
      this.loginUser.user_id === this.itemCreateUserId ||
      approver
    ) {
      return true;
    }
    return false;
  }

  /**
   * コンテンツ登録ボタン
   */
  public insertContent() {
    // 入力画面表示
    this.summaryDisplayInputComponent.inputSummaryContent(this.itemId);
  }

  /**
   * 承認者を取得
   */
  public getApprover() {
    /* コンテンツリスト取得処理 */
    this.summaryDisplayService
      .getApprover(this.itemId)
      .subscribe((response) => {
        // 承認者リストが存在するか否か
        if (!this.commonService.checkNoneResponse(response)) {
          // 承認者リストのJSONをオブジェクトに格納する
          this.approverList = response.body;
        } else {
          this.approverList = new Array();
        }
      });
  }
}
