import { Component, OnInit } from '@angular/core';
import { LoadingState } from 'src/app/shared/html-parts/loading/loading-state';
import { DbOperationService } from 'src/app/shared/service/db-operation.service';
import { NOTICE_LIST_CONSTANT } from './constant';

@Component({
  selector: 'app-notice',
  templateUrl: './notice.component.html',
  styleUrls: ['./notice.component.scss'],
})

/**
 * お知らせカテゴリ画面
 */
export class NoticeComponent implements OnInit {
  // お知らせ種別の辞書情報格納先
  dicValuesList: any[] = new Array();

  constructor(
    private dbOperationService: DbOperationService,
    private loadingState: LoadingState
  ) {}

  ngOnInit(): void {
    // 画面ロードフラグをON(ロード中状態)
    this.loadingState.loadStart();

    /* お知らせ種別の辞書情報を取得 */

    // お知らせ種別の使用辞書番号で辞書情報を取得する
    this.dbOperationService
      .getDicValues(NOTICE_LIST_CONSTANT.SP_DIC_ID)
      .subscribe((response) => {
        // 辞書情報を格納
        this.dicValuesList = response.body[0].dic_value;

        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadEnd();
      });
  }
}
