<!-- 回覧ルート登録画面ダイアログ -->
<p-dialog header="回覧ルート登録" [(visible)]="inputModal" [modal]="true">
  <!-- 入力フォームページアンカー -->
  <a id="generateInputErrorTop"></a>
  <!-- 入力エラーメッセージ-->
  <app-generate-input-error-message></app-generate-input-error-message>
  <!-- メンバー入力フォーム -->
  <div class="groupInputForm" *ngIf="!inputFlag && !confirmationFlag">
    <form [formGroup]="generateInputForm">
      <!-- 入力情報出力領域 -->
      <p-table
        [value]="circulationInputList"
        styleClass="p-datatable-sm generate-input"
      >
        <!-- 入力情報出力箇所 -->
        <ng-template pTemplate="body" let-groupUserInputInformation>
          <!-- ピッカーリスト -->
          <tr>
            <td colspan="2">
              <br />
              <p-pickList
                [source]="pickListSource(groupUserInputInformation.column_id)"
                [target]="
                  generateInputForm.value[groupUserInputInformation.column_id]
                "
                [sourceHeader]="groupUserInputInformation.column_name"
                targetHeader="選択リスト"
                [dragdrop]="true"
                [showSourceControls]="false"
                [showTargetControls]="false"
                filterBy="group_name"
              >
                <ng-template let-code pTemplate="item">
                  <!-- ピッカーリストアイテム要素 -->
                  <div>
                    {{ code.group_name }}
                  </div>
                </ng-template>
              </p-pickList>
              <br />
            </td>
          </tr>
        </ng-template>
      </p-table>

      <!-- ボタンエリア -->
      <div class="buttonArea">
        <button
          class="button"
          type="submit"
          label="ルート選択"
          (click)="selectedRoot()"
          [disabled]="selectedRootButtonDisplayFlag()"
          pButton
        ></button>
      </div>
    </form>
  </div>

  <!-- 回覧ルート選択フォーム -->
  <div class="groupRootInputForm" *ngIf="inputFlag && !confirmationFlag">
    <form [formGroup]="addCirculationRootForm">
      <!-- 入力情報出力領域 -->
      <p-table
        [value]="circulationRootInputList"
        styleClass="p-datatable-sm generate-input"
      >
        <!-- 入力情報タイトル -->
        <ng-template pTemplate="header">
          <tr>
            <th>回覧グループ名</th>
            <th>前条件</th>
          </tr>
        </ng-template>
        <!-- 入力情報出力箇所 -->
        <ng-template pTemplate="body" let-groupRootInput>
          <!-- 子フォームグループ -->
          <tr [formGroupName]="groupRootInput.group_id">
            <!-- 名前 -->
            <td>
              {{ groupRootInput.group_name }}
            </td>
            <!-- 前条件選択 -->
            <td>
              <p-multiSelect
                [options]="
                  selectedCirculationRootMultiSelectList(
                    groupRootInput.group_id
                  )
                "
                defaultLabel="　"
                emptyFilterMessage="検索結果が存在しません"
                optionLabel="group_name"
                optionValue="group_id"
                appendTo="body"
                [styleClass]="
                  checkErrorItem(groupRootInput.group_id) ? 'errorItem' : ''
                "
                [formControl]="
                  addCirculationRootForm.controls[groupRootInput.group_id]
                    .controls.start_condition
                "
              >
              </p-multiSelect>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </form>

    <!-- ボタンエリア -->
    <div class="buttonArea">
      <button
        class="button"
        type="button"
        label="確認"
        (click)="checkCirculationRoot()"
        pButton
      ></button>
      <button
        class="button"
        type="button"
        label="戻る"
        (click)="returnSelectedCirculationGroup()"
        pButton
      ></button>
    </div>
  </div>

  <!-- 確認情報出力領域 -->
  <div class="groupRootInputConfirmation" *ngIf="confirmationFlag">
    <p-table [value]="circulationRootRequest" styleClass="p-datatable-sm">
      <!-- 確認情報タイトル -->
      <ng-template pTemplate="header">
        <tr>
          <th>回覧グループ名</th>
          <th>前条件</th>
        </tr>
      </ng-template>
      <!-- 確認情報出力箇所 -->
      <ng-template pTemplate="body" let-groupRootInput>
        <tr>
          <!-- 名前 -->
          <td>
            {{ groupRootInput.group_name }}
          </td>
          <!-- 前条件選択 -->
          <td>
            <!-- 前条件選択済みの場合 -->
            <a
              *ngFor="
                let start_condition of groupRootInput.start_condition;
                last as last
              "
            >
              {{ changeGroupName(start_condition) }}
              <a *ngIf="!last">、</a>
            </a>
            <!-- 前条件が未選択の場合 -->
            <a *ngIf="!groupRootInput.start_condition.length">
              最初の回覧グループとなります。
            </a>
          </td>
        </tr>
      </ng-template>
    </p-table>

    <!-- ボタンエリア -->
    <div class="buttonArea">
      <button
        class="button"
        type="button"
        label="登録"
        (click)="registCirculationRoot()"
        pButton
      ></button>
      <button
        class="button"
        type="button"
        label="戻る"
        (click)="confirmationFlag = false"
        pButton
      ></button>
    </div>
  </div>
</p-dialog>
