/**
 * セッションキー
 */
export const SESSION_KEY = {
  // ログインユーザ情報
  loginUserInformation: 'loginUserInformation',

  // TODO ログアウト時のメッセージコード格納に使用　※auth0が間に入り、いいパラメータ受け渡し方法が見つかるまで使用
  // ログインアウト時メッセージコード
  loginOutMessageCode: 'loginOutMessageCode',

  // 全辞書値情報
  dicList: 'dicList',

  // 数量画面テーブル状態
  quantityDataTable: 'quantityDataTable',
};
