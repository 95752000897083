import { Component, OnInit, ViewChild } from '@angular/core';
import { API_URL_CONTENTS, API_URL_ITEM } from 'manager/http-constants_key';
import { SUMMARY_DISPLAY_TEMPLATE } from 'manager/template-constant';
import { GenerateDisplay } from 'src/app/shared/generate/generate-display/generate-display-information/generate-display-information';
import { GenerateDisplayInformationComponent } from 'src/app/shared/generate/generate-display/generate-display-information/generate-display-information.component';

@Component({
  selector: 'app-summary-display-detail',
  templateUrl: './summary-display-detail.component.html',
  styleUrls: ['./summary-display-detail.component.scss'],
})

/**
 * サマリー詳細.詳細画面
 */
export class SummaryDisplayDetailComponent implements OnInit {
  /** 詳細情報 */
  @ViewChild(GenerateDisplayInformationComponent)
  generateDisplayInformationComponent: GenerateDisplayInformationComponent;

  /* 画面用プロパティ */
  // 詳細ダイアログ表示フラグ
  detailModal: boolean;

  constructor() {}

  ngOnInit(): void {}

  /**
   * サマリー詳細
   * @param itemId 案件ID
   */
  public summaryDetail(itemId: string) {
    // サマリー詳細情報表示
    let generateDisplay: GenerateDisplay = new GenerateDisplay();
    generateDisplay.endPoint = API_URL_ITEM;
    generateDisplay.title = '案件詳細';
    generateDisplay.templateId =
      SUMMARY_DISPLAY_TEMPLATE.SUMMARY_OUTPUT_TEMPLATE_ID;
    this.generateDisplayInformationComponent.initial(generateDisplay, itemId);

    // 詳細画面ダイアログを表示
    this.detailModal = true;
  }

  /**
   * サマリーコンテンツ詳細
   * @param contentId コンテンツID
   */
  public summaryContentDetail(contentId: string) {
    // サマリーコンテンツ詳細情報表示
    let generateDisplay: GenerateDisplay = new GenerateDisplay();
    generateDisplay.endPoint = API_URL_CONTENTS;
    generateDisplay.title = 'コンテンツ詳細';
    generateDisplay.templateId =
      SUMMARY_DISPLAY_TEMPLATE.SUMMARY_CONTENT_OUTPUT_TEMPLATE_ID;
    this.generateDisplayInformationComponent.initial(
      generateDisplay,
      contentId
    );

    // 詳細画面ダイアログを表示
    this.detailModal = true;
  }
}
