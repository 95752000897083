import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  API_URL_ROUTE_GROUPS,
  API_URL_ROUTE_GROUPS_CHECK_INPUT,
} from 'manager/http-constants_key';
import {
  API_RESPONSE,
  DISPLAY_INFORMATIONS_API_CONSTANT,
  INPUT_INFORMATIONS_API_CONSTANT,
} from 'src/app/shared/constant/api-constant';
import { MESSAGE_CODE } from 'src/app/shared/constant/message-constant';
import { GenerateInputErrorMessage } from 'src/app/shared/generate/generate-input/generate-input-information/generate-input-error-message/generate-input-error-message';
import { GenerateInputErrorMessageComponent } from 'src/app/shared/generate/generate-input/generate-input-information/generate-input-error-message/generate-input-error-message.component';
import { LoadingState } from 'src/app/shared/html-parts/loading/loading-state';
import { MessageData } from 'src/app/shared/html-parts/message-common/message-data';
import { CommonService } from 'src/app/shared/service/common.service';
import { DbOperationService } from 'src/app/shared/service/db-operation.service';
import { CirculationRootService } from '../circulation-root.service';
import { CIRCULATION_ROOT_CONSTANT } from '../constant';

@Component({
  selector: 'app-add-circulation-root',
  templateUrl: './add-circulation-root.component.html',
  styleUrls: ['./add-circulation-root.component.scss'],
})

/**
 * 回覧ルートグループ追加画面
 */
export class AddCirculationRootComponent implements OnInit {
  /** 入力エラーメッセージ */
  @ViewChild(GenerateInputErrorMessageComponent)
  generateInputErrorMessageComponent: GenerateInputErrorMessageComponent;

  // 画面.入力フォーム
  generateInputForm: FormGroup = this.formBuilder.group(Object());

  // 画面.ルート選択入力フォーム
  addCirculationRootForm: FormGroup = this.formBuilder.group(Object());

  // 更新対象ID
  @Output() reloadID = new EventEmitter<string>();

  /* 画面用プロパティ */
  // 入力画面ダイアログ表示フラグ
  inputModal: boolean;

  // 入力画面表示フラグ(true=ルート選択,false=回覧選択)
  inputFlag: boolean;

  // 確認画面表示フラグ
  confirmationFlag: boolean;

  // 回覧ID格納先
  circulationId: string;

  // 回覧選択リスト生成格納先
  circulationInputList: any[] = new Array();

  // 回覧ルート選択リスト生成格納先
  circulationRootInputList: any[] = new Array();

  // 登録用リクエストオブジェクト
  circulationRootRequest: any[] = new Array();

  // 入力エラーメッセージオブジェクト
  generateInputErrorMessageList: GenerateInputErrorMessage[] = new Array();

  constructor(
    private commonService: CommonService,
    private formBuilder: FormBuilder,
    private circulationRootService: CirculationRootService,
    private loadingState: LoadingState,
    private dbOperationService: DbOperationService,
    private messageData: MessageData
  ) {}

  ngOnInit(): void {}

  /**
   * 回覧ルート登録情報表示
   * @param circulationId 回覧ID
   */
  public initial(circulationId: string) {
    // 画面ロードフラグをON(ロード中状態)
    this.loadingState.loadStart('AddCirculationRootComponent');

    // 入力画面表示
    this.inputModal = true;

    // 回覧IDを格納
    this.circulationId = circulationId;

    // 画面.入力フォームを初期化
    {
      this.circulationInputList = new Array();
      this.generateInputForm = this.formBuilder.group(Object());
      this.addCirculationRootForm = this.formBuilder.group(Object());

      // エラーメッセージ初期化
      this.clearErrorMessage();

      // 入力画面表示フラグ
      this.inputFlag = false;

      // 確認画面表示フラグ
      this.confirmationFlag = false;
    }

    /* 回覧情報取得処理  */
    this.circulationRootService.getRouteGroupsInfo().subscribe((response) => {
      // 画面ロードフラグをOFF(ロード終了)
      this.loadingState.loadEnd('AddCirculationRootComponent');

      // 入力項目情報が取得されたか否か
      if (this.commonService.checkNoneResponse(response)) {
        // 入力項目情報が取得されなかった場合

        return;
      }

      // 入力フォーム用コントロール作成
      for (const generateInput of response.body) {
        // 入力フォームグループに入力フォームコントロールを追加
        this.generateInputForm.addControl(
          generateInput.column_id,
          this.formBuilder.control(new Array())
        );
      }

      // 入力項目情報を画面.入力項目生成に格納
      this.circulationInputList = response.body;

      // 選択リスト格納データ取得
      this.getRouteGroups();
    });
  }

  /**
   * 選択リスト格納データ取得
   */
  private getRouteGroups() {
    // 画面ロードフラグをON(ロード中状態)
    this.loadingState.loadStart('getRouteGroups');

    // 選択リスト格納の詳細情報取得
    this.circulationRootService
      .getRouteGroups(this.circulationId)
      .subscribe((response) => {
        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadEnd('getRouteGroups');

        // 詳細情報が取得されたか否か
        if (this.commonService.checkNoneResponse(response)) {
          // 詳細情報が取得されなかった場合

          return;
        }

        // 入力項目分ループ
        for (let groupUserInput of this.circulationInputList) {
          // 詳細情報のカラム値(コード)を取得する
          const dataMulti = this.commonService.getArrayObjectValue(
            response.body,
            DISPLAY_INFORMATIONS_API_CONSTANT.COLUMN_ID,
            DISPLAY_INFORMATIONS_API_CONSTANT.DATA_MULTI,
            groupUserInput.column_id
          );

          // 詳細情報のカラム値(コード)を画面.入力フォームに初期表示として格納
          this.generateInputForm
            .get(groupUserInput.column_id)
            .setValue(dataMulti);
        }
      });
  }

  /**
   * ピッカーリスト.格納前情報
   * @param columnId カラムID
   */
  protected pickListSource(columnId: string): object[] {
    // カラムIDの入力項目コード情報を取得
    const generateInputDataMulti: object[] =
      this.commonService.getArrayObjectValue(
        this.circulationInputList,
        INPUT_INFORMATIONS_API_CONSTANT.COLUMN_ID,
        INPUT_INFORMATIONS_API_CONSTANT.COLUMN_CODE_LIST_MULTI,
        columnId
      );

    // 画面.入力フォームにカラムIDの入力情報が存在するか否か
    if (!this.generateInputForm.controls[columnId].value) {
      // 画面.入力フォームにカラムIDの入力情報が存在しない場合

      // 入力項目コード情報を返却
      return generateInputDataMulti;
    }

    // グループIDリストを生成
    const codeList = this.commonService.createArrayGetArrayObject(
      this.generateInputForm.controls[columnId].value,
      CIRCULATION_ROOT_CONSTANT.GROUP_ID
    );

    // 入力項目コード情報から詳細情報のコードを削除
    const returnDataMulti: any[] =
      this.commonService.deleteArrayObjectMultipleValue(
        generateInputDataMulti.slice(),
        CIRCULATION_ROOT_CONSTANT.GROUP_ID,
        codeList
      );

    // 詳細情報のコードが削除された入力項目コード情報を返却
    return returnDataMulti;
  }

  /**
   * ルートボタン表示フラグ
   * @returns true:ボタン非表示、false:ボタン表示
   */
  protected selectedRootButtonDisplayFlag(): boolean {
    // 回覧対象ルート一覧リストが存在するか否か
    if (!this.circulationInputList.length) {
      // 回覧対象ルート一覧リストが存在しない場合
      return true;
    }

    // 選択した回覧対象ルート一覧リストを取得
    const selectedUserList = this.generateInputForm.get(
      this.circulationInputList[0].column_id
    ).value;

    // 選択した回覧対象ルート一覧リストが存在するか否か
    if (selectedUserList.length) {
      // 回覧対象ルート一覧リストが存在する場合

      return false;
    }

    return true;
  }

  /**
   * ルート選択画面に遷移
   */
  protected selectedRoot() {
    // 画面.回覧ルート入力フォームを初期化
    this.addCirculationRootForm = this.formBuilder.group(Object());

    // 選択した回覧グループを取得
    const selectedCirculationList = this.generateInputForm.get(
      this.circulationInputList[0].column_id
    ).value;

    // 選択した回覧グループで入力フォーム用コントロール作成
    for (const selectedCirculationInput of selectedCirculationList) {
      // 子フォームグループを生成
      let childFormGroup = this.formBuilder.group(Object());

      // 選択した回覧グループでフォームコントロールを作成
      for (const inputKey in selectedCirculationInput) {
        // 子フォームグループに入力フォームコントロールを追加
        childFormGroup.addControl(
          inputKey,
          this.formBuilder.control(selectedCirculationInput[inputKey], Object())
        );
      }

      // 子フォームグループを入力フォームに格納
      this.addCirculationRootForm.addControl(
        selectedCirculationInput.group_id,
        childFormGroup
      );
    }

    // 選択した回覧グループを画面.回覧グループ選択リスト生成に格納
    this.circulationRootInputList = selectedCirculationList;

    // 入力画面表示フラグ(ルート選択画面に遷移)
    this.inputFlag = true;
  }

  /**
   * 前条件回覧選択リストを生成
   * @param groupId グループID
   */
  protected selectedCirculationRootMultiSelectList(groupId: string): Object[] {
    // 画面.回覧グループ選択リスト生成からグループIDの情報を削除
    const returnDataMulti: any[] = this.commonService.deleteArrayObjectValue(
      this.circulationRootInputList.slice(),
      CIRCULATION_ROOT_CONSTANT.GROUP_ID,
      groupId
    );

    return returnDataMulti;
  }

  /**
   * 回覧選択画面に遷移
   */
  protected returnSelectedCirculationGroup() {
    // エラーメッセージ初期化
    this.clearErrorMessage();

    // 入力画面表示フラグ(回覧選択画面に遷移)
    this.inputFlag = false;
  }

  /**
   * 選択した回覧ルート確認
   */
  protected checkCirculationRoot() {
    // 入力フォームオブジェクトを初期化
    this.circulationRootRequest = new Array();

    // エラーメッセージ初期化
    this.clearErrorMessage();

    // 前条件のグループIDリストを生成
    const groupIdList = this.commonService.createArrayGetArrayObject(
      this.circulationRootInputList,
      CIRCULATION_ROOT_CONSTANT.GROUP_ID
    );

    /* 登録用リクエストオブジェクト作成 */
    // 選択した回覧情報分ループ
    for (const circulationRootInput of this.circulationRootInputList) {
      // 入力フォームオブジェクトを生成
      let groupRootForm: object = new Object();

      // 対象の入力フォームを取得
      let addCirculationRootForm =
        this.addCirculationRootForm.controls[circulationRootInput.group_id]
          .value;

      // 前条件からプルダウンに無いデータを削除
      addCirculationRootForm.start_condition =
        addCirculationRootForm.start_condition.filter((start_condition) => {
          return groupIdList.includes(start_condition);
        });

      // 入力フォームオブジェクトに対象の入力フォームを格納
      groupRootForm = Object.assign(groupRootForm, addCirculationRootForm);

      // 入力フォームオブジェクトをリストに格納
      this.circulationRootRequest.push(groupRootForm);
    }

    // 画面ロードフラグをON(ロード中状態)
    this.loadingState.loadStart();

    /* 入力確認 */
    {
      // 入力項目値確認用API
      this.dbOperationService
        .insertData(API_URL_ROUTE_GROUPS_CHECK_INPUT, {
          route_id: this.circulationId,
          list: this.circulationRootRequest,
        })
        .subscribe((response) => {
          // 画面ロードフラグをOFF(ロード終了)
          this.loadingState.loadEnd();

          // バリデーションチェック状態を確認
          if (API_RESPONSE.FAIL == response.body[0].result) {
            // チェック状態が異常の場合

            // 選択したユーザ情報分ループ
            for (const item of this.circulationRootInputList) {
              // 入力項目に該当するエラーメッセージが存在するか判定
              if (response.body[0].error[item.group_id]) {
                // エラーメッセージが存在する場合

                //  入力フォームエラー情報を生成
                let generateInputErrorMessage: GenerateInputErrorMessage =
                  new GenerateInputErrorMessage();
                //  入力フォームエラー情報を格納
                generateInputErrorMessage.columnId = item.group_id;
                generateInputErrorMessage.columnName = item.group_name;
                generateInputErrorMessage.columnErrorMessage =
                  response.body[0].error[item.group_id][0].message;
                this.generateInputErrorMessageList.push(
                  generateInputErrorMessage
                );
              }
            }

            // 入力フォームエラー情報を出力
            this.generateInputErrorMessageComponent.initial(
              this.generateInputErrorMessageList
            );

            // 処理を終了
            return;
          }

          // 確認画面を表示
          this.confirmationFlag = true;
        });
    }
  }

  /**
   * 回覧ルート登録
   */
  protected registCirculationRoot() {
    // 画面ロードフラグをON(ロード中状態)
    this.loadingState.loadStart();

    /* 回覧ルート登録 */
    {
      // 入力項目値登録API
      this.dbOperationService
        .insertData(API_URL_ROUTE_GROUPS, {
          route_id: this.circulationId,
          list: this.circulationRootRequest,
        })
        .subscribe((response) => {
          // 画面ロードフラグをOFF(ロード終了)
          this.loadingState.loadEnd();

          // レスポンスを判定
          if (
            this.messageData.responseToastMessage(
              response,
              null,
              this.commonService.msg(MESSAGE_CODE.I00001)
            )
          ) {
            // レスポンスが正常終了の場合

            // 入力画面非表示
            this.inputModal = false;

            // 更新対象IDにプライマリキーをセット
            this.reloadID.emit(this.circulationId);
          }
        });
    }

    // 画面ロードフラグをOFF(ロード終了)
    this.loadingState.loadEnd();
  }

  /**
   * 回覧名称取得
   * @param groupId グループID
   * @returns コード名称
   */
  protected changeGroupName(groupId: string): string {
    // 回覧名称を取得
    return this.commonService.getArrayObjectValue(
      this.circulationRootInputList,
      CIRCULATION_ROOT_CONSTANT.GROUP_ID,
      CIRCULATION_ROOT_CONSTANT.GROUP_NAME,
      groupId
    );
  }

  /**
   * エラーメッセージ初期化
   */
  private clearErrorMessage() {
    // 入力フォームエラー情報を初期化
    this.generateInputErrorMessageList = new Array();
    this.generateInputErrorMessageComponent.clear();
  }

  /**
   * エラー項目チェック
   * @param groupId グループID
   * @returns true:エラーメッセージが存在する場合 false:エラーメッセージが存在しない場合
   */
  protected checkErrorItem(groupId: string): boolean {
    // エラーメッセージリストのエラーメッセージ存在チェック
    // 対象項目が存在する場合、trueを返却
    return this.generateInputErrorMessageList.some(
      (errorMessage) => errorMessage.columnId === groupId
    );
  }
}
