<!-- 回覧グループメンバー登録画面ダイアログ -->
<p-dialog
  header="回覧グループメンバー登録"
  [(visible)]="inputModal"
  [modal]="true"
>
  <!-- 入力フォームページアンカー -->
  <a id="generateInputErrorTop"></a>
  <!-- 入力エラーメッセージ-->
  <app-generate-input-error-message></app-generate-input-error-message>
  <!-- メンバー入力フォーム -->
  <div class="groupUserInputForm" *ngIf="!inputFlag && !confirmationFlag">
    <form [formGroup]="generateInputForm">
      <!-- 入力情報出力領域 -->
      <p-table
        [value]="groupUserInputList"
        styleClass="p-datatable-sm generate-input"
      >
        <!-- 入力情報出力箇所 -->
        <ng-template pTemplate="body" let-groupUserInputInformation>
          <!-- ピッカーリスト -->
          <tr>
            <td colspan="2">
              <br />
              <p-pickList
                [source]="pickListSource(groupUserInputInformation.column_id)"
                [target]="
                  generateInputForm.value[groupUserInputInformation.column_id]
                "
                [sourceHeader]="groupUserInputInformation.column_name"
                targetHeader="選択リスト"
                [dragdrop]="true"
                [showSourceControls]="false"
                [showTargetControls]="false"
                filterBy="department_name,user_name"
              >
                <ng-template let-code pTemplate="item">
                  <!-- ピッカーリストアイテム要素 -->
                  <div class="p-d-flex pickListItem">
                    <!-- 部門 -->
                    <div class="pickItem">
                      {{ code.department_name }}
                    </div>
                    <!-- ユーザー名 -->
                    <div class="pickItem">
                      {{ code.user_name }}
                    </div>
                    <!-- 役割 -->
                    <div class="pickItem">
                      {{ changeCodeValue(code.role) }}
                    </div>
                  </div>
                </ng-template>
              </p-pickList>
              <br />
            </td>
          </tr>
        </ng-template>
      </p-table>

      <!-- ボタンエリア -->
      <div class="buttonArea">
        <button
          class="button"
          type="submit"
          label="ロール選択"
          (click)="selectedRoll()"
          [disabled]="selectedRollButtonDisplayFlag()"
          pButton
        ></button>
      </div>
    </form>
  </div>

  <!-- メンバーロール選択フォーム -->
  <div class="groupUseRoleInputForm" *ngIf="inputFlag && !confirmationFlag">
    <form [formGroup]="addGroupMemberForm">
      <!-- 入力情報出力領域 -->
      <p-table
        [value]="groupUseRoleInputList"
        styleClass="p-datatable-sm generate-input"
      >
        <!-- 入力情報タイトル -->
        <ng-template pTemplate="header">
          <tr>
            <th colspan="3">選択リスト</th>
          </tr>
        </ng-template>
        <!-- 入力情報出力箇所 -->
        <ng-template pTemplate="body" let-groupUseRoleInput>
          <!-- 子フォームグループ -->
          <tr [formGroupName]="groupUseRoleInput.user_id">
            <!-- 部門 -->
            <td>
              {{ groupUseRoleInput.department_name }}
            </td>
            <!-- 名前 -->
            <td>
              {{ groupUseRoleInput.user_name }}
            </td>
            <!-- ロール選択 -->
            <td>
              <p-dropdown
                name="role"
                [options]="dicValuesList"
                placeholder="　"
                optionLabel="sp_name"
                optionValue="sp_code"
                appendTo="body"
                [styleClass]="
                  checkErrorItem(groupUseRoleInput.user_id) ? 'errorItem' : ''
                "
                [formControl]="
                  addGroupMemberForm.controls[groupUseRoleInput.user_id]
                    .controls.role
                "
              >
              </p-dropdown>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </form>

    <!-- ボタンエリア -->
    <div class="buttonArea">
      <button
        class="button"
        type="button"
        label="確認"
        (click)="checkGroupUseRole()"
        pButton
      ></button>
      <button
        class="button"
        type="button"
        label="戻る"
        (click)="returnSelectedGroupMembers()"
        pButton
      ></button>
    </div>
  </div>

  <!-- 確認情報出力領域 -->
  <div class="groupUseInputConfirmation" *ngIf="confirmationFlag">
    <p-table [value]="groupMemberRollRequest" styleClass="p-datatable-sm">
      <!-- 確認情報タイトル -->
      <ng-template pTemplate="header">
        <tr>
          <th colspan="3">確認</th>
        </tr>
      </ng-template>
      <!-- 確認情報出力箇所 -->
      <ng-template pTemplate="body" let-groupMemberRoll>
        <tr>
          <!-- 部門 -->
          <td>
            {{ groupMemberRoll.department_name }}
          </td>
          <!-- 名前 -->
          <td>
            {{ groupMemberRoll.user_name }}
          </td>
          <!-- ロール -->
          <td>
            {{ changeCodeValue(groupMemberRoll.role) }}
          </td>
        </tr>
      </ng-template>
    </p-table>

    <!-- ボタンエリア -->
    <div class="buttonArea">
      <button
        class="button"
        type="button"
        label="登録"
        (click)="registGroupUseRole()"
        pButton
      ></button>
      <button
        class="button"
        type="button"
        label="戻る"
        (click)="confirmationFlag = false"
        pButton
      ></button>
    </div>
  </div>
</p-dialog>
