import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  API_URL_ROUTE_GROUPS,
  API_URL_ROUTE_GROUPS_GET_GROUP_INFO,
} from 'manager/http-constants_key';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CommonService } from 'src/app/shared/service/common.service';

@Injectable({
  providedIn: 'root',
})

/**
 * 回覧ルートサービス
 */
export class CirculationRootService {
  constructor(private http: HttpClient, private commonService: CommonService) {}

  /**
   * 回覧対象ルート一覧取得
   */
  public getRouteGroupsInfo(): Observable<any> {
    // LambdaのEndpointを生成
    const url = this.commonService.url(API_URL_ROUTE_GROUPS_GET_GROUP_INFO);

    // コンテンツリストを返却する
    return this.http
      .get(url, { observe: 'response' })
      .pipe(map((res: HttpResponse<any>) => res));
  }

  /**
   * 登録済み回覧ルートグループ取得
   * @param circulationId 回覧ID
   */
  public getRouteGroups(circulationId: string): Observable<any> {
    // LambdaのEndpointを生成
    const url = this.commonService.url(API_URL_ROUTE_GROUPS, circulationId);

    // コンテンツリストを返却する
    return this.http
      .get(url, { observe: 'response' })
      .pipe(map((res: HttpResponse<any>) => res));
  }
}
