import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  API_URL_CIRCULATIONS_LIST,
  API_URL_CONTENTS_LIST,
  API_URL_ITEMS_APPROVER,
  API_URL_ITEMS_GROUP_MEMBER,
} from 'manager/http-constants_key';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CommonService } from 'src/app/shared/service/common.service';

@Injectable({
  providedIn: 'root',
})

/**
 * サマリー詳細サービス
 */
export class SummaryDisplayService {
  constructor(private http: HttpClient, private commonService: CommonService) {}

  /**
   * コンテンツリスト取得
   * @param itemId 案件ID
   */
  public getContentsList(itemId: string): Observable<any> {
    // LambdaのEndpointを生成
    const url = this.commonService.url(
      API_URL_CONTENTS_LIST,
      '?item_id=',
      itemId
    );

    // コンテンツリストを返却する
    return this.http
      .get(url, { observe: 'response' })
      .pipe(map((res: HttpResponse<any>) => res));
  }

  /**
   * 回覧リスト取得
   * @param itemId 案件ID
   * @param contentId コンテンツID
   */
  public getCirculationsList(
    itemId: string,
    contentId: string
  ): Observable<any> {
    // LambdaのEndpointを生成
    const url = this.commonService.url(
      API_URL_CIRCULATIONS_LIST,
      '?item_id=',
      itemId,
      '&content_id=',
      contentId
    );

    // コンテンツリストを返却する
    return this.http
      .get(url, { observe: 'response' })
      .pipe(map((res: HttpResponse<any>) => res));
  }

  /**
   * 承認者リスト取得
   * @param itemId 案件ID
   */
  public getApprover(itemId: string): Observable<any> {
    // LambdaのEndpointを生成
    const url = this.commonService.url(
      API_URL_ITEMS_GROUP_MEMBER,
      itemId,
      '?role=',
      '1'
    );

    // 承認者リストを返却する
    return this.http
      .get(url, { observe: 'response' })
      .pipe(map((res: HttpResponse<any>) => res));
  }
}
