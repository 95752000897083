import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  API_URL_INPUT_MEMBERS,
  API_URL_MEMBERS,
  API_URL_MEMBERS_ANSWER,
} from 'manager/http-constants_key';
import { SUMMARY_CIRCULATION_TEMPLATE } from 'manager/template-constant';
import { LOADING_KEY } from 'src/app/shared/constant/constant';
import { MESSAGE_CODE } from 'src/app/shared/constant/message-constant';
import { GENERATE_INPUT_TYPE } from 'src/app/shared/generate/generate-input/constant';
import { GenerateInputErrorMessageComponent } from 'src/app/shared/generate/generate-input/generate-input-information/generate-input-error-message/generate-input-error-message.component';
import { GenerateInputFormComponent } from 'src/app/shared/generate/generate-input/generate-input-information/generate-input-form/generate-input-form.component';
import {
  LoadData,
  LoadingState,
} from 'src/app/shared/html-parts/loading/loading-state';
import { MessageData } from 'src/app/shared/html-parts/message-common/message-data';
import { CommonService } from 'src/app/shared/service/common.service';
import { DbOperationService } from 'src/app/shared/service/db-operation.service';
import { SUMMARY_CIRCULATION_CONSTANT } from '../constant';

@Component({
  selector: 'app-add-answer-member',
  templateUrl: './add-answer-member.component.html',
  styleUrls: ['./add-answer-member.component.scss'],
})

/**
 * サマリー回覧.回答者追加画面
 */
export class AddAnswerMemberComponent implements OnInit {
  /** 入力エラーメッセージ */
  @ViewChild(GenerateInputErrorMessageComponent)
  generateInputErrorMessageComponent: GenerateInputErrorMessageComponent;

  /** 入力フォーム */
  @ViewChild(GenerateInputFormComponent)
  generateInputFormComponent: GenerateInputFormComponent;

  // 更新対象ID
  @Output() reloadID = new EventEmitter<string>();

  /* 画面用プロパティ */
  // 回覧ID
  circulationId: string;

  // 入力ダイアログ表示フラグ
  inputModal: boolean;

  constructor(
    private dbOperationService: DbOperationService,
    private commonService: CommonService,
    private messageData: MessageData,
    private loadingState: LoadingState
  ) {}

  ngOnInit(): void {}

  /**
   * 回答者追加画面表示
   * @param circulationId 回覧ID
   */
  public inputAddAnswerMember(circulationId: string) {
    // 対象データの回覧IDをプロパティに格納
    this.circulationId = circulationId;

    // 入力フォーム表示
    this.generateInputFormComponent.initial(
      GENERATE_INPUT_TYPE.NEW,
      API_URL_INPUT_MEMBERS,
      null,
      SUMMARY_CIRCULATION_TEMPLATE.ANSWER_MEMBER_INPUT_TEMPLATE_ID,
      null,
      {
        // 入力フォーム絞り込み条件:回覧ID
        CirculationID: circulationId,
      }
    );

    // 入力画面ダイアログを表示
    this.inputModal = true;
  }

  /**
   * 回答者追加処理
   * @param generateInputInformation 入力フォーム情報
   */
  public addAnswerMember(generateInputInformation: any) {
    // 入力フォーム情報に回覧IDをセット
    generateInputInformation.value[
      SUMMARY_CIRCULATION_CONSTANT.CIRCULATION_ID
    ] = this.circulationId;

    // 画面カスタムロードフラグをON(ロード中状態)
    this.loadingState.customLoadStart(
      new LoadData({
        loadingText: '',
        background_color: '',
        opacity: 0.3,
      }),
      LOADING_KEY.DATA_INSERT
    );

    // 回答者登録
    this.dbOperationService
      .insertData(API_URL_MEMBERS, generateInputInformation.value)
      .subscribe((response) => {
        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadEnd(LOADING_KEY.DATA_INSERT);

        // 入力レスポンスメッセージを表示
        if (
          this.messageData.responseToastMessage(
            response,
            this.commonService.msg(MESSAGE_CODE.T00001),
            this.commonService.msg(MESSAGE_CODE.I00001)
          )
        ) {
          // 正常終了の場合

          // 更新対象IDに回覧IDをセット
          this.reloadID.emit(this.circulationId);

          // 入力画面ダイアログを閉じる
          this.inputModal = false;
        } else {
          // 異常終了の場合

          // 入力フォーム状態初期化
          this.generateInputFormComponent.resetFlag();
        }
      });
  }

  /**
   * 入力エラー情報出力
   * @param generateInputErrorInformation 入力フォームエラー情報
   */
  public outputGenerateInputErrorInformation(
    generateInputErrorInformation: any
  ) {
    // 入力フォームエラー情報を出力
    this.generateInputErrorMessageComponent.initial(
      generateInputErrorInformation
    );
  }
}
