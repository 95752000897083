<div class="top">NEWS</div>
<div class="center">
  <!-- お知らせカテゴリ -->
  <p-tabView>
    <!-- 全て-->
    <p-tabPanel header="全て">
      <app-notice-list [informationType]=""></app-notice-list>
    </p-tabPanel>
    <!-- お知らせ種別の辞書情報分ループ -->
    <ng-container *ngFor="let dicValues of dicValuesList">
      <!-- お知らせ種別のカテゴリ表示 -->
      <p-tabPanel [header]="dicValues.name">
        <app-notice-list [informationType]="dicValues.code"></app-notice-list>
      </p-tabPanel>
    </ng-container>
  </p-tabView>
</div>
