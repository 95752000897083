export const CIRCULATION_GROUP_CONSTANT = {
  // csvファイル名
  CSV_FILENAME: 'circulation-group',
  // 対象テーブル
  TARGET_TABLE: 'customer',
  // ユーザIDカラム
  USER_ID: 'user_id',
  // 辞書番号:役割
  SP_DIC_ID: 621,
};
