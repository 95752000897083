<!-- メイン領域-->
<div class="main-container">
  <!-- メインヘッダー-->
  <div class="header p-d-flex">
    <!-- 戻るボタン-->
    <button
      type="button"
      pButton
      icon="pi pi-angle-left"
      label="戻る"
      (click)="return()"
      class="p-mr-2 summaryDisplayDefaultButton"
    ></button>
  </div>
  <!-- サマリー情報-->
  <div class="display">
    <label class="label">案件情報</label>
    <!-- サマリー目次情報表示領域-->
    <p-table
      [value]="summaryDisplayList"
      styleClass="p-datatable-striped summary-display"
    >
      <!-- サマリー目次ボディ表示-->
      <ng-template pTemplate="body" let-summaryDisplay let-rowIndex="rowIndex">
        <!-- サマリー目次行出力-->
        <tr *ngIf="rowIndex < summaryRowDisplayNumber">
          <!-- サマリー目次列出力-->
          <ng-container *ngFor="let colNumber of summaryColDisplayNumber">
            <!-- カラム名称 -->
            <td class="summaryDisplayLabel">
              <!-- サマリー情報が存在するか否か -->
              <label
                *ngIf="
                  summaryDisplayList[
                    rowIndex + colNumber * summaryRowDisplayNumber
                  ]
                "
              >
                <!-- サマリー情報が存在する場合 -->
                <!-- カラム名称を出力 -->
                {{
                  summaryDisplayList[
                    rowIndex + colNumber * summaryRowDisplayNumber
                  ].column_name
                }}
              </label>
            </td>
            <!-- カラム内容 -->
            <td class="summaryDisplayData">
              <!-- サマリー情報が存在するか否か -->
              <label
                *ngIf="
                  summaryDisplayList[
                    rowIndex + colNumber * summaryRowDisplayNumber
                  ]
                "
              >
                <!-- サマリー情報が存在する場合 -->

                <!-- カラム内容を出力 -->
                <!-- 数値出力 -->
                <span
                  *ngIf="
                    summaryDisplayList[
                      rowIndex + colNumber * summaryRowDisplayNumber
                    ].input_type == 'NUMBER'
                  "
                >
                  {{
                    summaryDisplayList[
                      rowIndex + colNumber * summaryRowDisplayNumber
                    ].data | number
                  }}
                </span>
                <!-- 文字出力 -->
                <span
                  *ngIf="
                    summaryDisplayList[
                      rowIndex + colNumber * summaryRowDisplayNumber
                    ].input_type == 'VARCHAR'
                  "
                >
                  {{
                    summaryDisplayList[
                      rowIndex + colNumber * summaryRowDisplayNumber
                    ].data
                  }}
                </span>
              </label>
            </td>
          </ng-container>

          <!-- ボタン表示用領域 -->
          <ng-container *ngIf="rowIndex == 0">
            <td
              class="summaryDisplayButtonArea"
              [attr.rowspan]="summaryRowDisplayNumber"
            >
              <!-- 詳細ボタン-->
              <button
                type="button"
                pButton
                label="詳細"
                (click)="detail()"
                class="p-mr-2 summaryDisplayDefaultButton p-button-outlined"
              ></button>
            </td>
          </ng-container>
        </tr>
      </ng-template>
    </p-table>
    <!-- コンテンツ登録ボタン-->
    <button
      type="button"
      pButton
      label="コンテンツ登録"
      (click)="insertContent()"
      class="p-mr-2 summaryDisplayDefaultButton"
      *ngIf="insertContentDisplayFlag()"
    ></button>
  </div>
  <!-- コンテンツ情報-->
  <div class="content">
    <p-tabView>
      <!-- サマリーコンテンツリスト分ループ -->
      <ng-container *ngFor="let summaryContents of summaryContentsList">
        <!-- サマリーコンテンツのカテゴリ表示 -->
        <p-tabPanel
          [header]="summaryContents.content_name"
          [selected]="selectedSummaryContentsList(summaryContents.content_id)"
        >
          <app-summary-content-display
            [itemId]="itemId"
            [contentId]="summaryContents.content_id"
            [itemCreateUserId]="itemCreateUserId"
            [contentCreateUserId]="summaryContents.create_user_id"
            (deleteContentId)="deleteSummaryContentList($event)"
          ></app-summary-content-display>
        </p-tabPanel>
      </ng-container>

      <!-- サマリーコンテンツが存在しない場合 -->
      <div *ngIf="!summaryContentsList.length">
        <label class="label">コンテンツは存在しません</label>
      </div>
    </p-tabView>
  </div>
</div>

<!-- サマリー詳細.詳細画面 -->
<app-summary-display-detail></app-summary-display-detail>

<!-- サマリー詳細.入力画面 -->
<app-summary-display-input
  (reloadID)="getSummaryContentList()"
></app-summary-display-input>
