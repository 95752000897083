import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  API_URL_CONTENTS,
  API_URL_INPUT_CONTENTS,
} from 'manager/http-constants_key';
import { SUMMARY_DISPLAY_TEMPLATE } from 'manager/template-constant';
import { LOADING_KEY } from 'src/app/shared/constant/constant';
import { MESSAGE_CODE } from 'src/app/shared/constant/message-constant';
import { GENERATE_INPUT_TYPE } from 'src/app/shared/generate/generate-input/constant';
import { GenerateInputErrorMessageComponent } from 'src/app/shared/generate/generate-input/generate-input-information/generate-input-error-message/generate-input-error-message.component';
import { GenerateInputFormComponent } from 'src/app/shared/generate/generate-input/generate-input-information/generate-input-form/generate-input-form.component';
import {
  LoadData,
  LoadingState,
} from 'src/app/shared/html-parts/loading/loading-state';
import { MessageData } from 'src/app/shared/html-parts/message-common/message-data';
import { CommonService } from 'src/app/shared/service/common.service';
import { DbOperationService } from 'src/app/shared/service/db-operation.service';
import { SUMMARY_DISPLAY_CONSTANT } from '../constant';

@Component({
  selector: 'app-summary-display-input',
  templateUrl: './summary-display-input.component.html',
  styleUrls: ['./summary-display-input.component.scss'],
})

/**
 * サマリー詳細.入力画面
 */
export class SummaryDisplayInputComponent implements OnInit {
  /** 入力エラーメッセージ */
  @ViewChild(GenerateInputErrorMessageComponent)
  generateInputErrorMessageComponent: GenerateInputErrorMessageComponent;

  /** 入力フォーム */
  @ViewChild(GenerateInputFormComponent)
  generateInputFormComponent: GenerateInputFormComponent;

  // 更新対象ID
  @Output() reloadID = new EventEmitter<string>();

  /* 画面用プロパティ */
  // 案件ID
  itemId: string;

  // 入力ダイアログ表示フラグ
  inputModal: boolean;

  constructor(
    private dbOperationService: DbOperationService,
    private commonService: CommonService,
    private messageData: MessageData,
    private loadingState: LoadingState
  ) {}

  ngOnInit(): void {}

  /**
   * サマリーコンテンツ登録画面表示
   * @param itemId 案件ID
   */
  public inputSummaryContent(itemId: string) {
    // 対象データの案件IDをプロパティに格納
    this.itemId = itemId;

    // 入力フォーム表示
    this.generateInputFormComponent.initial(
      GENERATE_INPUT_TYPE.NEW,
      API_URL_INPUT_CONTENTS,
      null,
      SUMMARY_DISPLAY_TEMPLATE.SUMMARY_CONTENT_NEW_INPUT_TEMPLATE_ID
    );

    // 入力画面ダイアログを表示
    this.inputModal = true;
  }

  /**
   * 登録処理
   * @param generateInputInformation 入力フォーム情報
   */
  public insertSummaryContent(generateInputInformation: any) {
    // 入力フォーム情報に案件IDをセット
    generateInputInformation.value[SUMMARY_DISPLAY_CONSTANT.ITEM_ID] =
      this.itemId;

    // 画面カスタムロードフラグをON(ロード中状態)
    this.loadingState.customLoadStart(
      new LoadData({
        loadingText: '',
        background_color: '',
        opacity: 0.3,
      }),
      LOADING_KEY.DATA_INSERT
    );

    // コンテンツ登録
    this.dbOperationService
      .insertData(API_URL_CONTENTS, generateInputInformation.value)
      .subscribe((response) => {
        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadEnd(LOADING_KEY.DATA_INSERT);

        // 入力レスポンスメッセージを表示
        if (
          this.messageData.responseToastMessage(
            response,
            this.commonService.msg(MESSAGE_CODE.T00001),
            this.commonService.msg(MESSAGE_CODE.I00001)
          )
        ) {
          // 正常終了の場合

          // 更新対象IDに案件IDをセット
          this.reloadID.emit(this.itemId);

          // 入力画面ダイアログを閉じる
          this.inputModal = false;
        } else {
          // 異常終了の場合

          // 入力フォーム状態初期化
          this.generateInputFormComponent.resetFlag();
        }
      });
  }

  /**
   * 入力エラー情報出力
   * @param generateInputErrorInformation 入力フォームエラー情報
   */
  public outputGenerateInputErrorInformation(
    generateInputErrorInformation: any
  ) {
    // 入力フォームエラー情報を出力
    this.generateInputErrorMessageComponent.initial(
      generateInputErrorInformation
    );
  }
}
