import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  API_URL_CIRCULATIONS,
  API_URL_INPUT_CIRCULATIONS,
} from 'manager/http-constants_key';
import { SUMMARY_DISPLAY_TEMPLATE } from 'manager/template-constant';
import { LOADING_KEY } from 'src/app/shared/constant/constant';
import { MESSAGE_CODE } from 'src/app/shared/constant/message-constant';
import { GENERATE_INPUT_TYPE } from 'src/app/shared/generate/generate-input/constant';
import { GenerateInputErrorMessageComponent } from 'src/app/shared/generate/generate-input/generate-input-information/generate-input-error-message/generate-input-error-message.component';
import { GenerateInputFormComponent } from 'src/app/shared/generate/generate-input/generate-input-information/generate-input-form/generate-input-form.component';
import {
  LoadData,
  LoadingState,
} from 'src/app/shared/html-parts/loading/loading-state';
import { MessageData } from 'src/app/shared/html-parts/message-common/message-data';
import { CommonService } from 'src/app/shared/service/common.service';
import { DbOperationService } from 'src/app/shared/service/db-operation.service';
import { SUMMARY_DISPLAY_CONSTANT } from '../constant';

@Component({
  selector: 'app-summary-content-display-input',
  templateUrl: './summary-content-display-input.component.html',
  styleUrls: ['./summary-content-display-input.component.scss'],
})

/**
 * サマリーコンテンツ詳細.入力画面
 */
export class SummaryContentDisplayInputComponent implements OnInit {
  /** 入力エラーメッセージ */
  @ViewChild(GenerateInputErrorMessageComponent)
  generateInputErrorMessageComponent: GenerateInputErrorMessageComponent;

  /** 入力フォーム */
  @ViewChild(GenerateInputFormComponent)
  generateInputFormComponent: GenerateInputFormComponent;

  // 更新対象ID
  @Output() reloadID = new EventEmitter<string>();

  /* 画面用プロパティ */
  // 登録種別
  inputType: string;

  // 画面タイトル
  title: string;

  // 案件ID
  itemId: string;

  // コンテンツID
  contentId: string;

  // 回覧ID
  circulationId: string;

  // 入力ダイアログ表示フラグ
  inputModal: boolean;

  constructor(
    private dbOperationService: DbOperationService,
    private commonService: CommonService,
    private messageData: MessageData,
    private loadingState: LoadingState
  ) {}

  ngOnInit(): void {}

  /**
   * サマリー回覧登録画面表示
   * @param itemId 案件ID
   * @param contentId コンテンツID
   */
  public inputSummaryCirculation(itemId: string, contentId: string) {
    // タイトルを設定
    this.title = '回覧登録';

    // 登録種別を設定(新規登録)
    this.inputType = GENERATE_INPUT_TYPE.NEW;

    // 対象データの案件IDをプロパティに格納
    this.itemId = itemId;

    // 対象データのコンテンツIDをプロパティに格納
    this.contentId = contentId;

    // 入力フォーム表示
    this.generateInputFormComponent.initial(
      GENERATE_INPUT_TYPE.NEW,
      API_URL_INPUT_CIRCULATIONS,
      API_URL_CIRCULATIONS,
      SUMMARY_DISPLAY_TEMPLATE.SUMMARY_CIRCULATION_NEW_INPUT_TEMPLATE_ID,
      null,
      {
        // 入力フォーム絞り込み条件:コンテンツID
        ContentID: contentId,
      }
    );

    // 入力画面ダイアログを表示
    this.inputModal = true;
  }

  /**
   * サマリー回覧更新画面表示
   * @param contentId コンテンツID
   * @param circulationId 回覧ID
   */
  public updateInputSummaryCirculation(
    contentId: string,
    circulationId: string
  ) {
    // タイトルを設定
    this.title = '回覧更新';

    // 登録種別を設定(編集)
    this.inputType = GENERATE_INPUT_TYPE.EDIT;

    // 対象データのコンテンツIDをプロパティに格納
    this.contentId = contentId;

    // 対象データの回覧IDをプロパティに格納
    this.circulationId = circulationId;

    // 入力フォーム表示
    this.generateInputFormComponent.initial(
      GENERATE_INPUT_TYPE.EDIT,
      API_URL_INPUT_CIRCULATIONS,
      API_URL_CIRCULATIONS,
      SUMMARY_DISPLAY_TEMPLATE.SUMMARY_CIRCULATION_EDIT_INPUT_TEMPLATE_ID,
      circulationId,
      {
        // 入力フォーム絞り込み条件:コンテンツID
        ContentID: contentId,
      }
    );

    // 入力画面ダイアログを表示
    this.inputModal = true;
  }

  /**
   * サマリー回覧登録処理
   * @param generateInputInformation 入力フォーム情報
   */
  public insertGenerateInputInformation(generateInputInformation: any) {
    // 登録種別を判定
    if (GENERATE_INPUT_TYPE.NEW == this.inputType) {
      // 新規の場合

      // 入力フォーム情報に案件IDをセット
      generateInputInformation.value[SUMMARY_DISPLAY_CONSTANT.ITEM_ID] =
        this.itemId;

      // 入力フォーム情報にコンテンツIDをセット
      generateInputInformation.value[SUMMARY_DISPLAY_CONSTANT.CONTENT_ID] =
        this.contentId;

      // 画面カスタムロードフラグをON(ロード中状態)
      this.loadingState.customLoadStart(
        new LoadData({
          loadingText: '',
          background_color: '',
          opacity: 0.3,
        }),
        LOADING_KEY.DATA_INSERT
      );

      // コンテンツ登録
      this.dbOperationService
        .insertData(API_URL_CIRCULATIONS, generateInputInformation.value)
        .subscribe((response) => {
          // 画面ロードフラグをOFF(ロード終了)
          this.loadingState.loadEnd(LOADING_KEY.DATA_INSERT);

          // 入力レスポンスメッセージを表示
          if (
            this.messageData.responseToastMessage(
              response,
              this.commonService.msg(MESSAGE_CODE.T00001),
              this.commonService.msg(MESSAGE_CODE.I00001)
            )
          ) {
            // 正常終了の場合

            // 更新対象IDに案件IDをセット
            this.reloadID.emit(this.itemId);

            // 入力画面ダイアログを閉じる
            this.inputModal = false;
          } else {
            // 異常終了の場合

            // 入力フォーム状態初期化
            this.generateInputFormComponent.resetFlag();
          }
        });
    } else {
      // 更新の場合

      // 入力フォーム情報にコンテンツIDをセット
      generateInputInformation.value[SUMMARY_DISPLAY_CONSTANT.CONTENT_ID] =
        this.contentId;

      // 画面カスタムロードフラグをON(ロード中状態)
      this.loadingState.customLoadStart(
        new LoadData({
          loadingText: '',
          background_color: '',
          opacity: 0.3,
        }),
        LOADING_KEY.DATA_UPDATE
      );

      // コンテンツ登録
      this.dbOperationService
        .updateData(
          API_URL_CIRCULATIONS,
          this.circulationId,
          generateInputInformation.value
        )
        .subscribe((response) => {
          // 画面ロードフラグをOFF(ロード終了)
          this.loadingState.loadEnd(LOADING_KEY.DATA_UPDATE);

          // 入力レスポンスメッセージを表示
          if (
            this.messageData.responseToastMessage(
              response,
              this.commonService.msg(MESSAGE_CODE.T00001),
              this.commonService.msg(MESSAGE_CODE.U00002)
            )
          ) {
            // 正常終了の場合

            // 更新対象IDに案件IDをセット
            this.reloadID.emit(this.itemId);

            // 入力画面ダイアログを閉じる
            this.inputModal = false;
          } else {
            // 異常終了の場合

            // 入力フォーム状態初期化
            this.generateInputFormComponent.resetFlag();
          }
        });
    }
  }

  /**
   * 入力エラー情報出力
   * @param generateInputErrorInformation 入力フォームエラー情報
   */
  public outputGenerateInputErrorInformation(
    generateInputErrorInformation: any
  ) {
    // 入力フォームエラー情報を出力
    this.generateInputErrorMessageComponent.initial(
      generateInputErrorInformation
    );
  }
}
