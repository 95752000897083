<!-- 詳細画面-->
<p-sidebar
  [(visible)]="detailsNav"
  position="right"
  styleClass="p-sidebar-md"
  blockScroll="false"
  [autoZIndex]="true"
  [showCloseIcon]="false"
>
  <p-scrollPanel styleClass="custombar">
    <!-- 詳細情報-->
    <app-generate-display-information></app-generate-display-information>

    <p-footer>
      <!-- 編集・削除ボタン表示領域 -->
      <p-table [value]="[{}]" styleClass="p-datatable-sm">
        <!-- 編集・削除ボタン出力箇所 -->
        <ng-template pTemplate="body">
          <tr>
            <td>
              <!-- 編集ボタン -->
              <button
                pButton
                pRipple
                type="button"
                label="編集"
                (click)="edit()"
                class="p-button-raised p-mr-2 button"
                *ngIf="editButtonDisplayFlag()"
              ></button>
            </td>
            <td>
              <!-- 複製ボタン -->
              <button
                pButton
                pRipple
                type="button"
                label="複製"
                (click)="duplicate()"
                class="p-button-raised p-mr-2 button"
                *ngIf="duplicateButtonDisplayFlag()"
              ></button>
            </td>
            <td>
              <!-- 削除ボタン -->
              <button
                pButton
                pRipple
                type="button"
                label="削除"
                (click)="delete()"
                class="p-button-raised p-mr-2 button"
                *ngIf="deleteButtonDisplayFlag()"
              ></button>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </p-footer>
  </p-scrollPanel>
</p-sidebar>

<!-- 登録画面[入力項目生成(アイテムアダプター)]-->
<app-generate-input (reloadID)="return($event)"></app-generate-input>
