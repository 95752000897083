/**
 * ヘッダータイトル設定
 */

/**
 * TOP
 */
// TOP画面
export const TopComponent = {
  default: 'TOP',
};

/** --------------------------------------------------------- */

/**
 * マスタ管理
 */
// 営業日マスタ画面
export const BizdayComponent = {
  default: '営業日',
};

// 得意先マスタ画面
export const CustomerComponent = {
  default: '得意先',
};

// 届先マスタ画面
export const DeliveryComponent = {
  default: '届先',
};

// 届先グループマスタ画面
export const DeliveryGroupComponent = {
  default: '届先グループ',
};

// 組織マスタ画面
export const DepartmentComponent = {
  default: '組織',
};

// お知らせマスタ画面
export const InformationComponent = {
  default: 'お知らせ',
};

// 販促資材マスタ画面
export const ItemComponent = {
  default: '販促資材',
};

// 販促資材斡旋マスタ画面
export const ItemMediationComponent = {
  default: '販促資材斡旋',
};

// ユーザーマスタ画面
export const UserComponent = {
  default: 'ユーザ',
};

/** --------------------------------------------------------- */

/**
 * 在庫管理
 */
// 製作指示画面
export const ProductionInstructionComponent = {
  default: '製作指示',
};

// 在庫メンテナンスファイル登録画面
export const RegisterStockMaintenanceComponent = {
  default: '在庫登録・変更',
};

// 在庫メンテナンス管理画面
export const StockMaintenanceComponent = {
  default: '在庫メンテナンス履歴',
};

/** --------------------------------------------------------- */

/**
 * 発注管理
 */
// 発注選択画面
export const CartComponent = {
  default: '発注選択',
};

// 発注届先画面
export const DeliverySearchComponent = {
  default: '届先選択',
};

// 発注販促資材画面
export const ItemSearchComponent = {
  default: '資材選択',
};

// 発注実績画面
export const OrderHistoryComponent = {
  default: '配送指示履歴',
};

// 注文登録画面
export const OrderRegisterComponent = {
  default: '注文登録',
};

// 発注画面
export const QuantityComponent = {
  default: '数量',
};

/** --------------------------------------------------------- */

/**
 * 計画
 */
// 計画一覧画面
export const ProjectListComponent = {
  default: 'IMD数量計画（新規）',
};

// 計画数確認画面
export const ProjectNumberConfirmationComponent = {
  default: '計画数確認',
};

// 計画数入力画面
export const ProjectNumberInputComponent = {
  default: '計画数入力',
};

/** --------------------------------------------------------- */

/**
 * サマリー
 */
//サマリー一覧画面
export const SummaryComponent = {
  default: '案件一覧',
};

// サマリー詳細画面
export const SummaryDisplayComponent = {
  default: '案件詳細',
};

/** --------------------------------------------------------- */

/**
 * 回覧
 */
// 回覧グループ画面
export const CirculationGroupComponent = {
  default: '回覧グループ',
};

// 回覧ルート画面
export const CirculationRootComponent = {
  default: '回覧ルート',
};

/** --------------------------------------------------------- */

/**
 * 事務局
 */
// 情報出力画面
export const OutputComponent = {
  default: '情報出力',
};

// 発送ステータスファイル登録画面
export const RegisterShippingResultComponent = {
  default: '出荷ステータス更新',
};

/** --------------------------------------------------------- */

/**
 * 帳票
 */
// 費用明細帳票出力画面
export const CostDetailsReportComponent = {
  default: 'IMD費用明細帳票出力',
};

// 棚卸帳票出力画面
export const InventoryReportComponent = {
  default: '棚卸帳票出力',
};

/** --------------------------------------------------------- */
