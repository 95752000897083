export const SUMMARY_DISPLAY_CONSTANT = {
  // サマリー目次の出力行数
  SUMMARY_ROW_DISPLAY_NUMBER: 3,
  // サマリーコンテンツ目次の出力行数
  SUMMARY_ROW_CONTENT_DISPLAY_NUMBER: 2,
  // 登録用ITEM_IDキー項目
  ITEM_ID: 'item_id',
  // 登録用コンテンツIDキー項目
  CONTENT_ID: 'content_id',
};
