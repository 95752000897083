import { DatePipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { API_URL_EVENT, API_URL_INPUT_EVENT } from 'manager/http-constants_key';
import { PROJECT_LIST_TEMPLATE } from 'manager/template-constant';
import { GenerateDisplay } from 'src/app/shared/generate/generate-display/generate-display-information/generate-display-information';
import { GenerateDisplayInformationComponent } from 'src/app/shared/generate/generate-display/generate-display-information/generate-display-information.component';
import { GENERATE_INPUT_TYPE } from 'src/app/shared/generate/generate-input/constant';
import { GenerateInputComponent } from 'src/app/shared/generate/generate-input/generate-input.component';
import { DeleteDialogService } from 'src/app/shared/html-parts/confirm-dialog/delete-dialog/delete-dialog.service';

@Component({
  selector: 'app-project-management-detail',
  templateUrl: './project-management-detail.component.html',
  styleUrls: [
    '../../../../shared/generate/generate-display/generate-display.component.scss',
    './project-management-detail.component.scss',
  ],
})

/**
 * 計画管理者詳細画面
 */
export class ProjectManagementDetailComponent implements OnInit {
  // 更新対象ID
  @Output() reloadID = new EventEmitter<string>();

  // 削除対象ID
  @Output() reloadDeleteID = new EventEmitter<string>();

  /** 詳細情報 */
  @ViewChild(GenerateDisplayInformationComponent)
  generateDisplayInformationComponent: GenerateDisplayInformationComponent;

  /** 編集画面 */
  @ViewChild(GenerateInputComponent)
  generateInputComponent: GenerateInputComponent;

  /* 画面用プロパティ */
  // 詳細画面表示フラグ
  detailsNav: boolean;

  // 詳細ID
  pkeyId: string;

  // 行データ
  rowData: any;

  constructor(private deleteDialogService: DeleteDialogService) {}

  ngOnInit(): void {}

  /**
   * 詳細情報表示
   * @param pkeyId 選択対象ID
   * @param rowData 行データ
   */
  public initial(pkeyId: string, rowData: any) {
    // 詳細画面表示
    this.detailsNav = true;

    // 詳細情報
    let generateDisplay: GenerateDisplay = new GenerateDisplay();
    generateDisplay.endPoint = API_URL_EVENT;
    generateDisplay.templateId =
      PROJECT_LIST_TEMPLATE.MANAGEMENT_OUTPUT_TEMPLATE_ID;
    this.generateDisplayInformationComponent.initial(generateDisplay, pkeyId);

    // 選択対象IDを格納
    this.pkeyId = pkeyId;

    // 行データを格納
    this.rowData = rowData;
  }

  /**
   * 編集ボタン出力表示判定
   * @returns true:ボタン表示、false:ボタン非表示
   */
  protected editButtonDisplayFlag(): boolean {
    return true;
  }

  /**
   * 複製ボタン出力表示判定
   * @returns true:ボタン表示、false:ボタン非表示
   */
  protected duplicateButtonDisplayFlag(): boolean {
    return true;
  }

  /**
   * 削除ボタン出力表示判定
   * @returns true:ボタン表示、false:ボタン非表示
   */
  protected deleteButtonDisplayFlag(): boolean {
    return true;
  }

  /**
   * 編集画面表示
   */
  protected edit() {
    // 編集画面表示
    this.generateInputComponent.initial(
      GENERATE_INPUT_TYPE.EDIT,
      API_URL_INPUT_EVENT,
      API_URL_EVENT,
      PROJECT_LIST_TEMPLATE.MANAGEMENT_INPUT_TEMPLATE_ID,
      this.pkeyId
    );
  }

  /**
   * 複製画面表示
   */
  protected duplicate() {
    // 複製画面表示
    this.generateInputComponent.initial(
      GENERATE_INPUT_TYPE.DUPLICATE,
      API_URL_INPUT_EVENT,
      API_URL_EVENT,
      PROJECT_LIST_TEMPLATE.DUPLICATE_INPUT_TEMPLATE_ID,
      this.pkeyId
    );
  }

  /**
   * 削除処理
   * @param pkeyId IDコード(クリックされたID)
   */
  protected delete() {
    // 削除ダイアログにより削除処理
    this.deleteDialogService
      .deleteDialog(API_URL_EVENT, 'イベントID', this.pkeyId)
      .subscribe((reloadID) => {
        // 削除対象IDにプライマリキーをセット
        this.reloadDeleteID.emit(reloadID);
      });
  }

  /**
   * 親画面へ返却する
   */
  public return(reloadID: string) {
    // 更新対象IDにプライマリキーをセット
    this.reloadID.emit(reloadID);
  }
}
