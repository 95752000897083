/** 発注実績画面 */
// 発注実績画面用
export const ORDER_PERFORMANCE_CONSTANT = {
  // csvファイル名
  CSV_FILENAME: 'order',
  // 詳細副題名
  DETAIL_SUB_TITLE: '発注者情報',
  // 注文ID
  ORDER_ID: 'order_id',
  // 注文ステータス
  STATUS: 'status',
  // 寄贈可否
  AVAILABILITY: 'availability',
  // 決定配送方法
  DELIVERY_TYPE: 'delivery_type',
  // 寄贈可否備考
  AVAILABILITY_NOTE: 'availability_note',
  // 出荷日
  SHIP_DATE: 'ship_date',
  // 納品日
  DELIVERY_DATE: 'delivery_date',
  // 対象テーブル
  TARGET_TABLE: 'order',
};

// ステータス更新画面のタイトル
export const STATUS_UPDATE_TITLE = {
  // 配送依頼(ステータス)
  DELIVERY_REQUEST: '承認可否登録',

  // 出荷作業中(ステータス)
  DURING_SHIPPING_WORK: '発送結果情報登録',

  // 出荷済み(ステータス)
  SHIPPED: '受贈情報登録',
};
