import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  HEADER_LIST,
  API_URL_UPLOAD_IMAGE,
  API_URL_DICVALUES,
  API_URL_REGIST_DATA,
  API_URL_UPLOAD_CSV,
  API_URL_RUN_COUNT,
  API_URL_DIC_LISTS,
} from 'manager/http-constants_key';
import { CommonService } from './common.service';
import { CONSTANT } from '../constant/constant';
import { MESSAGE_CODE } from 'src/app/shared/constant/message-constant';
import { REQUEST_IDENTIFIER } from '../html-parts/export-file/constant';

@Injectable({
  providedIn: 'root',
})

/**
 * 共通処理_DB操作
 */
export class DbOperationService {
  constructor(private http: HttpClient, private commonService: CommonService) {}

  /**
   * ヘッダー項目取得
   * @param templateId テンプレートID
   * @returns JSONデータ
   */
  public getHeaderList(templateId: number): Observable<any> {
    // LambdaのEndpointを生成
    const url = this.commonService.url(HEADER_LIST, '?TemplateID=', templateId);

    // 取得データの返却
    return this.http
      .get(url, { observe: 'response' })
      .pipe(map((res: HttpResponse<any>) => res));
  }

  /**
   * 辞書値取得
   * @param dicID 辞書番号
   * @param dicCode コード値
   * @returns JSONデータ
   */
  public getDicValues(dicID: number, dicCode?: number): Observable<any> {
    // LambdaのEndpointを生成
    const url = this.commonService.url(
      API_URL_DICVALUES,
      '?dicID=',
      dicID,
      dicCode ? '&dicCode=' + dicCode : CONSTANT.EMPTY_STRING
    );

    // 取得データの返却
    return this.http
      .get(url, { observe: 'response' })
      .pipe(map((res: HttpResponse<any>) => res));
  }

  /**
   * 全辞書情報取得
   * @returns JSONデータ
   */
  public getDicLists(): Observable<any> {
    // LambdaのEndpointを生成
    const url = this.commonService.url(API_URL_DIC_LISTS);

    // 取得データの返却
    return this.http
      .get(url, { observe: 'response' })
      .pipe(map((res: HttpResponse<any>) => res));
  }

  /**
   * DBデータ検索
   * @param endPoint REST APIエンドポイント
   * @param templateId テンプレートID
   * @param searchConditions 検索条件入力値
   * @param getCode コード値取得 (true:コード値取得、false:論理値取得)
   * @param runCount ページ番号
   * @returns JSONデータ
   * ※テンプレートIDの値が1つの場合、通常レスポンス
   * 　FamilyTemplateIdの場合、親と子のレスポンスが返却
   */
  public getData(
    endPoint: string,
    templateId: number | FamilyTemplateId,
    searchConditions?: any,
    getCode?: boolean,
    runCount?: number
  ): Observable<any> {
    // URLに渡すテンプレートID
    let useTemplateId;

    // テンプレートID型判定
    if ('[object Object]' == Object.prototype.toString.call(templateId)) {
      // オブジェクト型の場合

      // FamilyTemplateIdをparentTemplateId,childTemplateIdの文字列に変換
      useTemplateId = Object.values(templateId).join();
    } else {
      // 数値型の場合

      // templateIdを格納
      useTemplateId = templateId;
    }

    // LambdaのEndpointを生成
    const url = this.commonService.url(
      endPoint,
      '?TemplateID=',
      useTemplateId,
      // 検索条件入力値が存在するか否か
      searchConditions
        ? // 検索条件入力値が存在する場合
          CONSTANT.AMPERSAND + new URLSearchParams(searchConditions).toString()
        : // 検索条件入力値が存在しない場合
          CONSTANT.EMPTY_STRING,
      // コード値取得が存在するか否か
      getCode
        ? // コード値取得が存在する場合
          CONSTANT.AMPERSAND + 'CodeConvFLAG=0'
        : // コード値取得が存在しない場合
          CONSTANT.EMPTY_STRING,
      // ページ番号が存在するか否か
      runCount
        ? // ページ番号が存在する場合
          CONSTANT.AMPERSAND + 'RunCount=' + runCount
        : // ページ番号が存在しない場合
          CONSTANT.EMPTY_STRING
    );

    // 一覧情報を返却する
    return this.http
      .get(url, { observe: 'response' })
      .pipe(map((res: HttpResponse<any>) => res));
  }

  /**
   * DBデータ検索(分割取得)
   * @param table 対象テーブル
   * @param endPoint REST APIエンドポイント
   * @param templateId テンプレートID
   * @param searchConditions 検索条件入力値
   * @param getCode コード値取得 (true:コード値取得、false:論理値取得)
   * @returns JSONデータ
   */
  public getForkJoinData(
    table: string,
    endPoint: string,
    templateId: number,
    searchConditions?: any,
    getCode?: boolean
  ): Subject<any> {
    // 返却用リスト
    let returnResponse = new Subject<any>();

    /* API分割リクエストを生成(DBデータ検索用) */
    this.createForkJoinTask(
      table,
      endPoint,
      templateId,
      searchConditions,
      getCode
    ).subscribe((task) => {
      // 非同期同時実行
      forkJoin(task).subscribe((responseList) => {
        // 返却用レスポンス
        let response: any = new Object();

        // response分ループ
        responseList.forEach((responseData, index) => {
          // ループ回数が0回目か否か
          if (!index) {
            // ループ回数が0回目の場合

            // status等の込みデータを格納
            response = responseData;
          } else {
            // ループ回数が1回目以降の場合

            // コード値の一覧情報が存在するか否か
            if (!this.commonService.checkNoneResponse(responseData)) {
              // コード値の一覧情報が存在する場合

              // 返却用レスポンスにレスポンスボディをマージする
              response.body = response.body.concat(responseData.body);
            }
          }
        });

        // 結合されたresponseを返却
        returnResponse.next(response);
      });
    });

    return returnResponse;
  }

  /**
   * API分割リクエストを生成(DBデータ検索用)
   * @param table 対象テーブル
   * @param endPoint REST APIエンドポイント
   * @param templateId テンプレートID
   * @param searchConditions 検索条件入力値
   * @param getCode コード値取得 (true:コード値取得、false:論理値取得)
   * @returns Observable<any>[]
   */
  public createForkJoinTask(
    table: string,
    endPoint: string,
    templateId: number,
    searchConditions?: any,
    getCode?: boolean
  ): Subject<Observable<any>[]> {
    // 返却用非同期同時実行リスト
    let task = new Subject<Observable<any>[]>();

    /* ブロック実行必要回数の取得API */
    this.http
      .get(
        this.commonService.url(
          API_URL_RUN_COUNT,
          '?TableName=',
          table,
          CONSTANT.AMPERSAND,
          'TemplateID=',
          templateId,
          // 検索条件入力値が存在するか否か
          searchConditions
            ? // 検索条件入力値が存在する場合
              CONSTANT.AMPERSAND +
                new URLSearchParams(searchConditions).toString()
            : // 検索条件入力値が存在しない場合
              CONSTANT.EMPTY_STRING
        ),
        { observe: 'response' }
      )
      .pipe(map((res: HttpResponse<any>) => res))
      .subscribe((response) => {
        // 非同期同時実行リスト
        let taskList: Observable<any>[] = new Array();

        // 結果ページ数分ループを実施
        for (let runCount = 1; runCount <= response.body.RunCount; runCount++) {
          // 非同期実行リストを生成
          taskList.push(
            /* DBデータ検索 */
            this.getData(
              endPoint,
              templateId,
              searchConditions,
              getCode,
              runCount
            )
          );
        }
        // 非同期同時実行リストを返却
        task.next(taskList);
      });

    return task;
  }

  /**
   * DBデータを1件返却
   * @param endPoint REST APIエンドポイント
   * @param templateId テンプレートID
   * @param code コードカラム
   * @param codeValue コード値
   */
  public getSingleData(
    endPoint: string,
    templateId: number | FamilyTemplateId,
    code: string,
    codeValue: string | number
  ): Observable<any> {
    // URLに渡すテンプレートID
    let useTemplateId;

    // テンプレートID型判定
    if ('[object Object]' == Object.prototype.toString.call(templateId)) {
      // オブジェクト型の場合

      // FamilyTemplateIdをparentTemplateId,childTemplateIdの文字列に変換
      useTemplateId = Object.values(templateId).join();
    } else {
      // 数値型の場合

      // templateIdを格納
      useTemplateId = templateId;
    }

    // LambdaのEndpointを格納先
    const url = this.commonService.url(
      endPoint,
      '?TemplateID=',
      useTemplateId,
      CONSTANT.AMPERSAND,
      code,
      CONSTANT.EQUAL,
      codeValue
    );

    // 一覧情報を返却する
    return this.http
      .get(url, { observe: 'response' })
      .pipe(map((res: HttpResponse<any>) => res));
  }

  /**
   * DBデータを1件返却(詳細レスポンス)
   * @param endPoint REST APIエンドポイント
   * @param templateId テンプレートID
   * @param pkeyId IDコード
   */
  public getDisplayData(
    endPoint: string,
    templateId: number,
    pkeyId: string | number
  ): Observable<any> {
    // LambdaのEndpointを格納先
    const url = this.commonService.url(
      endPoint,
      pkeyId,
      '?TemplateID=',
      templateId
    );

    // 詳細情報を返却する
    return this.http
      .get(url, { observe: 'response' })
      .pipe(map((res: HttpResponse<any>) => res));
  }

  /**
   * DBデータ登録
   * @param endPoint REST APIエンドポイント
   * @param insertData 登録データ
   * @returns JSONデータ
   */
  public insertData(endPoint: string, data: object): Observable<any> {
    // 登録結果を返却する
    return this.http
      .post(this.commonService.url(endPoint), data, { observe: 'response' })
      .pipe(map((res: HttpResponse<any>) => res));
  }

  /**
   * DBデータ更新(1件)
   * @param endPoint REST APIエンドポイント
   * @param pkeyId 対象データのID
   * @param updateData 更新データ
   * @returns JSONデータ
   */
  public updateData(
    endPoint: string,
    pkeyId: string | number,
    updateData?: object
  ): Observable<any> {
    // LambdaのEndpointを格納先
    const url = this.commonService.url(endPoint, pkeyId);

    // 更新結果を返却する
    return this.http
      .put(url, updateData ? updateData : new Object(), { observe: 'response' })
      .pipe(map((res: HttpResponse<any>) => res));
  }

  /**
   * DBデータ更新(複数件)
   * @param endPoint REST APIエンドポイント
   * @param updateData 更新データ
   * @returns JSONデータ
   */
  public updateMultipleData(
    endPoint: string,
    updateData: object
  ): Observable<any> {
    // LambdaのEndpointを格納先
    const url = this.commonService.url(endPoint);

    // 更新結果を返却する
    return this.http
      .put(url, updateData, { observe: 'response' })
      .pipe(map((res: HttpResponse<any>) => res));
  }

  /**
   * DBデータ削除(1件)
   * @param endPoint REST APIエンドポイント
   * @param pkeyId 対象データのID
   * @returns JSONデータ
   */
  public deleteData(
    endPoint: string,
    pkeyId: string | number
  ): Observable<any> {
    // LambdaのEndpointを生成
    const url = this.commonService.url(endPoint, pkeyId);

    // 削除結果を返却する
    return this.http
      .delete(url, { observe: 'response' })
      .pipe(map((res: HttpResponse<any>) => res));
  }

  /**
   * ファイル登録
   * @param fileName 登録するファイル名
   * @param file ファイル情報
   */
  public insertFile(fileName: string, file: any) {
    // ファイル情報(base64)取得処理
    this.commonService.getBase64(file).then((fileInformation) => {
      // ファイル情報(base64)が返却後、処理を続行

      // ファイル登録を実施
      return this.http
        .post(
          this.commonService.url(API_URL_UPLOAD_CSV),
          {
            fileName: fileName,
            BASE64ENC: fileInformation.replace(/^data:.*?;base64,/, ''),
          },
          { observe: 'response' }
        )
        .pipe(map((res: HttpResponse<any>) => res))
        .subscribe(() => {
          console.log(this.commonService.msg(MESSAGE_CODE.I00013));
        });
    });
  }

  /**
   * ファイル取得(base64形式)
   * @param endPoint REST APIエンドポイント
   * @param request リクエスト形式
   * @param searchConditions 検索条件入力値
   * @returns BASE64データ
   */
  public getFile(
    endPoint: string,
    request: string,
    searchConditions?: any
  ): Observable<any> {
    // LambdaのEndpointを格納
    let url;

    // リクエスト形式がPOSTか否か
    if (REQUEST_IDENTIFIER.POST == request) {
      // リクエスト形式がPOSTの場合

      // ファイル情報(base64形式)を返却する
      return this.http
        .post(url, searchConditions, {
          observe: 'response',
          responseType: 'text',
        })
        .pipe(map((res: HttpResponse<any>) => res));
    }

    // リクエスト形式がGETの場合

    // 検索条件入力値が存在しているか否か
    if (searchConditions) {
      // 検索条件入力値が存在している場合

      // 検索項目値でURLを生成
      url = this.commonService.url(
        endPoint,
        CONSTANT.QUESTION,
        new URLSearchParams(searchConditions).toString()
      );
    } else {
      // URLを生成
      url = this.commonService.url(endPoint);
    }

    // 一覧情報を返却する
    return this.http
      .get(url, { observe: 'response', responseType: 'text' })
      .pipe(map((res: HttpResponse<any>) => res));
  }

  /**
   * ファイル情報登録
   * @param csvData CSVデータ
   * @returns JSONデータ
   */
  public insertFileData(csvData: Object): Observable<any> {
    // 取得データの返却
    return this.http
      .post(this.commonService.url(API_URL_REGIST_DATA), csvData, {
        observe: 'response',
      })
      .pipe(map((res: HttpResponse<any>) => res));
  }

  /**
   * 画像登録
   * @param pkeyId 対象データのID
   * @param imageFile - 画像ファイル
   */
  public insertImage(pkeyId: string, imageFile: any) {
    // LambdaのEndpointを格納
    const url = this.commonService.url(API_URL_UPLOAD_IMAGE, pkeyId);

    // ファイル情報(base64)取得処理
    this.commonService.getBase64(imageFile).then((imageInformation) => {
      // ファイル情報(base64)が返却後、処理を続行

      // 画像登録を実施
      return this.http
        .post(
          url,
          {
            BASE64ENC: imageInformation.replace(/^data:.*?;base64,/, ''),
          },
          { observe: 'response' }
        )
        .pipe(map((res: HttpResponse<any>) => res))
        .subscribe(() => {
          console.log(this.commonService.msg(MESSAGE_CODE.I00003));
        });
    });
  }
}

/** 親子レスポンス_テンプレートオブジェクト */
export class FamilyTemplateId {
  // 親テンプレート
  private _parentTemplateId: number = 0;

  // 子テンプレート
  private _childTemplateId: number;

  constructor(init?: Partial<FamilyTemplateId>) {
    Object.assign(this, init);
  }

  set parentTemplateId(parentTemplateId: number) {
    this._parentTemplateId = parentTemplateId;
  }

  get parentTemplateId(): number {
    return this._parentTemplateId;
  }

  set childTemplateId(childTemplateId: number) {
    this._childTemplateId = childTemplateId;
  }

  get child(): number {
    return this._childTemplateId;
  }
}
