import { Component, Input, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/shared/service/login.service';
import { LoadingState } from '../loading/loading-state';
import { COMPONENT_VALUE_KEY, LOADING_KEY } from '../../constant/constant';
import { CommonService } from '../../service/common.service';
import { TITLE } from 'manager/environment';
import { SESSION_KEY } from '../../constant/session-constants';
import { AuthorityDecision } from 'manager/user-authority-constant';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})

/**
 * ヘッダー
 */
export class HeaderComponent implements OnInit {
  // ヘッダー判定フラグ(true:通常モード、false:メッセージモード)
  @Input() headerFlag: boolean;

  // タイトルヘッダーを設定
  title: string;

  // カートボタン表示フラグ
  cartDisplayFlag: boolean = false;

  // メニュー情報
  menuitems: MenuItem[] = new Array();

  constructor(
    private router: Router,
    private commonService: CommonService,
    private loginService: LoginService,
    private loadingState: LoadingState
  ) {}

  ngAfterContentChecked() {
    // ヘッダー判定フラグが通常モードか否か
    if (this.headerFlag) {
      // ヘッダー判定フラグが通常モードの場合

      // タイトルヘッダーを設定
      this.title = this.commonService.getComponentValue(
        COMPONENT_VALUE_KEY.HEADER_TITLE
      );
    } else {
      // ヘッダー判定フラグがメッセージモードの場合

      // タイトルフッターをタイトルヘッダーに設定
      this.title = TITLE.TITLE_FOOTER;
    }
  }

  ngOnInit() {
    // 画面ロードフラグをON(ロード中状態)
    this.loadingState.loadStart(LOADING_KEY.HEADER_MENU);

    // ログインユーザ情報取得処理
    this.loginService.getLoginUser().subscribe((response) => {
      // セッションにユーザ情報を格納
      window.sessionStorage.setItem(
        SESSION_KEY.loginUserInformation,
        JSON.stringify(response.body[0])
      );

      // TODO なおしてねっとでは不要
      /*
      // ユーザ情報の組織種別を判定
      if (
        this.commonService.decisionUserAuthority(
          false,
          new AuthorityDecision({
            user_1: true,
          })
        )
      ) {
        // カートボタンを表示
        this.cartDisplayFlag = true;
      }
      */

      // メニュー情報設定
      this.menu();

      // 画面ロードフラグをOFF(ロード終了)
      this.loadingState.loadSleepEnd(0.3, LOADING_KEY.HEADER_MENU);
    });
  }

  /**
   * メニュー情報設定
   */
  private menu() {
    /**
     * 配送依頼カテゴリ
     */
    {
      /* 配送依頼カテゴリを生成 */
      let order: object = new Object();
      order['label'] = '配送指示';
      order['items'] = new Array();

      /* 配送依頼登録 */
      // TODO なおしてねっとで不要の為、コメントアウト
      /*
      if (
        this.commonService.decisionUserAuthority(
          false,
          new AuthorityDecision({
            user_1: true,
          })
        )
      ) {
        const item: object = {
          label: '配送依頼登録',
          icon: 'pi pi-search',
          routerLink: 'pages/order/item-search',
          routerLinkActiveOptions: 'active',
        };
        order['items'].push(item);
      }
      */
      /* 届先選択 */
      // TODO なおしてねっとで不要の為、コメントアウト
      /*
      if (
        this.commonService.decisionUserAuthority(
          false,
          new AuthorityDecision({
            user_1: true,
          })
        )
      ) {
        const item: object = {
          label: '届先選択',
          icon: 'pi pi-search',
          routerLink: 'pages/order/delivery-search',
          routerLinkActiveOptions: 'active',
        };
        order['items'].push(item);
      }
      */
      /* 選択リスト */
      // TODO なおしてねっとで不要の為、コメントアウト
      /*
      if (
        this.commonService.decisionUserAuthority(
          false,
          new AuthorityDecision({
            user_1: true,
          })
        )
      ) {
        const item: object = {
          label: '選択リスト',
          icon: 'pi pi-search',
          routerLink: 'pages/order/cart',
          routerLinkActiveOptions: 'active',
        };
        order['items'].push(item);
      }
      */
      /* 注文登録 */
      // TODO なおしてねっとで不要の為、コメントアウト
      /*
      if (
        this.commonService.decisionUserAuthority(
          false,
          new AuthorityDecision({
            user_1: true,
          })
        )
      ) {
        const item: object = {
          label: '注文登録',
          icon: 'pi pi-search',
          routerLink: 'pages/order/orderRegister',
          routerLinkActiveOptions: 'active',
        };
        order['items'].push(item);
      }
      */
      /* 注文履歴 */
      // TODO なおしてねっとで不要の為、コメントアウト
      /*
      if (
        this.commonService.decisionUserAuthority(
          false,
          new AuthorityDecision({
            user_1: true,
          })
        )
      ) {
        const item: object = {
          label: '配送指示履歴',
          icon: 'pi pi-list',
          routerLink: 'pages/order/orderHistory',
          routerLinkActiveOptions: 'active',
        };
        order['items'].push(item);
      }
      */
      // 配送依頼カテゴリのメニューが存在するか否か
      if (order['items'].length) {
        // 配送依頼カテゴリのメニューが存在する場合

        // 配送依頼カテゴリをメニューに追加
        this.menuitems.push(order);
      }
    }

    /**
     * 計画カテゴリ
     */
    {
      /* 計画カテゴリを生成 */
      let project: object = new Object();
      project['label'] = '計画作成';
      project['items'] = new Array();

      /* IMD数量計画（新規） */
      // TODO なおしてねっとで不要の為、コメントアウト
      /*
      if (
        this.commonService.decisionUserAuthority(
          false,
          new AuthorityDecision({
            user_1: true,
          })
        )
      ) {
        const item: object = {
          label: 'IMD数量計画（新規）',
          icon: 'pi pi-file',
          routerLink: 'pages/project/project-list',
          routerLinkActiveOptions: 'active',
        };
        project['items'].push(item);
      }
      */
      // 計画カテゴリのメニューが存在するか否か
      if (project['items'].length) {
        // 計画カテゴリのメニューが存在する場合

        // 計画カテゴリをメニューに追加
        this.menuitems.push(project);
      }
    }

    /**
     * 在庫メンテナンスカテゴリ
     */
    {
      /* 在庫メンテナンスカテゴリを生成 */
      let stock: object = new Object();
      stock['label'] = '在庫メンテナンス';
      stock['items'] = new Array();

      /* 製作指示 */
      // TODO なおしてねっとで不要の為、コメントアウト
      /*
      if (
        this.commonService.decisionUserAuthority(
          false,
          new AuthorityDecision({
            user_1: true,
          })
        )
      ) {
        const item: object = {
          label: '製作指示（通常）',
          icon: 'pi pi-upload',
          routerLink: 'pages/stock/productionInstruction',
          routerLinkActiveOptions: 'active',
        };
        stock['items'].push(item);
      }
      */
      /* 在庫登録・変更 */
      // TODO なおしてねっとで不要の為、コメントアウト
      /*
      if (
        this.commonService.decisionUserAuthority(
          false,
          new AuthorityDecision({
            user_1: true,
          })
        )
      ) {
        const item: object = {
          label: '在庫登録・変更',
          icon: 'pi pi-upload',
          routerLink: 'pages/stock/registerStockMaintenance',
          routerLinkActiveOptions: 'active',
        };
        stock['items'].push(item);
      }
      */
      /* 在庫メンテナンス管理 */
      // TODO なおしてねっとで不要の為、コメントアウト
      /*
      if (
        this.commonService.decisionUserAuthority(
          false,
          new AuthorityDecision({
            user_1: true,
          })
        )
      ) {
        const item: object = {
          label: '在庫メンテナンス履歴',
          icon: 'pi pi-list',
          routerLink: 'pages/stock/stockMaintenanc',
          routerLinkActiveOptions: 'active',
        };
        stock['items'].push(item);
      }
      */
      // 在庫メンテナンスカテゴリのメニューが存在するか否か
      if (stock['items'].length) {
        // 在庫メンテナンスカテゴリのメニューが存在する場合

        // 在庫メンテナンスカテゴリをメニューに追加
        this.menuitems.push(stock);
      }
    }

    /**
     * 回覧カテゴリ
     */
    {
      /* 回覧カテゴリを生成 */
      let circulation: object = new Object();
      circulation['label'] = '回覧';
      circulation['items'] = new Array();

      /* 案件一覧 */
      if (
        this.commonService.decisionUserAuthority(
          false,
          new AuthorityDecision({
            user_1: true,
          })
        )
      ) {
        const item: object = {
          label: '案件一覧',
          icon: 'pi pi-file',
          routerLink: 'pages/summary/summary-list',
          routerLinkActiveOptions: 'active',
        };
        circulation['items'].push(item);
      }

      /* 回覧グループマスタ */
      if (
        this.commonService.decisionUserAuthority(
          false,
          new AuthorityDecision({
            user_1: true,
          })
        )
      ) {
        const item: object = {
          label: '回覧グループ',
          icon: 'pi pi-file',
          routerLink: 'pages/circulation/circulation-group',
          routerLinkActiveOptions: 'active',
        };
        circulation['items'].push(item);
      }

      /* 回覧ルートマスタ */
      if (
        this.commonService.decisionUserAuthority(
          false,
          new AuthorityDecision({
            user_1: true,
          })
        )
      ) {
        const item: object = {
          label: '回覧ルート',
          icon: 'pi pi-file',
          routerLink: 'pages/circulation/circulation-root',
          routerLinkActiveOptions: 'active',
        };
        circulation['items'].push(item);
      }

      // 回覧カテゴリのメニューが存在するか否か
      if (circulation['items'].length) {
        // 回覧カテゴリのメニューが存在する場合

        // 回覧カテゴリをメニューに追加
        this.menuitems.push(circulation);
      }
    }

    /**
     * マスタ管理カテゴリ
     */
    {
      /* マスタ管理カテゴリを生成 */
      let master: object = new Object();
      master['label'] = 'マスタ管理';
      master['items'] = new Array();

      /* 販促資材マスタ */
      // TODO なおしてねっとで不要の為、コメントアウト
      /*
      if (
        this.commonService.decisionUserAuthority(
          false,
          new AuthorityDecision({
            user_1: true,
          })
        )
      ) {
        const item: object = {
          label: '販促資材',
          icon: 'pi pi-file',
          routerLink: 'pages/master/item',
          routerLinkActiveOptions: 'active',
        };
        master['items'].push(item);
      }
      */

      /* 販促資材斡旋マスタ */
      // TODO なおしてねっとで不要の為、コメントアウト
      /*
      if (
        this.commonService.decisionUserAuthority(
          false,
          new AuthorityDecision({
            user_1: true,
          })
        )
      ) {
        const item: object = {
          label: '販促資材斡旋',
          icon: 'pi pi-file',
          routerLink: 'pages/master/itemMediation',
          routerLinkActiveOptions: 'active',
        };
        master['items'].push(item);
      }
      */

      /* 届先マスタ */
      // TODO なおしてねっとで不要の為、コメントアウト
      /*
      if (
        this.commonService.decisionUserAuthority(
          false,
          new AuthorityDecision({
            user_1: true,
          })
        )
      ) {
        const item: object = {
          label: '届先',
          icon: 'pi pi-file',
          routerLink: 'pages/master/delivery',
          routerLinkActiveOptions: 'active',
        };
        master['items'].push(item);
      }
      */

      /* 届先グループマスタ */
      // TODO なおしてねっとで不要の為、コメントアウト
      /*
      if (
        this.commonService.decisionUserAuthority(
          false,
          new AuthorityDecision({
            user_1: true,
          })
        )
      ) {
        const item: object = {
          label: '届先グループ',
          icon: 'pi pi-file',
          routerLink: 'pages/master/deliveryGroup',
          routerLinkActiveOptions: 'active',
        };
        master['items'].push(item);
      }
      */

      /* 得意先マスタ */
      // TODO なおしてねっとで不要の為、コメントアウト
      /*
      if (
        this.commonService.decisionUserAuthority(
          false,
          new AuthorityDecision({
            user_1: true,
          })
        )
      ) {
        const item: object = {
          label: '得意先',
          icon: 'pi pi-file',
          routerLink: 'pages/master/customer',
          routerLinkActiveOptions: 'active',
        };
        master['items'].push(item);
      }
      */

      /* 営業日マスタ */
      // TODO なおしてねっとで不要の為、コメントアウト
      /*
      if (
        this.commonService.decisionUserAuthority(
          false,
          new AuthorityDecision({
            user_1: true,
          })
        )
      ) {
        const item: object = {
          label: '営業日マスタ',
          icon: 'pi pi-file',
          routerLink: 'pages/master/bizday',
          routerLinkActiveOptions: 'active',
        };
        master['items'].push(item);
      }
      */

      /* 組織マスタ */
      if (
        this.commonService.decisionUserAuthority(
          false,
          new AuthorityDecision({
            user_1: true,
          })
        )
      ) {
        const item: object = {
          label: '組織',
          icon: 'pi pi-file',
          routerLink: 'pages/master/department',
          routerLinkActiveOptions: 'active',
        };
        master['items'].push(item);
      }

      /* ユーザーマスタ */
      if (
        this.commonService.decisionUserAuthority(
          false,
          new AuthorityDecision({
            user_1: true,
          })
        )
      ) {
        const item: object = {
          label: 'ユーザ',
          icon: 'pi pi-file',
          routerLink: 'pages/master/user',
          routerLinkActiveOptions: 'active',
        };
        master['items'].push(item);
      }

      /* お知らせマスタ */
      if (
        this.commonService.decisionUserAuthority(
          false,
          new AuthorityDecision({
            user_1: true,
          })
        )
      ) {
        const item: object = {
          label: 'お知らせ',
          icon: 'pi pi-file',
          routerLink: 'pages/master/information',
          routerLinkActiveOptions: 'active',
        };
        master['items'].push(item);
      }

      // マスタ管理カテゴリのメニューが存在するか否か
      if (master['items'].length) {
        // マスタ管理カテゴリのメニューが存在する場合

        // マスタ管理カテゴリをメニューに追加
        this.menuitems.push(master);
      }
    }

    /**
     * 事務局カテゴリ
     */
    {
      /* 事務局カテゴリを生成 */
      let secretariat: object = new Object();
      secretariat['label'] = '事務局';
      secretariat['items'] = new Array();

      /* 出荷ステータス更新 */
      // TODO なおしてねっとで不要の為、コメントアウト
      /*
      if (
        this.commonService.decisionUserAuthority(
          false,
          new AuthorityDecision({
            user_1: true,
          })
        )
      ) {
        const item: object = {
          label: '出荷ステータス更新',
          icon: 'pi pi-upload',
          routerLink: 'pages/secretariat/registerShippingResult',
          routerLinkActiveOptions: 'active',
        };
        secretariat['items'].push(item);
      }
      */
      /* 情報出力 */
      // TODO なおしてねっとで不要の為、コメントアウト
      /*
      if (
        this.commonService.decisionUserAuthority(
          false,
          new AuthorityDecision({
            user_1: true,
          })
        )
      ) {
        const item: object = {
          label: '情報出力',
          icon: 'pi pi-file',
          routerLink: 'pages/secretariat/output',
          routerLinkActiveOptions: 'active',
        };
        secretariat['items'].push(item);
      }
      */
      // 事務局カテゴリのメニューが存在するか否か
      if (secretariat['items'].length) {
        // 事務局カテゴリのメニューが存在する場合

        // 事務局カテゴリをメニューに追加
        this.menuitems.push(secretariat);
      }
    }

    /**
     * 帳票カテゴリ
     */
    {
      /* 帳票カテゴリを生成 */
      let report: object = new Object();
      report['label'] = '帳票';
      report['items'] = new Array();

      /* 棚卸帳票出力 */
      // TODO なおしてねっとで不要の為、コメントアウト
      /*
      if (
        this.commonService.decisionUserAuthority(
          false,
          new AuthorityDecision({
            user_1: true,
          })
        )
      ) {
        const item: object = {
          label: '棚卸帳票出力',
          icon: 'pi pi-file',
          routerLink: 'pages/report/inventoryReport',
          routerLinkActiveOptions: 'active',
        };
        report['items'].push(item);
      }
      */
      /* 費用明細帳票出力 */
      // TODO なおしてねっとで不要の為、コメントアウト
      /*
      if (
        this.commonService.decisionUserAuthority(
          false,
          new AuthorityDecision({
            user_1: true,
          })
        )
      ) {
        const item: object = {
          label: 'IMD費用明細帳票出力',
          icon: 'pi pi-file',
          routerLink: 'pages/report/costDetailsReport',
          routerLinkActiveOptions: 'active',
        };
        report['items'].push(item);
      }
      */
      // 帳票カテゴリのメニューが存在するか否か
      if (report['items'].length) {
        // 帳票カテゴリのメニューが存在する場合

        // 帳票カテゴリをメニューに追加
        this.menuitems.push(report);
      }
    }

    /**
     * その他カテゴリ
     */
    {
      /* その他カテゴリを生成 */
      let others: object = new Object();
      others['label'] = 'その他';
      others['items'] = new Array();

      /* 資料・リンク集 */
      // TODO なおしてねっとで不要の為、コメントアウト
      /*
      {
        let item: object = {
          label: '資料・リンク集',
          icon: 'pi pi-list',
          routerLink: 'pages/link',
          routerLinkActiveOptions: 'active',
        };
        others['items'].push(item);
      }
      */

      // その他カテゴリのメニューが存在するか否か
      if (others['items'].length) {
        // その他カテゴリのメニューが存在する場合

        // その他カテゴリをメニューに追加
        this.menuitems.push(others);
      }
    }

    /* 線 */
    this.menuitems.push({ separator: true });

    /* ログアウト */
    {
      const logout: object = {
        items: [
          {
            label: 'ログアウト',
            icon: 'pi pi-times-circle',
            command: (event) => {
              this.loginService.logout();
            },
          },
        ],
      };
      this.menuitems.push(logout);
    }
  }

  /**
   * TOP画面遷移
   */
  onHome() {
    this.router.navigate(['pages/top']);
  }

  /**
   * カート画面遷移
   */
  onCart() {
    this.router.navigate(['pages/order/cart']);
  }
}
