import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { GenerateDisplay } from 'src/app/shared/generate/generate-display/generate-display-information/generate-display-information';
import {
  API_URL_CIRCULATIONS,
  API_URL_CONTENTS,
  API_URL_FILES,
} from 'manager/http-constants_key';
import { SUMMARY_CIRCULATION_TEMPLATE } from 'manager/template-constant';
import { GenerateDisplayInformationComponent } from 'src/app/shared/generate/generate-display/generate-display-information/generate-display-information.component';
import { SummaryCirculationAnswerComponent } from './summary-circulation-answer/summary-circulation-answer.component';
import { SUMMARY_CIRCULATION_CONSTANT } from './constant';
import { SummaryCirculationService } from './summary-circulation.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { SummaryCirculationEndComponent } from './summary-circulation-end/summary-circulation-end.component';
import { SummaryContentDisplayInputComponent } from '../summary-display/summary-content-display-input/summary-content-display-input.component';
import { AddAnswerMemberComponent } from './add-answer-member/add-answer-member.component';
import { DbOperationService } from 'src/app/shared/service/db-operation.service';
import { NUMBER } from 'src/app/shared/constant/constant';
import {
  MessageData,
  ToastMessageData,
} from 'src/app/shared/html-parts/message-common/message-data';
import { TOAST } from 'src/app/shared/constant/primeng-constants';
import { MESSAGE_CODE } from 'src/app/shared/constant/message-constant';
import { SESSION_KEY } from 'src/app/shared/constant/session-constants';

@Component({
  selector: 'app-summary-circulation',
  templateUrl: './summary-circulation.component.html',
  styleUrls: ['./summary-circulation.component.scss'],
})

/**
 * サマリー回覧
 */
export class SummaryCirculationComponent implements OnInit {
  /** 詳細情報 */
  @ViewChild(GenerateDisplayInformationComponent)
  generateDisplayInformationComponent: GenerateDisplayInformationComponent;

  /** 回答画面 */
  @ViewChild(SummaryCirculationAnswerComponent)
  summaryCirculationAnswerComponent: SummaryCirculationAnswerComponent;

  /** 回覧終了画面 */
  @ViewChild(SummaryCirculationEndComponent)
  summaryCirculationEndComponent: SummaryCirculationEndComponent;

  /** 回答者画面 */
  @ViewChild(AddAnswerMemberComponent)
  addAnswerMemberComponent: AddAnswerMemberComponent;

  /** 回覧入力画面ダイアログ */
  @ViewChild(SummaryContentDisplayInputComponent)
  summaryContentDisplayInputComponent: SummaryContentDisplayInputComponent;

  // 案件ID(クエリパラメータ)
  itemId: string = this.route.snapshot.queryParams.item_id;

  // コンテンツID(クエリパラメータ)
  contentId: string = this.route.snapshot.queryParams.content_id;

  // 回覧ID(クエリパラメータ)
  circulationId: string = this.route.snapshot.queryParams.circulation_id;

  // 案件作成者ID(クエリパラメータ)
  contentCreateUserId: string =
    this.route.snapshot.queryParams.content_create_user_id;

  // 案件作成者ID(クエリパラメータ)
  itemCreateUserId: string =
    this.route.snapshot.queryParams.item_create_user_id;

  // 回覧作成者ID(クエリパラメータ)
  circulationCreateUserId: string =
    this.route.snapshot.queryParams.circulation_create_user_id;

  /* 画面用プロパティ */
  // 回覧状況リスト格納先
  circulationStatusList: any[] = new Array();

  // ファイル情報格納先
  fileInformationList: any[] = new Array();

  // 回覧グループメンバー格納先
  circulationGroupMemberList: any[] = new Array();

  // 回答開始フラグ(true:開始可能,false:開始不可能)
  circulationIsStartableFlg: boolean = false;

  // ファイル情報ローディング
  fileInformationloading: boolean;

  // 回覧状況リストローディング
  circulationStatusloading: boolean;

  // 回答状況の辞書情報格納先
  dicValuesList: any[] = this.commonService.dictionaryDicIdList(
    SUMMARY_CIRCULATION_CONSTANT.SP_DIC_ID
  );

  // 回覧のfile1に相当するファイルID
  circulationFileId: string = '';

  //ログインユーザ情報
  loginUser = JSON.parse(
    window.sessionStorage.getItem(SESSION_KEY.loginUserInformation)
  );

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private summaryCirculationService: SummaryCirculationService,
    private commonService: CommonService,
    private dbOperationService: DbOperationService,
    private messageData: MessageData
  ) {}

  ngOnInit(): void {
    // 回覧状況取得を実施
    this.getCirculationStatus();

    // 回答ボタン出力表示情報取得を実施
    this.getCirculationIsStartable();

    // ファイル情報取得
    this.getFileInformation();

    // 回覧のファイルID取得
    this.getFileId();

    //回覧終了可能グループメンバー取得
    this.getCirculationGroupMember();
  }

  ngAfterViewInit() {
    // 詳細情報取得を実施
    this.getSummaryCirculatiDisplay();
  }

  /**
   * 詳細情報取得
   */
  public getSummaryCirculatiDisplay() {
    /* 詳細情報 */
    {
      let generateDisplayList: GenerateDisplay[] = new Array();

      // サマリーコンテンツ詳細情報
      {
        let generateDisplay: GenerateDisplay = new GenerateDisplay();
        generateDisplay.endPoint = API_URL_CONTENTS;
        generateDisplay.title = 'コンテンツ情報';
        generateDisplay.templateId =
          SUMMARY_CIRCULATION_TEMPLATE.SUMMARY_CONTENT_OUTPUT_TEMPLATE_ID;
        generateDisplay.pkeyId = this.contentId;
        generateDisplayList.push(generateDisplay);
      }

      // サマリー回覧詳細情報
      {
        let generateDisplay: GenerateDisplay = new GenerateDisplay();
        generateDisplay.endPoint = API_URL_CIRCULATIONS;
        generateDisplay.title = '回覧情報';
        generateDisplay.templateId =
          SUMMARY_CIRCULATION_TEMPLATE.SUMMARY_CIRCULATION_OUTPUT_TEMPLATE_ID;
        generateDisplay.pkeyId = this.circulationId;
        generateDisplayList.push(generateDisplay);
      }

      // 詳細情報取得
      this.generateDisplayInformationComponent.initial(generateDisplayList);
    }
  }

  /**
   * ファイル情報取得
   */
  public getFileInformation() {
    // ファイル情報ローディングを開始
    this.fileInformationloading = true;

    // ファイル情報取得
    this.dbOperationService
      .getDisplayData(
        API_URL_FILES,
        SUMMARY_CIRCULATION_TEMPLATE.SUMMARY_CONTENT_FILE_OUTPUT_TEMPLATE_ID,
        this.circulationId
      )
      .subscribe((response) => {
        // ファイル情報が存在するか否か
        if (!this.commonService.checkNoneResponse(response)) {
          // ファイル情報のJSONをオブジェクトに格納する
          this.fileInformationList = response.body;
        } else {
          this.fileInformationList = new Array();
        }

        // ファイル情報ローディングを終了
        this.fileInformationloading = false;
      });
  }

  /**
   * 回覧状況取得
   */
  public getCirculationStatus() {
    // 回覧状況リストローディングを開始
    this.circulationStatusloading = true;

    // 回覧状況情報取得
    this.summaryCirculationService
      .getMembersList(this.circulationId)
      .subscribe((response) => {
        // 回覧状況情報が存在するか否か
        if (!this.commonService.checkNoneResponse(response)) {
          // 回覧状況情報のJSONをオブジェクトに格納する
          this.circulationStatusList = response.body;
        } else {
          this.circulationStatusList = new Array();
        }

        // 回覧状況リストローディングを終了
        this.circulationStatusloading = false;
      });
  }

  /**
   * AIファイル連携ボタン
   */
  public uploadAiFile() {
    // AIファイル連携を実施
    this.summaryCirculationService
      .uploadAiFile(this.circulationFileId)
      .subscribe(
        (response) => {
          // AIファイルの連携に成功した場合はAIステータス更新を実施する
          if (response.body[0].Message === 'SUCCESS') {
            this.checkAiFile();

            //正常メッセージ
            this.messageData.toastMessage(
              new ToastMessageData({
                severity: TOAST.SUCCESS,
                summary: this.commonService.msg(MESSAGE_CODE.T00001),
                detail: this.commonService.msg(MESSAGE_CODE.U00012),
              })
            );
          }
        },
        (response) => {
          // APIでエラー発生の場合

          // 異常メッセージ
          this.messageData.toastMessage(
            new ToastMessageData({
              severity: TOAST.ERROR,
              summary: this.commonService.msg(MESSAGE_CODE.E00003),
              detail: response.error.error,
              life: 60000,
            })
          );
        }
      );
  }

  /**
   * AIステータス更新ボタン
   */
  public checkAiFile() {
    // AIステータス更新を実施
    this.summaryCirculationService
      .checkAiStatus(this.circulationFileId)
      .subscribe((response) => {
        console.log(response);
        // AIステータス更新後にファイル情報を再読み込みする
        this.getFileInformation();
      });
  }

  /**
   * 回答ボタン
   */
  public circulationAnswer() {
    // 入力画面表示
    this.summaryCirculationAnswerComponent.inputSummaryCirculationAnswer(
      this.circulationId
    );
  }

  /**
   * 回答ボタン出力表示情報取得
   */
  private getCirculationIsStartable() {
    // 回覧（開始条件判定）取得
    this.summaryCirculationService
      .getcirculationsIsStartable(this.circulationId)
      .subscribe((response) => {
        // 回覧（開始条件判定）結果を格納
        this.circulationIsStartableFlg = response.body[0].result;
      });
  }

  /**
   * 回覧のファイルID取得
   */
  private getFileId() {
    // 回覧（ファイルID）取得
    this.summaryCirculationService
      .getFileId(this.circulationId)
      .subscribe((response) => {
        // 回覧（ファイルID）結果を格納
        this.circulationFileId = response.body[0].result;
      });
  }

  /**
   * 回覧終了ボタン
   */
  public circulationEnd() {
    // 入力画面表示
    this.summaryCirculationEndComponent.inputSummaryCirculationEnd(
      this.circulationId
    );
  }

  /**
   * 回答者追加ボタン
   */
  public addAnswerMember() {
    // 入力画面表示
    this.addAnswerMemberComponent.inputAddAnswerMember(this.circulationId);
  }

  /**
   * 回覧更新ボタン
   */
  public updateSummaryCirculation() {
    // 更新画面表示
    this.summaryContentDisplayInputComponent.updateInputSummaryCirculation(
      this.contentId,
      this.circulationId
    );
  }

  /**
   * 戻るボタン
   */
  public return() {
    // コンテンツ詳細から遷移されたか否か
    if (NUMBER.ONE == this.route.snapshot.params.transitionSummaryDisplay) {
      // コンテンツ詳細から遷移した場合

      // コンテンツ詳細画面へ遷移
      this.router.navigate(['pages/summary/summary-display'], {
        queryParams: {
          item_id: this.itemId,
          item_create_user_id: this.itemCreateUserId,
          content_id: this.contentId,
        },
      });
    } else {
      // コンテンツ詳細以外から遷移した場合

      // 前画面へ戻る(ブラウザの戻るボタンと同一)
      this.location.back();
    }
  }

  /**
   * 回答ボタン出力表示判定
   * @returns true:ボタン表示、false:ボタン非表示
   */
  public answerButtonDisplayFlag(): boolean {
    // ログインユーザが回答可能かチェック
    const answerableUser = this.circulationGroupMemberList.find((user) => {
      return (
        user.user_id === this.loginUser.user_id &&
        (user.role === '1' || user.role === '2')
      );
    });
    if (
      this.circulationIsStartableFlg &&
      (this.loginUser.department_code === '99999' || answerableUser)
    ) {
      return true;
    }
    return false;
  }

  /**
   * 回覧終了ボタン出力表示判定
   * @returns true:ボタン表示、false:ボタン非表示
   */
  public endSummaryCirculationDisplayFlag(): boolean {
    // ログインユーザが回覧終了可能かチェック
    const endableUser = this.circulationGroupMemberList.find((user) => {
      return user.user_id === this.loginUser.user_id && user.role === '1';
    });
    if (
      this.loginUser.department_code === '99999' ||
      this.loginUser.user_id === this.circulationCreateUserId ||
      endableUser
    ) {
      return true;
    }
    return false;
  }

  /**
   * 回覧更新ボタン出力表示判定
   * @returns true:ボタン表示、false:ボタン非表示
   */
  public updateSummaryCirculationDisplayFlag(): boolean {
    //権限チェック
    if (
      this.loginUser.admit === '1' ||
      this.loginUser.department_code === '99999' ||
      this.loginUser.user_id === this.circulationCreateUserId
    ) {
      return true;
    }
    return false;
  }

  /**
   * 回答者追加ボタン出力表示判定
   * @returns true:ボタン表示、false:ボタン非表示
   */
  public addAnswerMemberDisplayFlag(): boolean {
    // ログインユーザが回答者追加可能かチェック
    const addableUser = this.circulationGroupMemberList.find((user) => {
      return (
        user.user_id === this.loginUser.user_id &&
        (user.role === '1' || user.role === '2')
      );
    });
    if (this.loginUser.department_code === '99999' || addableUser) {
      return true;
    }
    return false;
  }

  /**
   * AIファイル連携ボタン出力表示判定
   * @returns true:ボタン表示、false:ボタン非表示
   */
  public uploadAiFileDisplayFlag(): boolean {
    // ログインユーザがAIファイル連携可能かチェック
    if (
      this.loginUser.department_code === '99999' ||
      this.loginUser.user_id === this.contentCreateUserId
    ) {
      return true;
    }
    return false;
  }

  /**
   * AIステータスチェックボタン出力表示判定
   * @returns true:ボタン表示、false:ボタン非表示
   */
  public checkAiFileDisplayFlag(): boolean {
    // ログインユーザがAIステータスチェック可能かチェック
    if (
      this.loginUser.department_code === '99999' ||
      this.loginUser.user_id === this.contentCreateUserId
    ) {
      return true;
    }
    return false;
  }

  /**
   * AI校閲表示判定
   * @returns true:ボタン表示、false:ボタン非表示
   */
  public fileInformationDisplayFlag(): boolean {
    // ログインユーザがAI校閲を閲覧可能かチェック
    const watchableUser = this.circulationGroupMemberList.find((user) => {
      return (
        user.user_id === this.loginUser.user_id &&
        (user.role === '1' || user.role === '2' || user.role === '3')
      );
    });
    if (
      this.loginUser.department_code === '99999' ||
      watchableUser ||
      this.loginUser.user_id === this.contentCreateUserId
    ) {
      return true;
    }
    return false;
  }

  /**
   * 回覧グループメンバー取得
   */
  private getCirculationGroupMember() {
    // 回覧グループメンバー取得
    this.summaryCirculationService
      .getGroupMember(this.itemId, this.circulationId)
      .subscribe((response) => {
        // 回覧グループメンバー取得結果を格納
        this.circulationGroupMemberList = response.body;
      });
  }
}
