<!-- コンテンツ登録画面 -->
<p-dialog header="コンテンツ登録" [(visible)]="inputModal" [modal]="true">
  <!-- 入力エラーメッセージ-->
  <app-generate-input-error-message></app-generate-input-error-message>
  <!-- 入力フォーム-->
  <app-generate-input-form
    (generateInputInformation)="insertSummaryContent($event)"
    (generateInputErrorInformation)="
      outputGenerateInputErrorInformation($event)
    "
  ></app-generate-input-form>
</p-dialog>
