<!-- リンク画面 -->
<div class="main-container">
  <!-- タイトル -->
  <div class="linkTitle">資料・リンク集</div>
  <!-- リンク情報部 -->
  <div class="linkData">
    <!-- 外部リンク一覧 -->
    <p-table [value]="[{}]">
      <ng-template pTemplate="header">
        <tr>
          <th>外部リンク</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body">
        <tr>
          <td>
            <ul>
              <li type="disc">
                <a
                  href="https://www.kankyo.metro.tokyo.lg.jp/resource/recycle/tokyo_torikumi/index.html"
                  target="_blank"
                >
                  東京都環境局（食品廃棄物・食品ロス対策）
                </a>
              </li>
              <li type="disc">
                <a
                  href="https://www.maff.go.jp/j/shokusan/recycle/syoku_loss/"
                  target="_blank"
                >
                  農林水産省（食品ロス・食品リサイクル）
                </a>
              </li>
              <li type="disc">
                <a
                  href="https://www.env.go.jp/recycle/foodloss/index.html"
                  target="_blank"
                >
                  環境省（食品ロスポータルサイト）
                </a>
              </li>
              <li type="disc">
                <a
                  href="https://www.caa.go.jp/policies/policy/consumer_policy/information/food_loss/"
                  target="_blank"
                >
                  消費者庁
                </a>
              </li>
              <li type="disc">
                <a
                  href="https://www.maff.go.jp/j/shokusan/recycle/syoku_loss/attach/pdf/foodbank-9.pdf"
                  target="_blank"
                >
                  フードバンク活動における食品の取扱い等に関する手引き（農林水産省）
                </a>
              </li>
            </ul>
          </td>
        </tr>
      </ng-template>
    </p-table>

    <!-- 資料一覧 -->
    <p-table [value]="[{}]">
      <ng-template pTemplate="header">
        <tr>
          <th>資料</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body">
        <tr>
          <td>
            <ul>
              <li type="disc">
                <a
                  href="https://hsj-prod-img.s3.ap-northeast-1.amazonaws.com/Documents/%E6%93%8D%E4%BD%9C%E3%83%9E%E3%83%8B%E3%83%A5%E3%82%A2%E3%83%AB(%E5%AF%84%E8%B4%88%E5%85%83%E5%90%91%E3%81%91).pdf
                "
                  target="_blank"
                >
                  操作マニュアル（寄贈元向け）
                </a>
              </li>
              <li type="disc">
                <a
                  href="https://hsj-prod-img.s3.ap-northeast-1.amazonaws.com/Documents/%E6%93%8D%E4%BD%9C%E3%83%9E%E3%83%8B%E3%83%A5%E3%82%A2%E3%83%AB(%E5%AF%84%E8%B4%88%E5%85%88%E5%90%91%E3%81%91).pdf
                "
                  target="_blank"
                >
                  操作マニュアル（寄贈先向け）
                </a>
              </li>
              <li type="disc">
                <a
                  href="https://hsj-prod-img.s3.ap-northeast-1.amazonaws.com/Documents/%E5%82%99%E8%93%84%E9%A3%9F%E5%93%81%E6%B4%BB%E7%94%A8%E3%83%AC%E3%82%B7%E3%83%94%EF%BC%88%E3%82%AB%E3%83%B3%E3%83%91%E3%83%B3%E3%83%BB%E6%B6%B2%E4%BD%93%E3%83%9F%E3%83%AB%E3%82%AF%EF%BC%89.pdf
                "
                  target="_blank"
                >
                  備蓄食品活用レシピ（カンパン・液体ミルク）
                </a>
              </li>
            </ul>
          </td>
        </tr>
      </ng-template>
    </p-table>

    <!-- その他一覧 -->
    <p-table [value]="[{}]">
      <ng-template pTemplate="header">
        <tr>
          <th>その他</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body">
        <tr>
          <td>
            <ul>
              <li type="disc">
                <a
                  href="https://hsj-prod-img.s3.ap-northeast-1.amazonaws.com/Documents/%E3%82%B7%E3%82%B9%E3%83%86%E3%83%A0%E6%94%B9%E4%BF%AE%E3%81%8C%E5%8F%8D%E6%98%A0%E3%81%95%E3%82%8C%E3%81%AA%E3%81%84%E5%A0%B4%E5%90%88%E3%81%AE%E6%89%8B%E9%A0%86%E3%81%AB%E3%81%A4%E3%81%84%E3%81%A6.pdf
                          "
                  target="_blank"
                >
                  システム改修が反映されない場合の手順について
                </a>
              </li>
            </ul>
          </td>
        </tr>
      </ng-template>
    </p-table>

    <!-- CSVダウンロード -->
    <p-table [value]="[{}]" *ngIf="exportButtonDisplayFlag()">
      <ng-template pTemplate="header">
        <tr>
          <th>CSVダウンロード</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body">
        <tr>
          <td>
            <ul>
              <li type="disc">
                <button
                  pButton
                  type="button"
                  label="一括"
                  class="p-button-link"
                  (click)="exportAllCSV()"
                ></button>
              </li>
              <li type="disc">
                <button
                  pButton
                  type="button"
                  label="食品"
                  class="p-button-link"
                  (click)="exportItemCSV()"
                ></button>
              </li>
              <li type="disc">
                <button
                  pButton
                  type="button"
                  label="届先"
                  class="p-button-link"
                  (click)="exportDeliveryCSV()"
                ></button>
              </li>
              <li type="disc">
                <button
                  pButton
                  type="button"
                  label="注文履歴"
                  class="p-button-link"
                  (click)="exportOrderHistoryCSV()"
                ></button>
              </li>
              <li type="disc">
                <button
                  pButton
                  type="button"
                  label="組織"
                  class="p-button-link"
                  (click)="exportDepartmentCSV()"
                ></button>
              </li>
              <li type="disc">
                <button
                  pButton
                  type="button"
                  label="ユーザ"
                  class="p-button-link"
                  (click)="exportUserCSV()"
                ></button>
              </li>
            </ul>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
