/**
 * ルート権限設定
 */
/**
 * 注意
 * 画面を追加する場合は"Component"名で定数追加
 * 内部は配列でユーザ権限設定(user-authority-constant.ts)の対象ユーザを設定
 * 空配列の場合、権限チェックは実施しない
 */
/**
 * TOP
 */

import {
  USER_1,
  USER_2,
  USER_3,
  USER_4,
  USER_5,
  USER_6,
  USER_7,
  USER_8,
  USER_9,
  USER_10,
  USER_99,
} from './user-authority-constant';

// TOP画面
export const TopComponent = [];

/** --------------------------------------------------------- */

/**
 * マスタ管理
 */
// 営業日マスタ画面
export const BizdayComponent = [USER_99];

// 得意先マスタ画面
export const CustomerComponent = [USER_99];

// 届先マスタ画面
export const DeliveryComponent = [USER_99];

// 届先グループマスタ画面
export const DeliveryGroupComponent = [USER_99];

// 組織マスタ画面
export const DepartmentComponent = [];

// お知らせマスタ画面
export const InformationComponent = [];

// 販促資材マスタ画面
export const ItemComponent = [USER_99];

// 販促資材斡旋マスタ画面
export const ItemMediationComponent = [USER_99];

// ユーザーマスタ画面
export const UserComponent = [];

/** --------------------------------------------------------- */

/**
 * 在庫管理
 */
// 製作指示画面
export const ProductionInstructionComponent = [USER_99];

// 在庫メンテナンスファイル登録画面
export const RegisterStockMaintenanceComponent = [USER_99];

// 在庫メンテナンス管理画面
export const StockMaintenanceComponent = [USER_99];

/** --------------------------------------------------------- */

/**
 * 発注管理
 */
// 発注選択画面
export const CartComponent = [USER_99];

// 発注届先画面
export const DeliverySearchComponent = [USER_99];

// 発注販促資材画面
export const ItemSearchComponent = [USER_99];

// 発注実績画面
export const OrderHistoryComponent = [USER_99];

// 注文登録画面
export const OrderRegisterComponent = [USER_99];

// 発注画面
export const QuantityComponent = [USER_99];

/** --------------------------------------------------------- */

/**
 * 計画
 */
// 計画一覧画面
export const ProjectListComponent = [USER_99];

// 計画数確認画面
export const ProjectNumberConfirmationComponent = [USER_99];

// 計画数入力画面
export const ProjectNumberInputComponent = [USER_99];

/** --------------------------------------------------------- */

/**
 * サマリー
 */
//サマリー一覧
export const SummaryComponent = [];

// サマリー詳細
export const SummaryDisplayComponent = [];

// サマリー回覧
export const SummaryCirculationComponent = [];

/** --------------------------------------------------------- */

/**
 * 回覧
 */
// 回覧グループマスタ画面
export const CirculationGroupComponent = [];

// 回覧ルート画面
export const CirculationRootComponent = [];

/** --------------------------------------------------------- */

/**
 * 事務局
 */
// 情報出力画面
export const OutputComponent = [USER_99];

// 発送ステータスファイル登録画面
export const RegisterShippingResultComponent = [USER_99];

/** --------------------------------------------------------- */

/**
 * 帳票
 */
// 費用明細帳票出力画面
export const CostDetailsReportComponent = [USER_99];

// 棚卸帳票出力画面
export const InventoryReportComponent = [USER_99];

/** --------------------------------------------------------- */

/**
 * その他
 */
// 資料・リンク集
export const LinkComponent = [USER_99];

/** --------------------------------------------------------- */
