import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { DbOperationService } from 'src/app/shared/service/db-operation.service';
import { GenerateDisplay } from 'src/app/shared/generate/generate-display/generate-display-information/generate-display-information';
import { GenerateDisplayInformationComponent } from 'src/app/shared/generate/generate-display/generate-display-information/generate-display-information.component';
import { StockComponent } from '../stock/stock.component';
import { API_URL_ITEM } from 'manager/http-constants_key';
import { ITEM_TEMPLATE } from 'manager/template-constant';
import { CommonService } from 'src/app/shared/service/common.service';
import { ITEM_CONSTANT, STOCK_FLAG } from '../constant';
import { TOAST } from 'src/app/shared/constant/primeng-constants';
import { MESSAGE_CODE } from 'src/app/shared/constant/message-constant';
import {
  CONSTANT,
  IMAGE_EXTENSION,
  NO_IMAGE_FILE_NAME,
} from 'src/app/shared/constant/constant';
import {
  MessageData,
  ToastMessageData,
} from 'src/app/shared/html-parts/message-common/message-data';
import {
  LoadData,
  LoadingState,
} from 'src/app/shared/html-parts/loading/loading-state';

@Component({
  selector: 'app-item-detail',
  templateUrl: './item-detail.component.html',
  styleUrls: [
    '../../../../shared/generate/generate-display/generate-display.component.scss',
    './item-detail.component.scss',
  ],
})

/**
 * 販促資材マスタ詳細画面
 */
export class ItemDetailComponent {
  // 更新対象ID
  @Output() reloadID = new EventEmitter<string>();

  /** 詳細情報 */
  @ViewChild(GenerateDisplayInformationComponent)
  generateDisplayInformationComponent: GenerateDisplayInformationComponent;

  /** 入庫登録、在庫調整 */
  @ViewChild(StockComponent)
  stockComponent: StockComponent;

  /* 画面用プロパティ */
  // 詳細画面表示フラグ
  detailsNav: boolean;

  // 詳細ID
  pkeyId: string;

  constructor(
    private dbOperationService: DbOperationService,
    private messageData: MessageData,
    private commonService: CommonService,
    private loadingState: LoadingState
  ) {}

  /**
   * 詳細情報表示
   * @param pkeyId 選択対象ID
   */
  public initial(pkeyId: string) {
    // 詳細画面表示
    this.detailsNav = true;

    // 詳細情報
    let generateDisplay: GenerateDisplay = new GenerateDisplay();
    generateDisplay.endPoint = API_URL_ITEM;
    generateDisplay.templateId = ITEM_TEMPLATE.OUTPUT_TEMPLATE_ID;
    this.generateDisplayInformationComponent.initial(generateDisplay, pkeyId);

    // 選択対象IDを格納
    this.pkeyId = pkeyId;
  }

  /**
   * 入庫登録
   */
  public inputStockRegist() {
    // 入庫登録画面を表示
    this.stockComponent.initial(STOCK_FLAG.STOCK_REGIST, this.pkeyId);
  }

  /**
   * 在庫調整
   */
  public inputAdjustStock() {
    // 在庫調整画面を表示
    this.stockComponent.initial(STOCK_FLAG.ADJUST_STOCK, this.pkeyId);
  }

  /**
   * ファイルアップロード
   * @param event 画像情報
   * @param fileUpload ボタン動作状態
   */
  public uploadFile(event, fileUpload) {
    // 画像拡張子を取得
    const imageExtension = event.files[0].name.substring(
      event.files[0].name.lastIndexOf(CONSTANT.PERIOD)
    );

    // 画像拡張子が".jpg" or ".jpeg" or ".png"か否か判定
    if (
      !(
        IMAGE_EXTENSION.JPG == imageExtension ||
        IMAGE_EXTENSION.JPEG == imageExtension ||
        IMAGE_EXTENSION.PNG == imageExtension
      )
    ) {
      // 画像拡張子が".jpg" or ".jpeg" or ".png"以外の場合

      // 画像拡張子エラーメッセージ
      this.messageData.toastMessage(
        new ToastMessageData({
          severity: TOAST.ERROR,
          summary: this.commonService.msg(MESSAGE_CODE.E00009),
          detail: this.commonService.msg(
            MESSAGE_CODE.E00010,
            'jpg又はjpeg又はpng'
          ),
        })
      );

      // ボタン動作状態をクリア
      fileUpload.clear();

      // 処理を終了
      return;
    }

    // 画像ファイルサイズの判定
    if (ITEM_CONSTANT.FILE_SIZE < event.files[0].size) {
      // 画像サイズが500KB以上の場合

      // 画像サイズエラーメッセージ
      this.messageData.toastMessage(
        new ToastMessageData({
          severity: TOAST.ERROR,
          summary: this.commonService.msg(MESSAGE_CODE.E00009),
          detail: this.commonService.msg(MESSAGE_CODE.E00011, '500KB'),
        })
      );

      // ボタン動作状態をクリア
      fileUpload.clear();

      // 処理を終了
      return;
    }

    // 画像登録
    this.dbOperationService.insertImage(this.pkeyId, event.files[0]);

    // 画像登録完了メッセージ
    this.messageData.toastMessage(
      new ToastMessageData({
        severity: TOAST.SUCCESS,
        summary: this.commonService.msg(MESSAGE_CODE.I00003),
        detail: this.commonService.msg(MESSAGE_CODE.T00003),
      })
    );

    // ボタン動作状態をクリア
    fileUpload.clear();
  }

  /**
   * 画像ダウンロード
   */
  protected imageDownload() {
    // 画面カスタムロードフラグをON(ロード中状態)
    this.loadingState.customLoadStart(
      new LoadData({
        loadingText: this.commonService.msg(MESSAGE_CODE.S00013),
      })
    );

    // 画像URl取得処理
    this.dbOperationService
      .getDisplayData(
        API_URL_ITEM,
        ITEM_TEMPLATE.OUTPUT_IMAGE_TEMPLATE_ID,
        this.pkeyId
      )
      .subscribe((response) => {
        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadSleepEnd(0.3);

        // 詳細IDの画像URlが存在するか否か
        if (this.commonService.checkNoneResponse(response)) {
          // 詳細IDの画像URlが存在しない場合

          // 画像ダウンロード失敗メッセージ
          this.messageData.toastMessage(
            new ToastMessageData({
              severity: TOAST.WARN,
              summary: this.commonService.msg(MESSAGE_CODE.E00022),
              detail: this.commonService.msg(MESSAGE_CODE.E00023),
            })
          );

          return;
        }

        // 画像ファイル名が画像無しファイル名か否か
        if (response.body[0].data.match(NO_IMAGE_FILE_NAME)) {
          // 画像無しファイル名の場合

          // 画像ダウンロード失敗メッセージ
          this.messageData.toastMessage(
            new ToastMessageData({
              severity: TOAST.WARN,
              summary: this.commonService.msg(MESSAGE_CODE.E00022),
              detail: this.commonService.msg(MESSAGE_CODE.E00023),
            })
          );

          return;
        }

        // 画像ダウンロードを実行
        window.location.href = response.body[0].data;
      });
  }

  /**
   * 画像アップロードボタン出力表示判定
   * @returns true:ボタン表示、false:ボタン非表示
   */
  protected imageUploadButtonDisplayFlag(): boolean {
    return true;
  }

  /**
   * 画像ダウンロードボタン出力表示判定
   * @param rowData 行データ
   * @returns true:ボタン表示、false:ボタン非表示
   */
  protected imageDownloadButtonDisplayFlag(): boolean {
    return true;
  }

  /**
   * 親画面へ返却する
   */
  public return(reloadID: string) {
    // 更新対象IDにプライマリキーをセット
    this.reloadID.emit(reloadID);
  }
}
