<!-- メイン領域-->
<div class="main-container">
  <!-- 一覧画面表示領域-->
  <p-table
    [value]="outputResultsList"
    styleClass="p-datatable-striped output-datatable"
    selectionMode="multiple"
  >
    <ng-template pTemplate="caption">
      <div class="p-d-flex p-jc-between">
        <!-- ファイル情報作成-->
        <button
          type="button"
          pButton
          label="ファイル作成"
          (click)="onClickCreateFile()"
          class="p-mr-2 createFileButton"
        ></button>

        <!-- 画面更新ボタン -->
        <button
          type="button"
          pButton
          icon="pi pi-undo"
          (click)="outputResult()"
          class="p-mr-2"
          pTooltip="画面情報更新"
          tooltipPosition="bottom"
        ></button>
      </div>
    </ng-template>

    <!-- 一覧画面ヘッダー表示-->
    <ng-template pTemplate="header">
      <tr class="p-col-2">
        <!-- ラジオボタンヘッダーの表示 -->
        <th class="inputRadioButton"></th>
        <!-- APIヘッダーの表示 -->
        <ng-container *ngFor="let column of columnOrder">
          <th>
            {{ column.header }}
          </th>
        </ng-container>
      </tr>
    </ng-template>

    <!-- 一覧画面ボディ表示-->
    <ng-template pTemplate="body" let-searchResults>
      <tr>
        <!-- ラジオボタン -->
        <td class="inputRadioButton">
          <p-radioButton
            [name]="searchResults.output_id"
            [value]="searchResults.output_id"
            [(ngModel)]="idSelected"
          ></p-radioButton>
        </td>
        <!-- 一覧項目値表示 -->
        <ng-container *ngFor="let column of columnOrder">
          <td [ngSwitch]="column?.input_type">
            <span *ngSwitchCase="'FILE'">
              <!-- ファイルDL-->
              <button
                pButton
                label="ファイルDL"
                [value]="searchResults[column.field]"
                onClick="location.href=this.value"
                class="p-mr-2"
                [disabled]="!searchResults[column.field]"
              ></button>
            </span>
            <span *ngSwitchDefault>
              {{ searchResults[column.field] }}
            </span>
          </td>
        </ng-container>
      </tr>
    </ng-template>
  </p-table>
</div>
