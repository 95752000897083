import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { GenerateService } from 'src/app/shared/generate/generate.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SEARCH_INFORMATIONS_API_CONSTANT } from 'src/app/shared/constant/api-constant';
import { CommonService } from '../../service/common.service';
import { CONSTANT, LOADING_KEY } from 'src/app/shared/constant/constant';
import { LoadingState } from '../../html-parts/loading/loading-state';

@Component({
  selector: 'app-generate-search',
  templateUrl: './generate-search.component.html',
  styleUrls: ['./generate-search.component.scss'],
})

/**
 * 検索項目情報
 */
export class GenerateSearchComponent implements OnInit {
  // インプット.検索項目生成エンドポイント
  @Input() searchEndPoint: string;

  // インプット.検索項目生成テンプレートID
  @Input() searchTemplateId: number;

  // 検索ボタン名
  // 未設定時のボタン名は検索
  @Input() searchButtonName: string = '検索';

  // 日付 and 数値の表示形式変換
  // 未設定時の開始and終了の縦に表示
  @Input() changeFromToDisplay: boolean;

  // アウトプット.検索フォーム
  @Output() generateSearchInformation = new EventEmitter<FormGroup>();

  // 画面.検索フォーム
  generateSearchForm: FormGroup = this.formBuilder.group(Object());

  // 画面.検索項目生成
  generateSearchList: any[] = new Array();

  // 初期化/宣言
  constructor(
    private generateService: GenerateService,
    private commonService: CommonService,
    private loadingState: LoadingState,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {}

  // 画面初期表示 or @Input変更時
  ngOnChanges(): void {
    // 検索項目生成を初期化
    this.generateSearchList = new Array();

    // 検索フォームを初期化
    this.generateSearchForm = this.formBuilder.group(Object());

    // 検索項目生成を実施
    this.getGenerateSearch();
  }

  /**
   * 検索項目生成
   */
  private getGenerateSearch(): void {
    // 画面ロードフラグをON(ロード中状態)
    this.loadingState.loadStart(LOADING_KEY.GENERATE_SEARCH);

    // 検索項目情報取得処理
    this.generateService
      .getGenerateSearch(this.searchEndPoint, this.searchTemplateId)
      .subscribe((response) => {
        // 検索項目情報が取得されたか否か
        if (this.commonService.checkNoneResponse(response)) {
          // 検索項目情報が取得されなかった場合

          // 画面ロードフラグをOFF(ロード終了)
          this.loadingState.loadEnd(LOADING_KEY.GENERATE_SEARCH);

          return;
        }

        // 検索項目情報を画面.検索項目生成に格納
        this.generateSearchList = response.body;

        // 検索フォーム用コントロール作成
        for (const generateSearchItems of response.body) {
          // 検索項目タイプの判定
          if (
            SEARCH_INFORMATIONS_API_CONSTANT.DATE_TYPE ==
              generateSearchItems.search_type ||
            SEARCH_INFORMATIONS_API_CONSTANT.NUMBER_TYPE ==
              generateSearchItems.search_type
          ) {
            // 検索項目タイプが"date" or "number"の場合
            this.generateSearchForm.addControl(
              generateSearchItems.column_id + '_FROM',
              this.formBuilder.control(CONSTANT.EMPTY_STRING, [])
            );

            this.generateSearchForm.addControl(
              generateSearchItems.column_id + '_TO',
              this.formBuilder.control(CONSTANT.EMPTY_STRING, [])
            );
          } else {
            // 検索項目タイプが"date" or "number"以外の場合
            this.generateSearchForm.addControl(
              generateSearchItems.column_id,
              this.formBuilder.control(CONSTANT.EMPTY_STRING, [])
            );
          }
        }

        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadEnd(LOADING_KEY.GENERATE_SEARCH);
      });
  }

  /* 検索ボタン押下 */
  public onSubmit() {
    // 画面.検索フォームを親コンポーネントへ値を渡す
    this.generateSearchInformation.emit(this.generateSearchForm.value);
  }

  /**
   * クリアボタン押下
   */
  public reset() {
    // デフォルト入力値オブジェクトを生成
    let defaultInputValue: object = new Object();

    // 検索項目情報をループ
    for (const generateSearchItems of this.generateSearchList) {
      // 検索項目タイプの判定
      if (
        SEARCH_INFORMATIONS_API_CONSTANT.DATE_TYPE ==
          generateSearchItems.search_type ||
        SEARCH_INFORMATIONS_API_CONSTANT.NUMBER_TYPE ==
          generateSearchItems.search_type
      ) {
        // 検索項目タイプが"date" or "number"の場合
        defaultInputValue[generateSearchItems.column_id + '_FROM'] =
          CONSTANT.EMPTY_STRING;

        defaultInputValue[generateSearchItems.column_id + '_TO'] =
          CONSTANT.EMPTY_STRING;
      } else {
        // 検索項目タイプが"date" or "number"以外の場合
        defaultInputValue[generateSearchItems.column_id] =
          CONSTANT.EMPTY_STRING;
      }
    }

    // フォーム入力値をクリア
    this.generateSearchForm.reset(defaultInputValue);
  }
}
