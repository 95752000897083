import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  LoadData,
  LoadingState,
} from 'src/app/shared/html-parts/loading/loading-state';
import { DbOperationService } from 'src/app/shared/service/db-operation.service';
import { QUANTITY_TEMPLATE } from 'manager/template-constant';
import { CommonService } from 'src/app/shared/service/common.service';
import {
  API_URL_INPUT_WORK_ORDER,
  API_URL_ORDER,
  API_URL_WORK_ORDER,
} from 'manager/http-constants_key';
import {
  CONSTANT,
  NUMBER,
  SCREEN_CONSTANT,
} from 'src/app/shared/constant/constant';
import { MESSAGE_CODE } from 'src/app/shared/constant/message-constant';
import { ConfirmationService } from 'primeng/api';
import {
  API_RESPONSE,
  INPUT_INFORMATIONS_API_CONSTANT,
} from 'src/app/shared/constant/api-constant';
import {
  MessageData,
  ToastMessageData,
} from 'src/app/shared/html-parts/message-common/message-data';
import { TOAST } from 'src/app/shared/constant/primeng-constants';
import { ErrorMessage, InputErrorMessage } from './quantity';
import { ADD_ORDER_API_CONSTANT } from './constant';
import { forkJoin, Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { Router } from '@angular/router';
import { SESSION_KEY } from 'src/app/shared/constant/session-constants';

@Component({
  selector: 'app-order',
  templateUrl: './quantity.component.html',
  styleUrls: ['../order.component.scss', './quantity.component.scss'],
})

/**
 * 発注画面
 */
export class QuantityComponent implements OnInit {
  // 画面.入力フォーム
  inputForm: FormGroup = this.formBuilder.group(Object());
  // 画面.一括入力フォーム
  bulkInputForm: FormGroup = this.formBuilder.group(Object());

  /* 画面用プロパティ */
  // 画面ヘッダー情報格納先
  columnOrder: any[] = new Array();

  // 画面入力項目ヘッダー情報格納先
  inputColumnOrder: any[] = new Array();

  // 画面検索結果一覧格納先
  searchResultsList: any[] = new Array();

  // 画面検索結果一覧内部情報
  searchResultsInformation: any[] = new Array();

  // 画面.入力項目生成
  inputItemList: any[] = new Array();

  // 選択行格納先
  selected: any[] = new Array();

  // 登録用リクエストオブジェクト
  quantityRequest: any[] = new Array();

  // 入力エラーメッセージ出力オブジェクトリスト(画面表示情報)
  inputErrorMessageList: InputErrorMessage[] = new Array();

  // 確認画面用チェックボックスキー
  checkBox = ADD_ORDER_API_CONSTANT.CHECK_BOX;

  // 確認画面表示フラグ
  confirmationFlag: boolean;

  // 登録ボタン押下フラグ(データ登録中にボタン押下させないフラグ)
  insertFlag: boolean;

  // 数量一覧画面状態保存セッションキー
  quantityDataTableSessionKey = SESSION_KEY.quantityDataTable;

  constructor(
    private loadingState: LoadingState,
    private dbOperationService: DbOperationService,
    private commonService: CommonService,
    private formBuilder: FormBuilder,
    private confirmationService: ConfirmationService,
    private messageData: MessageData,
    private router: Router
  ) {}

  ngOnInit() {
    // 数量画面テーブル状態をクリア
    window.sessionStorage.removeItem(SESSION_KEY.quantityDataTable);

    // 画面ロードフラグをON(ロード中状態)
    this.loadingState.loadStart();

    /* ヘッダー情報取得処理(画面用) */
    {
      // ヘッダー情報取得
      this.dbOperationService
        .getHeaderList(QUANTITY_TEMPLATE.SEARCH_RESULTS_TEMPLATE_ID)
        .subscribe((response) => {
          this.columnOrder = response.body;
        });
    }

    // 入力項目ヘッダー情報取得＆一括入力項目を生成
    this.inputFormColumnOrder();

    // 検索処理を実施
    this.searchResult();
  }

  /**
   * 入力項目ヘッダー情報取得＆一括入力項目を生成
   */
  private inputFormColumnOrder() {
    // 画面ロードフラグをON(ロード中状態)
    this.loadingState.loadStart('inputFormColumnOrder');

    /* 入力項目ヘッダー情報取得処理 */
    this.dbOperationService
      .getData(API_URL_INPUT_WORK_ORDER, QUANTITY_TEMPLATE.INPUT_TEMPLATE_ID)
      .subscribe((response) => {
        /* 一括入力項目を生成 */
        // 入力項目項目分ループ
        for (const input of response.body) {
          // フォームコントロールを追加
          this.bulkInputForm.addControl(
            input.column_id,
            this.formBuilder.control(CONSTANT.EMPTY_STRING, Object())
          );
        }

        /* 入力項目ヘッダー情報を格納 */
        this.inputColumnOrder = response.body;

        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadSleepEnd(0.3, 'inputFormColumnOrder');
      });
  }

  /**
   * 検索処理
   */
  public searchResult() {
    // 非同期同時実行リスト
    const task: Observable<any>[] = [
      /* 注文一覧取得処理(画面用) */
      this.dbOperationService.getData(
        API_URL_WORK_ORDER,
        QUANTITY_TEMPLATE.SEARCH_RESULTS_INFORMATION_TEMPLATE_ID
      ),
      /* 注文入力項目取得処理 */
      this.dbOperationService.getData(
        API_URL_INPUT_WORK_ORDER,
        QUANTITY_TEMPLATE.INPUT_TEMPLATE_ID
      ),
      // 注文一覧内部情報取得
      this.dbOperationService.getHeaderList(
        QUANTITY_TEMPLATE.SEARCH_RESULTS_INFORMATION_TEMPLATE_ID
      ),
    ];

    // 非同期同時実行
    forkJoin(task).subscribe((responseList) => {
      // 注文一覧情報、注文入力項目情報が存在するか否か
      if (this.commonService.checkNoneResponseList(responseList)) {
        // 注文一覧情報or注文入力項目情報が存在しない場合

        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadEnd();

        // 発注管理_販促資材選択へ遷移
        this.router.navigate(['pages/order/item-search']);

        // 処理を終了
        return;
      }

      // 注文一覧内部情報を格納
      this.searchResultsInformation = responseList[2].body;

      /* 入力フォーム用コントロール作成 */
      // 注文一覧情報分ループ
      for (const searchResults of responseList[0].body) {
        // 子フォームグループを生成
        let childFormGroup = this.formBuilder.group(Object());

        // 注文入力項目分ループ
        for (const input of responseList[1].body) {
          // 子フォームグループに入力フォームコントロールを追加
          childFormGroup.addControl(
            input.column_id,
            this.formBuilder.control(CONSTANT.EMPTY_STRING, Object())
          );
        }

        // 子フォームグループを入力フォームに格納
        this.inputForm.addControl(
          searchResults[this.getPkeyColumn()],
          childFormGroup
        );
      }

      // 注文一覧情報のJSONをオブジェクトに格納する
      this.searchResultsList = responseList[0].body;

      // 注文入力項目のJSONをオブジェクトに格納する
      this.inputItemList = responseList[1].body;

      // 入力項目初期値セットを実施
      this.setFormControlDefaultValue();
    });
  }

  /**
   * 入力項目初期値セット
   */
  private setFormControlDefaultValue() {
    /* 注文入力初期値取得処理(画面用) */
    this.dbOperationService
      .getData(API_URL_WORK_ORDER, QUANTITY_TEMPLATE.INPUT_DEFAULT_TEMPLATE_ID)
      .subscribe((response) => {
        /* 入力フォーム初期値設定 */
        // 注文一覧分ループ
        for (const searchResults of this.searchResultsList) {
          // 注文入力項目分ループ
          for (const inputItem of this.inputItemList) {
            // 子フォームグループの入力フォームに入力値をセット
            this.inputForm.controls[searchResults[this.getPkeyColumn()]]
              .get(inputItem.column_id)
              .setValue(
                this.commonService.getArrayObjectValue(
                  response.body,
                  this.getPkeyColumn(),
                  inputItem.column_id,
                  searchResults[this.getPkeyColumn()]
                )
              );
          }
        }

        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadSleepEnd(0.3);
      });
  }

  /**
   * 一括入力ボタン
   */
  protected onClickBulkInput() {
    // 一括入力ダイアログを表示
    this.confirmationService.confirm({
      message: this.commonService.msg(MESSAGE_CODE.T00008),
      accept: () => {
        // 一括入力値存在チェックフラグ
        let existenceCheckBulkInput: boolean;

        // 画面入力項目ヘッダー情報分ループ
        for (const inputColumn of this.inputColumnOrder) {
          // 一括入力値を取得
          const bulkInputData = this.bulkInputForm.get(
            inputColumn.column_id
          ).value;

          // 一括入力値が存在するか否か
          if (!bulkInputData) {
            // 一括入力値が存在しない場合

            // 次の画面入力項目ヘッダー情報を処理
            continue;
          }

          // 一括入力値が1件以上存在する為、一括入力値存在チェックフラグをONにする
          existenceCheckBulkInput = true;

          // 画面検索結果一覧分ループ
          for (const searchResults of this.searchResultsList) {
            // 子フォームグループの入力フォームに一括入力値をセット
            this.inputForm.controls[searchResults[this.getPkeyColumn()]]
              .get(inputColumn.column_id)
              .setValue(bulkInputData);
          }
        }

        // 一括入力値存在チェックフラグがOFFのままの場合
        if (!existenceCheckBulkInput) {
          // 警告メッセージ
          this.messageData.toastMessage(
            new ToastMessageData({
              severity: TOAST.WARN,
              summary: this.commonService.msg(MESSAGE_CODE.E00018),
              detail: this.commonService.msg(MESSAGE_CODE.E00019),
            })
          );
        }
      },
    });
  }

  /**
   * リセットボタン
   */
  protected onClickReset() {
    // リセットダイアログを表示
    this.confirmationService.confirm({
      message: this.commonService.msg(MESSAGE_CODE.S00008),
      accept: () => {
        // 画面カスタムロードフラグをON(ロード中状態)
        this.loadingState.customLoadStart(
          new LoadData({
            loadingText: this.commonService.msg(MESSAGE_CODE.S00007),
          })
        );

        // 画面.入力フォームを初期化
        this.inputForm = this.formBuilder.group(Object());

        // 画面.一括入力フォームを初期化
        this.bulkInputForm = this.formBuilder.group(Object());

        // 選択行を初期化
        this.selected = new Array();

        // 入力エラーメッセージ出力オブジェクトリストを初期化
        this.inputErrorMessageList = new Array();

        // 入力項目ヘッダー情報を初期化
        this.inputColumnOrder = new Array();

        // 画面検索結果一覧格納先を初期化
        this.searchResultsList = new Array();

        // 画面.入力項目生成を初期化
        this.inputItemList = new Array();

        // 入力項目ヘッダー情報取得＆一括入力項目を生成
        this.inputFormColumnOrder();

        // 検索処理を実施
        this.searchResult();
      },
    });
  }

  /**
   * 数量確認
   */
  onClickConfirmation() {
    // 入力フォームオブジェクトを初期化
    this.quantityRequest = new Array();

    // 入力エラーメッセージ出力オブジェクトリストを初期化
    this.inputErrorMessageList = new Array();

    /* 登録用リクエストオブジェクト作成 */
    // 注文一覧情報分ループ
    for (const searchResults of this.searchResultsList) {
      // 入力フォームオブジェクトを生成
      let quantityForm: object = new Object();

      // チェック状態を判定
      // チェックされている場合は1,チェックされていない場合は0をセット
      quantityForm[ADD_ORDER_API_CONSTANT.CHECK_BOX] =
        this.commonService.getArrayObjectValue(
          this.selected,
          this.getPkeyColumn(),
          this.getPkeyColumn(),
          searchResults[this.getPkeyColumn()]
        )
          ? NUMBER.ONE
          : NUMBER.ZERO;

      // 入力フォームオブジェクトに対象の注文一覧を格納
      quantityForm = Object.assign(quantityForm, searchResults);

      // 入力フォームオブジェクトに対象の入力フォームを格納
      quantityForm = Object.assign(
        quantityForm,
        this.inputForm.controls[searchResults[this.getPkeyColumn()]].value
      );

      // 入力フォームオブジェクトをリストに格納
      this.quantityRequest.push(quantityForm);
    }

    // 画面カスタムロードフラグをON(ロード中状態)
    this.loadingState.customLoadStart(
      new LoadData({
        loadingText: this.commonService.msg(MESSAGE_CODE.S00009),
        changeText: '',
      })
    );

    /* 入力確認 */
    {
      // 入力項目値確認用API
      this.dbOperationService
        .insertData(API_URL_ORDER, {
          registFlag: 0,
          list: this.quantityRequest,
        })
        .subscribe((response) => {
          // 画面ロードフラグをOFF(ロード終了)
          this.loadingState.loadEnd();

          // エラーメッセージを出力
          this.createErrorMessage(response.body[0]);

          // バリデーションチェック状態を確認
          if (API_RESPONSE.FAIL == response.body[0].result) {
            // チェック状態が異常の場合

            // 処理を終了
            return;
          }

          // 確認画面を表示
          this.confirmationFlag = true;
        });
    }
  }

  /**
   * 数量登録
   */
  protected onClickRegist() {
    // 注文確認ダイアログを表示
    this.confirmationService.confirm({
      message: this.commonService.msg(MESSAGE_CODE.I00004),
      accept: () => {
        // 画面カスタムロードフラグをON(ロード中状態)
        this.loadingState.customLoadStart(
          new LoadData({
            loadingText: this.commonService.msg(MESSAGE_CODE.I00011),
            changeText: '',
          })
        );

        /* 注文登録 */
        {
          // 入力項目値確認用API
          this.dbOperationService
            .insertData(API_URL_ORDER, {
              registFlag: 1,
              list: this.quantityRequest,
            })
            .subscribe((response) => {
              // 画面ロードフラグをOFF(ロード終了)
              this.loadingState.loadEnd();

              // レスポンスを判定
              if (
                this.messageData.resultResponseToastMessage(
                  response,
                  null,
                  this.commonService.msg(MESSAGE_CODE.I00005)
                )
              ) {
                // レスポンスが正常終了の場合

                // レスポンスが正常終了の場合
                // sleep時間を設定(0.3秒)
                const sleep = of('').pipe(delay(300));
                // sleep時間後処理を実施
                sleep.subscribe(() => {
                  // 注文履歴画面へ遷移
                  this.router.navigate(['pages/order/orderHistory']);
                });
              }
            });
        }
      },
    });
  }

  /**
   * 削除処理
   * @param searchResults 行データ
   */
  protected delete(searchResults: Object) {
    // 削除ダイアログを表示
    this.confirmationService.confirm({
      message: this.commonService.msg(
        MESSAGE_CODE.D00003,
        '資材名称：' +
          searchResults['item_name'] +
          SCREEN_CONSTANT.LINE_CODE +
          '届先名称：' +
          searchResults['delivery_name']
      ),
      accept: () => {
        // 検索結果一覧から該当データを削除
        this.searchResultsList = this.commonService.deleteArrayObjectValue(
          this.searchResultsList,
          this.getPkeyColumn(),
          searchResults[this.getPkeyColumn()]
        );

        // 選択行から該当データを削除
        this.selected = this.commonService.deleteArrayObjectValue(
          this.selected,
          this.getPkeyColumn(),
          searchResults[this.getPkeyColumn()]
        );

        // 子フォームグループの入力フォームを削除
        this.inputForm.removeControl(searchResults[this.getPkeyColumn()]);
      },
    });
  }

  /**
   * 確認画面用入力値表示
   * @param column カラムID
   * @param data 入力値
   */
  protected getInputFormConfirmation(column: string, data: any): string {
    // 入力値が存在するか否か
    if (!data) {
      // 入力値が存在しない場合

      return CONSTANT.EMPTY_STRING;
    }

    // ArrayObjectから対象のobjectを取得
    const object: any = this.inputItemList.filter(
      (val) => val[INPUT_INFORMATIONS_API_CONSTANT.COLUMN_ID] == column
    );

    // 対象のobjectが存在するか否か
    if (!object.length) {
      // 対象のobjectが存在しない場合

      return CONSTANT.EMPTY_STRING;
    }

    // 入力項目タイプを判定
    if (
      INPUT_INFORMATIONS_API_CONSTANT.TEXT_TYPE == object[0].input_type ||
      INPUT_INFORMATIONS_API_CONSTANT.NUMBER_TYPE == object[0].input_type ||
      INPUT_INFORMATIONS_API_CONSTANT.DATE_TYPE == object[0].input_type ||
      INPUT_INFORMATIONS_API_CONSTANT.TEXTAREA_TYPE == object[0].input_type
    ) {
      // 入力項目タイプが以下の場合
      // テキスト or
      // 数値 or
      // 日付 or
      // テキストエリアの場合

      // 入力値を返却
      return data;
    } else if (
      INPUT_INFORMATIONS_API_CONSTANT.RADIO_TYPE == object[0].input_type ||
      INPUT_INFORMATIONS_API_CONSTANT.SINGLE_SELECT_TYPE == object[0].input_type
    ) {
      // 種類が以下の場合
      // ラジオボタン or
      // シングルセレクトの場合

      // 入力値を名称に変換して返却
      return this.commonService.getArrayObjectValue(
        object[0].column_code_list_multi,
        INPUT_INFORMATIONS_API_CONSTANT.COLUMN_CODE_LIST_MULTI_VALUE,
        INPUT_INFORMATIONS_API_CONSTANT.COLUMN_CODE_LIST_MULTI_NAME,
        data
      );
    } else {
      // 種類が以下の場合
      // チェックボックス or
      // マルチセレクトの場合

      // 入力値(複数)を名称に変換して格納
      return this.commonService
        .getArrayObjectMultipleValue(
          object[0].column_code_list_multi,
          INPUT_INFORMATIONS_API_CONSTANT.COLUMN_CODE_LIST_MULTI_VALUE,
          INPUT_INFORMATIONS_API_CONSTANT.COLUMN_CODE_LIST_MULTI_NAME,
          data
        )
        .join(CONSTANT.COMMA);
    }
  }

  /**
   * 注文金額を算出
   * @param decision_unitprice 販促資材決定単価
   * @param order_num 入力項目値
   */
  protected calculationOrderCost(
    decision_unitprice: number,
    order_num: number
  ): number {
    // 注文数量が入力されているか否か
    if (!order_num) {
      // 注文数量が入力されていない場合

      // 0を返却
      return 0;
    }

    // 販促資材決定単価 × 注文数量の結果を返却
    return decision_unitprice * order_num;
  }

  /**
   * エラーメッセージを出力
   * @param errorMessage APIから返却されたエラーメッセージ
   */
  private createErrorMessage(errorMessage: any): void {
    //  画面検索結果一覧分ループ
    for (const searchResults of this.searchResultsList) {
      // 主キーに紐づくエラーメッセージを取得
      const errorData = errorMessage.error[searchResults[this.getPkeyColumn()]];

      // エラーメッセージが存在するか否か
      if (!errorData) {
        // エラーメッセージが存在しない場合

        continue;
      }
      // エラーメッセージが存在する場合

      // 入力フォームエラー情報オブジェクト生成
      let inputErrorMessage: InputErrorMessage = new InputErrorMessage();

      // 主キーをセット
      inputErrorMessage.pkeyId = searchResults[this.getPkeyColumn()];

      // 販促資材IDをセット
      inputErrorMessage.itemId =
        searchResults[ADD_ORDER_API_CONSTANT.ITEM_CODE];

      // 届先IDをセット
      inputErrorMessage.deliveryId =
        searchResults[ADD_ORDER_API_CONSTANT.DELIVERY_ID];

      // エラーメッセージリスト生成
      let errorMessageList: ErrorMessage[] = new Array();

      // 主キーに紐づくエラーメッセージでループ
      for (const error of errorData) {
        // エラーメッセージオブジェクト生成
        let errorMessage: ErrorMessage = new ErrorMessage();

        // カラムIDをセット
        errorMessage.columnId =
          error[INPUT_INFORMATIONS_API_CONSTANT.COLUMN_ID];

        // カラム名称をセット
        errorMessage.columnName = this.commonService.getArrayObjectValue(
          this.inputItemList,
          INPUT_INFORMATIONS_API_CONSTANT.COLUMN_ID,
          INPUT_INFORMATIONS_API_CONSTANT.COLUMN_NAME,
          error[INPUT_INFORMATIONS_API_CONSTANT.COLUMN_ID]
        );

        // エラーメッセージをセット
        errorMessage.message = error.message;

        // エラーメッセージリストに格納
        errorMessageList.push(errorMessage);
      }

      // エラーメッセージリストをセット
      inputErrorMessage.errorMessageList = errorMessageList;

      // 入力フォームエラー情報オブジェクトをリストに格納
      this.inputErrorMessageList.push(inputErrorMessage);
    }
  }

  /**
   * エラー項目チェック
   * @param pkeyId 主キー
   * @param column_id 対象項目
   * @returns true:エラーメッセージが存在する場合 false:エラーメッセージが存在しない場合
   */
  protected checkErrorItem(pkeyId: string, columnId: string): boolean {
    // 入力エラーメッセージ出力オブジェクトリスト分ループ
    for (const inputErrorMessage of this.inputErrorMessageList) {
      // 入力エラーメッセージ出力オブジェクトの販促資材IDと引数.販促資材コードが一致するか否か
      if (pkeyId == inputErrorMessage.pkeyId) {
        // 条件が一致する場合

        // エラーメッセージリストのエラーメッセージ存在チェック
        // 対象項目が存在する場合、trueを返却
        return inputErrorMessage.errorMessageList.some(
          (errorMessage) => errorMessage.columnId === columnId
        );
      }
    }

    return false;
  }

  /**
   * プライマリーカラムを取得
   */
  protected getPkeyColumn(): string {
    return this.commonService.getPkeyColumn(this.searchResultsInformation);
  }
}
