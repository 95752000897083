/**
 * DB値定数
 */
/** 注文情報 */
// ステータス
export const ORDER$STATUS = {
  // 配送依頼
  STATUS_ONE: '1',
  // 出荷作業中
  STATUS_TWO: '2',
  // 出荷済み
  STATUS_THREE: '3',
  // 承認待ち
  STATUS_FOUR: '4',
  // 承認済み
  STATUS_FIVE: '5',
  // 承認済み
  STATUS_SIX: '6',
  // キャンセル
  STATUS_NINE: '9',
};
// 製品属性
export const ORDER$CATEGORY1 = {
  // 通常
  CATEGORY1_NORMAL: '1',
  // 新規
  CATEGORY1_NEW: '2',
  // 送込
  CATEGORY1_SEND: '3',
};

/** 計画数情報 */
// ステータス
export const PLAN_INFO$STATUS = {
  // 未入力
  STATUS_ONE: '1',
  // 承認待ち
  STATUS_TWO: '2',
  // 承認済み
  STATUS_THREE: '3',
  // キャンセル
  STATUS_NINE: '9',
};

/** 在庫メンテナンス */
// 在庫ステータス
export const STOCK_MAINTENANCE$STATUS = {
  // 倉庫連携済み
  STATUS_NINETY_SIX: '96',
  // 一部入庫完了
  STATUS_NINETY_SEVEN: '97',
  // 完了
  STATUS_NINETY_EIGHT: '98',
  // キャンセル
  STATUS_NINETY_NINE: '99',
};

/** 組織情報 */
// 組織レベル
export const DEPARTMENT$DEPARTMENT_LEVEL = {
  // 一般
  LEVEL_GENERAL: '0',
  // 管理
  LEVEL_MANAGEMENT: '1',
};
// 組織種別
export const DEPARTMENT$DEPARTMENT_TYPE = {
  // ※プロジェクト毎に変動
  TYPE_FORMER: '1',
  // ※プロジェクト毎に変動
  TYPE_DESTINATION: '2',
  // 事務局
  TYPE_OFFICE: '3',
  // 他
  TYPE_OTHER: '9',
};

/** ユーザ情報 */
// 権限レベル
export const USER$ADMIT = {
  // 一般
  ADMIT_GENERAL: '0',
  // 管理
  ADMIT_MANAGEMENT: '1',
};
