import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  API_URL_GROUP_MEMBERS,
  API_URL_GROUP_MEMBERS_CHECK_INPUT,
} from 'manager/http-constants_key';
import {
  API_RESPONSE,
  DISPLAY_INFORMATIONS_API_CONSTANT,
  INPUT_INFORMATIONS_API_CONSTANT,
} from 'src/app/shared/constant/api-constant';
import { MESSAGE_CODE } from 'src/app/shared/constant/message-constant';
import { GenerateInputErrorMessage } from 'src/app/shared/generate/generate-input/generate-input-information/generate-input-error-message/generate-input-error-message';
import { GenerateInputErrorMessageComponent } from 'src/app/shared/generate/generate-input/generate-input-information/generate-input-error-message/generate-input-error-message.component';
import { LoadingState } from 'src/app/shared/html-parts/loading/loading-state';
import { MessageData } from 'src/app/shared/html-parts/message-common/message-data';
import { CommonService } from 'src/app/shared/service/common.service';
import { DbOperationService } from 'src/app/shared/service/db-operation.service';
import { CirculationGroupService } from '../circulation-group.service';
import { CIRCULATION_GROUP_CONSTANT } from '../constant';

@Component({
  selector: 'app-add-circulation-group-member',
  templateUrl: './add-circulation-group-member.component.html',
  styleUrls: ['./add-circulation-group-member.component.scss'],
})

/**
 * 回覧グループメンバー追加画面
 */
export class AddCirculationGroupMemberComponent implements OnInit {
  /** 入力エラーメッセージ */
  @ViewChild(GenerateInputErrorMessageComponent)
  generateInputErrorMessageComponent: GenerateInputErrorMessageComponent;

  // 画面.入力フォーム
  generateInputForm: FormGroup = this.formBuilder.group(Object());

  // 画面.グループメンバー入力フォーム
  addGroupMemberForm: FormGroup = this.formBuilder.group(Object());

  // 更新対象ID
  @Output() reloadID = new EventEmitter<string>();

  /* 画面用プロパティ */
  // 入力画面ダイアログ表示フラグ
  inputModal: boolean;

  // 入力画面表示フラグ(true=役割選択,false=メンバー選択)
  inputFlag: boolean;

  // 確認画面表示フラグ
  confirmationFlag: boolean;

  // 回覧グループID格納先
  groupId: string;

  // ユーザ選択リスト生成格納先
  groupUserInputList: any[] = new Array();

  // ユーザロール選択リスト生成格納先
  groupUseRoleInputList: any[] = new Array();

  // 役割の辞書情報格納先
  dicValuesList: any[] = this.commonService.dictionaryDicIdList(
    CIRCULATION_GROUP_CONSTANT.SP_DIC_ID
  );

  // 登録用リクエストオブジェクト
  groupMemberRollRequest: any[] = new Array();

  // 入力エラーメッセージオブジェクト
  generateInputErrorMessageList: GenerateInputErrorMessage[] = new Array();

  constructor(
    private commonService: CommonService,
    private formBuilder: FormBuilder,
    private circulationGroupService: CirculationGroupService,
    private loadingState: LoadingState,
    private dbOperationService: DbOperationService,
    private messageData: MessageData
  ) {}

  ngOnInit(): void {}

  /**
   * 回覧グループメンバー登録情報表示
   * @param groupId 回覧グループID
   */
  public initial(groupId: string) {
    // 画面ロードフラグをON(ロード中状態)
    this.loadingState.loadStart('AddCirculationGroupMemberComponent');

    // 入力画面表示
    this.inputModal = true;

    // 回覧グループIDを格納
    this.groupId = groupId;

    // 画面.入力フォームを初期化
    {
      this.groupUserInputList = new Array();
      this.generateInputForm = this.formBuilder.group(Object());
      this.addGroupMemberForm = this.formBuilder.group(Object());

      // エラーメッセージ初期化
      this.clearErrorMessage();

      // 入力画面表示フラグ
      this.inputFlag = false;

      // 確認画面表示フラグ
      this.confirmationFlag = false;
    }

    /* ユーザ情報取得処理  */
    this.circulationGroupService.getUser().subscribe((response) => {
      // 画面ロードフラグをOFF(ロード終了)
      this.loadingState.loadEnd('AddCirculationGroupMemberComponent');

      // 入力項目情報が取得されたか否か
      if (this.commonService.checkNoneResponse(response)) {
        // 入力項目情報が取得されなかった場合

        return;
      }

      // 入力フォーム用コントロール作成
      for (const generateInput of response.body) {
        // 入力フォームグループに入力フォームコントロールを追加
        this.generateInputForm.addControl(
          generateInput.column_id,
          this.formBuilder.control(new Array())
        );
      }

      // 入力項目情報を画面.入力項目生成に格納
      this.groupUserInputList = response.body;

      // 選択リスト格納データ取得
      this.getGroupMembers();
    });
  }

  /**
   * 選択リスト格納データ取得
   */
  private getGroupMembers() {
    // 画面ロードフラグをON(ロード中状態)
    this.loadingState.loadStart('getGroupMembers');

    // 選択リスト格納の詳細情報取得
    this.circulationGroupService
      .getGroupMembers(this.groupId)
      .subscribe((response) => {
        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadEnd('getGroupMembers');

        // 詳細情報が取得されたか否か
        if (this.commonService.checkNoneResponse(response)) {
          // 詳細情報が取得されなかった場合

          return;
        }

        // 入力項目分ループ
        for (let groupUserInput of this.groupUserInputList) {
          // 詳細情報のカラム値(コード)を取得する
          const dataMulti = this.commonService.getArrayObjectValue(
            response.body,
            DISPLAY_INFORMATIONS_API_CONSTANT.COLUMN_ID,
            DISPLAY_INFORMATIONS_API_CONSTANT.DATA_MULTI,
            groupUserInput.column_id
          );

          // 詳細情報のカラム値(コード)を画面.入力フォームに初期表示として格納
          this.generateInputForm
            .get(groupUserInput.column_id)
            .setValue(dataMulti);
        }
      });
  }

  /**
   * ピッカーリスト.格納前情報
   * @param columnId カラムID
   */
  protected pickListSource(columnId: string): object[] {
    // カラムIDの入力項目コード情報を取得
    const generateInputDataMulti: object[] =
      this.commonService.getArrayObjectValue(
        this.groupUserInputList,
        INPUT_INFORMATIONS_API_CONSTANT.COLUMN_ID,
        INPUT_INFORMATIONS_API_CONSTANT.COLUMN_CODE_LIST_MULTI,
        columnId
      );

    // 画面.入力フォームにカラムIDの入力情報が存在するか否か
    if (!this.generateInputForm.controls[columnId].value) {
      // 画面.入力フォームにカラムIDの入力情報が存在しない場合

      // 入力項目コード情報を返却
      return generateInputDataMulti;
    }

    // userIDリストを生成
    const codeList = this.commonService.createArrayGetArrayObject(
      this.generateInputForm.controls[columnId].value,
      CIRCULATION_GROUP_CONSTANT.USER_ID
    );

    // 入力項目コード情報から詳細情報のコードを削除
    const returnDataMulti: any[] =
      this.commonService.deleteArrayObjectMultipleValue(
        generateInputDataMulti.slice(),
        CIRCULATION_GROUP_CONSTANT.USER_ID,
        codeList
      );

    // 詳細情報のコードが削除された入力項目コード情報を返却
    return returnDataMulti;
  }

  /**
   * ロールボタン表示フラグ
   * @returns true:ボタン非表示、false:ボタン表示
   */
  protected selectedRollButtonDisplayFlag(): boolean {
    // ユーザ選択リストが存在するか否か
    if (!this.groupUserInputList.length) {
      // ユーザ選択リストが存在しない場合
      return true;
    }

    // 選択したユーザリストを取得
    const selectedUserList = this.generateInputForm.get(
      this.groupUserInputList[0].column_id
    ).value;

    // 選択したユーザリストが存在するか否か
    if (selectedUserList.length) {
      // ユーザ選択リストが存在する場合

      return false;
    }

    return true;
  }

  /**
   * ロール選択画面に遷移
   */
  protected selectedRoll() {
    // 画面.グループメンバー入力フォームを初期化
    this.addGroupMemberForm = this.formBuilder.group(Object());

    // 選択したユーザリストを取得
    const selectedUserList = this.generateInputForm.get(
      this.groupUserInputList[0].column_id
    ).value;

    // 選択したユーザで入力フォーム用コントロール作成
    for (const selectedUserInput of selectedUserList) {
      // 子フォームグループを生成
      let childFormGroup = this.formBuilder.group(Object());

      // 選択したユーザでフォームコントロールを作成
      for (const inputKey in selectedUserInput) {
        // 子フォームグループに入力フォームコントロールを追加
        childFormGroup.addControl(
          inputKey,
          this.formBuilder.control(selectedUserInput[inputKey], Object())
        );
      }

      // 子フォームグループを入力フォームに格納
      this.addGroupMemberForm.addControl(
        selectedUserInput.user_id,
        childFormGroup
      );
    }

    // 選択したユーザ情報を画面.ユーザロール選択リスト生成に格納
    this.groupUseRoleInputList = selectedUserList;

    // 入力画面表示フラグ(役割選択画面に遷移)
    this.inputFlag = true;
  }

  /**
   * メンバー選択画面に遷移
   */
  protected returnSelectedGroupMembers() {
    // エラーメッセージ初期化
    this.clearErrorMessage();

    // 入力画面表示フラグ(メンバー選択画面に遷移)
    this.inputFlag = false;
  }

  /**
   * 選択したユーザロール確認
   */
  protected checkGroupUseRole() {
    // 入力フォームオブジェクトを初期化
    this.groupMemberRollRequest = new Array();

    // エラーメッセージ初期化
    this.clearErrorMessage();

    /* 登録用リクエストオブジェクト作成 */
    // 選択したユーザ情報分ループ
    for (const groupUseRoleInput of this.groupUseRoleInputList) {
      // 入力フォームオブジェクトを生成
      let groupMemberRollForm: object = new Object();

      // 入力フォームオブジェクトに対象の入力フォームを格納
      groupMemberRollForm = Object.assign(
        groupMemberRollForm,
        this.addGroupMemberForm.controls[groupUseRoleInput.user_id].value
      );

      // 入力フォームオブジェクトをリストに格納
      this.groupMemberRollRequest.push(groupMemberRollForm);
    }

    // 画面ロードフラグをON(ロード中状態)
    this.loadingState.loadStart();

    /* 入力確認 */
    {
      // 入力項目値確認用API
      this.dbOperationService
        .insertData(API_URL_GROUP_MEMBERS_CHECK_INPUT, {
          group_id: this.groupId,
          list: this.groupMemberRollRequest,
        })
        .subscribe((response) => {
          // 画面ロードフラグをOFF(ロード終了)
          this.loadingState.loadEnd();

          // バリデーションチェック状態を確認
          if (API_RESPONSE.FAIL == response.body[0].result) {
            // チェック状態が異常の場合

            // 選択したユーザ情報分ループ
            for (const item of this.groupUseRoleInputList) {
              // 入力項目に該当するエラーメッセージが存在するか判定
              if (response.body[0].error[item.user_id]) {
                // エラーメッセージが存在する場合

                //  入力フォームエラー情報を生成
                let generateInputErrorMessage: GenerateInputErrorMessage =
                  new GenerateInputErrorMessage();
                //  入力フォームエラー情報を格納
                generateInputErrorMessage.columnId = item.user_id;
                generateInputErrorMessage.columnName = item.user_name;
                generateInputErrorMessage.columnErrorMessage =
                  response.body[0].error[item.user_id][0].message;
                this.generateInputErrorMessageList.push(
                  generateInputErrorMessage
                );
              }
            }

            // 入力フォームエラー情報を出力
            this.generateInputErrorMessageComponent.initial(
              this.generateInputErrorMessageList
            );

            // 処理を終了
            return;
          }

          // 確認画面を表示
          this.confirmationFlag = true;
        });
    }
  }

  /**
   * グループメンバー登録
   */
  protected registGroupUseRole() {
    // 画面ロードフラグをON(ロード中状態)
    this.loadingState.loadStart();

    /* グループメンバー登録 */
    {
      // 入力項目値登録API
      this.dbOperationService
        .insertData(API_URL_GROUP_MEMBERS, {
          group_id: this.groupId,
          list: this.groupMemberRollRequest,
        })
        .subscribe((response) => {
          // 画面ロードフラグをOFF(ロード終了)
          this.loadingState.loadEnd();

          // レスポンスを判定
          if (
            this.messageData.responseToastMessage(
              response,
              null,
              this.commonService.msg(MESSAGE_CODE.I00001)
            )
          ) {
            // レスポンスが正常終了の場合

            // 入力画面非表示
            this.inputModal = false;

            // 更新対象IDにプライマリキーをセット
            this.reloadID.emit(this.groupId);
          }
        });
    }

    // 画面ロードフラグをOFF(ロード終了)
    this.loadingState.loadEnd();
  }

  /**
   * 役割名称取得
   * @param spCode コード値
   * @returns コード名称
   */
  protected changeCodeValue(code: string): string {
    // 役割名称を取得
    return this.commonService.dictionaryCodeCnvName(
      CIRCULATION_GROUP_CONSTANT.SP_DIC_ID,
      code
    );
  }

  /**
   * エラーメッセージ初期化
   */
  private clearErrorMessage() {
    // 入力フォームエラー情報を初期化
    this.generateInputErrorMessageList = new Array();
    this.generateInputErrorMessageComponent.clear();
  }

  /**
   * エラー項目チェック
   * @param userId ユーザーID
   * @returns true:エラーメッセージが存在する場合 false:エラーメッセージが存在しない場合
   */
  protected checkErrorItem(userId: string): boolean {
    // エラーメッセージリストのエラーメッセージ存在チェック
    // 対象項目が存在する場合、trueを返却
    return this.generateInputErrorMessageList.some(
      (errorMessage) => errorMessage.columnId === userId
    );
  }
}
