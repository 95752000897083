import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  API_URL_CIRCULATIONS,
  API_URL_INPUT_CIRCULATIONS,
} from 'manager/http-constants_key';
import { SUMMARY_CIRCULATION_TEMPLATE } from 'manager/template-constant';
import { GENERATE_INPUT_TYPE } from 'src/app/shared/generate/generate-input/constant';
import { GenerateInputErrorMessageComponent } from 'src/app/shared/generate/generate-input/generate-input-information/generate-input-error-message/generate-input-error-message.component';
import { GenerateInputInformationComponent } from 'src/app/shared/generate/generate-input/generate-input-information/generate-input-information.component';

@Component({
  selector: 'app-summary-circulation-end',
  templateUrl: './summary-circulation-end.component.html',
  styleUrls: ['./summary-circulation-end.component.scss'],
})

/**
 * サマリー回覧.回覧終了画面
 */
export class SummaryCirculationEndComponent implements OnInit {
  /** 入力エラーメッセージ */
  @ViewChild(GenerateInputErrorMessageComponent)
  generateInputErrorMessageComponent: GenerateInputErrorMessageComponent;

  /** 入力情報 */
  @ViewChild(GenerateInputInformationComponent)
  generateInputInformationComponent: GenerateInputInformationComponent;

  // 更新対象ID
  @Output() reloadID = new EventEmitter<string>();

  /* 画面用プロパティ */
  // 入力ダイアログ表示フラグ
  inputModal: boolean;

  constructor() {}

  ngOnInit(): void {}

  /**
   * 回覧終了画面表示
   * @param circulationId 回覧ID
   */
  public inputSummaryCirculationEnd(circulationId: string) {
    // 入力フォーム表示
    this.generateInputInformationComponent.initial(
      GENERATE_INPUT_TYPE.EDIT,
      API_URL_INPUT_CIRCULATIONS,
      API_URL_CIRCULATIONS,
      SUMMARY_CIRCULATION_TEMPLATE.CIRCULATION_END_INPUT_TEMPLATE_ID,
      circulationId
    );

    // 入力画面ダイアログを表示
    this.inputModal = true;
  }

  /**
   * 親画面へ返却する
   */
  public return(reloadID: string) {
    // 入力画面ダイアログを非表示
    this.inputModal = false;

    // 更新対象IDにプライマリキーをセット
    this.reloadID.emit(reloadID);
  }
}
