/** 詳細情報オブジェクト */
export class GenerateDisplay {
  // タイトル(初期値は"詳細")
  private _title: string = '詳細';

  // APIの名前
  private _endPoint: string;

  // 出力テンプレートID
  private _templateId: number;

  // 選択対象ID(詳細情報の複数取得時、設定されている場合、第一引数より優先)
  private _pkeyId: string;

  set title(title: string) {
    this._title = title;
  }

  get title(): string {
    return this._title;
  }

  set endPoint(endPoint: string) {
    this._endPoint = endPoint;
  }

  get endPoint(): string {
    return this._endPoint;
  }

  set templateId(templateId: number) {
    this._templateId = templateId;
  }

  get templateId(): number {
    return this._templateId;
  }

  set pkeyId(pkeyId: string) {
    this._pkeyId = pkeyId;
  }

  get pkeyId(): string {
    return this._pkeyId;
  }
}

/** 詳細情報出力オブジェクト */
export class OutputGenerateDisplay {
  // タイトル
  _title: string;

  // 詳細出力情報
  _generateDisplay: any;

  set title(title: string) {
    this._title = title;
  }

  get title(): string {
    return this._title;
  }

  set generateDisplay(generateDisplay: any) {
    this._generateDisplay = generateDisplay;
  }

  get generateDisplay(): any {
    return this._generateDisplay;
  }
}
