import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import {
  DbOperationService,
  FamilyTemplateId,
} from 'src/app/shared/service/db-operation.service';
import { GenerateInputComponent } from 'src/app/shared/generate/generate-input/generate-input.component';
//import { SummaryDisplayComponent } from '../mycurculation-display/mycurculation-display.component';
import {
  API_URL_ITEM,
  API_URL_SEARCH_ITEM,
  API_URL_INPUT_ITEM,
  API_URL_MYCIRCULATION,
} from 'manager/http-constants_key';
import { CONSTANT, DATE_FORMAT } from 'src/app/shared/constant/constant';
import { GENERATE_INPUT_TYPE } from 'src/app/shared/generate/generate-input/constant';
//import { SUMMARY_CONSTANT } from 'src/app/pages/mycurculation/mycurculation-list/constant';
import { GET_MYCIRCULATION_TEMPLATE } from 'manager/template-constant';
import { CommonService } from 'src/app/shared/service/common.service';
import {
  LoadData,
  LoadingState,
} from 'src/app/shared/html-parts/loading/loading-state';
import { MESSAGE_CODE } from 'src/app/shared/constant/message-constant';
import { ExportFileService } from 'src/app/shared/html-parts/export-file/export-file.service';
import { DeleteDialogService } from 'src/app/shared/html-parts/confirm-dialog/delete-dialog/delete-dialog.service';
import { Table } from 'primeng/table';

@Component({
  selector: 'app-mycirculation-list',
  templateUrl: './mycirculation-list.component.html',
  styleUrls: ['./mycirculation-list.component.scss'],
})

/**
 * My回覧画面
 */
export class MyCirculationlistComponent implements OnInit {
  /** 新規登録、編集、複製画面 */
  @ViewChild(GenerateInputComponent)
  generateInputComponent: GenerateInputComponent;

  /** テーブル状態 */
  @ViewChild('table') table: Table;

  /* 画面用プロパティ */
  // 画面ヘッダー情報格納先
  columnOrder: any[] = new Array();

  // 画面ヘッダー（コンテンツ）
  columnOrder2: any[] = new Array();

  // 画面検索結果一覧格納先
  searchResultsList: any[] = new Array();

  // 検索条件保持
  generateSearchItems: Object;

  constructor(
    public datePipe: DatePipe,
    private dbOperationService: DbOperationService,
    private commonService: CommonService,
    private loadingState: LoadingState,
    private exportFileService: ExportFileService,
    private deleteDialogService: DeleteDialogService,
    private router: Router
  ) {}

  ngOnInit() {
    // 画面ロードフラグをON(ロード中状態)
    this.loadingState.loadStart();

    /* ヘッダー情報取得処理(画面用) */
    this.dbOperationService
      .getHeaderList(GET_MYCIRCULATION_TEMPLATE.GET_MYCIRCULATION_TEMPLATE_ID)
      .subscribe((response) => {
        this.columnOrder = response.body;
      });

    // 検索処理を実施
    this.searchResult();
  }

  /**
   * 検索処理
   * @param generateSearchItems 検索条件
   */
  public searchResult(generateSearchItems?: Object) {
    // 画面カスタムロードフラグをON(ロード中状態)
    this.loadingState.customLoadStart(
      new LoadData({
        loadingText: this.commonService.msg(MESSAGE_CODE.S00003),
      })
    );

    /* サマリーリスト取得処理(画面用) */
    this.dbOperationService
      .getData(
        API_URL_MYCIRCULATION,
        new FamilyTemplateId({
          parentTemplateId:
            GET_MYCIRCULATION_TEMPLATE.GET_MYCIRCULATION_TEMPLATE_ID,
        }),
        generateSearchItems
      )
      .subscribe((response) => {
        // コード値の一覧情報が存在するか否か
        if (!this.commonService.checkNoneResponse(response)) {
          // サマリーリスト情報のJSONをオブジェクトに格納する
          this.searchResultsList = response.body;
        } else {
          this.searchResultsList = new Array();
        }

        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadSleepEnd(0.3);
      });

    // テーブル状態が存在するか否か
    if (this.table) {
      // テーブル状態が存在する場合

      // テーブル状態をリセット
      this.table.reset();
    }
  }

  /**
   * 回覧詳細画面表示
   * @param content_id コンテンツID
   * @param circulation_id 回覧ID
   */
  public detail_circulation(content_id: string, circulation_id: string) {
    // サマリー詳細画面へ遷移
    this.router.navigate(['pages/summary/summary-circulation/2'], {
      queryParams: { content_id: content_id, circulation_id: circulation_id },
    });
  }

  /**
   * 編集ボタン出力表示判定
   * @param rowData 行データ
   * @returns true:ボタン表示、false:ボタン非表示
   */
  protected editButtonDisplayFlag(rowData: any): boolean {
    return true;
  }
}
