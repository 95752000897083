import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DbOperationService } from 'src/app/shared/service/db-operation.service';
import { GenerateInputComponent } from 'src/app/shared/generate/generate-input/generate-input.component';
import { GenerateDisplayComponent } from 'src/app/shared/generate/generate-display/generate-display.component';
import {
  API_URL_INFORMATION,
  API_URL_INPUT_INFORMATION,
  API_URL_SEARCH_INFORMATION,
} from 'manager/http-constants_key';
import { CONSTANT, DATE_FORMAT } from 'src/app/shared/constant/constant';
import { GENERATE_INPUT_TYPE } from 'src/app/shared/generate/generate-input/constant';
import { INFORMATION_CONSTANT } from './constant';
import { INFORMATION_TEMPLATE } from 'manager/template-constant';
import { CommonService } from 'src/app/shared/service/common.service';
import { GenerateDisplay } from 'src/app/shared/generate/generate-display/generate-display-information/generate-display-information';
import {
  LoadData,
  LoadingState,
} from 'src/app/shared/html-parts/loading/loading-state';
import { MESSAGE_CODE } from 'src/app/shared/constant/message-constant';
import { ExportFileService } from 'src/app/shared/html-parts/export-file/export-file.service';
import { DeleteDialogService } from 'src/app/shared/html-parts/confirm-dialog/delete-dialog/delete-dialog.service';
import { Table } from 'primeng/table';
import { SESSION_KEY } from 'src/app/shared/constant/session-constants';

@Component({
  selector: 'app-information',
  templateUrl: '../master.component.html',
  styleUrls: ['../master.component.scss'],
})

/**
 * お知らせマスタ画面
 */
export class InformationComponent implements OnInit {
  /** 詳細画面 */
  @ViewChild(GenerateDisplayComponent)
  generateDisplayItemsComponent: GenerateDisplayComponent;

  /** 新規登録、編集、複製画面 */
  @ViewChild(GenerateInputComponent)
  generateInputComponent: GenerateInputComponent;

  /** テーブル状態 */
  @ViewChild('table') table: Table;

  // 検索項目生成エンドポイント
  public searchEndPoint: string = API_URL_SEARCH_INFORMATION;

  // 検索項目生成テンプレートID
  public searchTemplateId: number = INFORMATION_TEMPLATE.SEARCH_TEMPLATE_ID;

  /* 画面用プロパティ */
  // 画面ヘッダー情報格納先
  columnOrder: any[] = new Array();

  // 画面検索結果一覧格納先
  searchResultsList: any[] = new Array();

  // 検索条件保持
  generateSearchItems: Object;

  //ログインユーザ情報
  loginUser = JSON.parse(
    window.sessionStorage.getItem(SESSION_KEY.loginUserInformation)
  );

  constructor(
    public datePipe: DatePipe,
    private dbOperationService: DbOperationService,
    private commonService: CommonService,
    private loadingState: LoadingState,
    private exportFileService: ExportFileService,
    private deleteDialogService: DeleteDialogService
  ) {}

  ngOnInit() {
    // 画面ロードフラグをON(ロード中状態)
    this.loadingState.loadStart();

    /* ヘッダー情報取得処理(画面用) */
    this.dbOperationService
      .getHeaderList(INFORMATION_TEMPLATE.SEARCH_RESULTS_TEMPLATE_ID)
      .subscribe((response) => {
        this.columnOrder = response.body;
      });

    // 検索処理を実施
    this.searchResult();
  }

  /**
   * 検索処理
   * @param generateSearchItems 検索条件
   */
  public searchResult(generateSearchItems?: Object) {
    // 画面カスタムロードフラグをON(ロード中状態)
    this.loadingState.customLoadStart(
      new LoadData({
        loadingText: this.commonService.msg(MESSAGE_CODE.S00003),
      })
    );

    // CSV出力用に検索条件を保持
    this.generateSearchItems = generateSearchItems;

    /* お知らせマスタ一覧取得処理(画面用) */
    this.dbOperationService
      .getData(
        API_URL_INFORMATION,
        INFORMATION_TEMPLATE.SEARCH_RESULTS_TEMPLATE_ID,
        generateSearchItems
      )
      .subscribe((response) => {
        // コード値の一覧情報が存在するか否か
        if (!this.commonService.checkNoneResponse(response)) {
          // お知らせマスタ一覧情報のJSONをオブジェクトに格納する
          this.searchResultsList = response.body;
        } else {
          this.searchResultsList = new Array();
        }

        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadSleepEnd(0.3);
      });

    // テーブル状態が存在するか否か
    if (this.table) {
      // テーブル状態が存在する場合

      // テーブル状態をリセット
      this.table.reset();
    }
  }

  /**
   * 詳細情報画面表示
   * @param pkeyId IDコード(クリックされたID)
   */
  protected detail(pkeyId: string) {
    // 詳細画面表示
    let generateDisplay: GenerateDisplay = new GenerateDisplay();
    generateDisplay.endPoint = API_URL_INFORMATION;
    generateDisplay.templateId = INFORMATION_TEMPLATE.OUTPUT_TEMPLATE_ID;
    this.generateDisplayItemsComponent.initial(generateDisplay, pkeyId);
  }

  /**
   * 新規登録ボタン出力表示判定
   * @returns true:ボタン表示、false:ボタン非表示
   */
  protected newButtonDisplayFlag(): boolean {
    if (
      this.loginUser.admit === '1' ||
      this.loginUser.department_code === '99999'
    ) {
      return true;
    }
    return false;
  }

  /**
   * 編集ボタン出力表示判定
   * @param rowData 行データ
   * @returns true:ボタン表示、false:ボタン非表示
   */
  protected editButtonDisplayFlag(rowData: any): boolean {
    if (
      this.loginUser.admit === '1' ||
      this.loginUser.department_code === '99999'
    ) {
      return true;
    }
    return false;
  }

  /**
   * 複製ボタン出力表示判定
   * @param rowData 行データ
   * @returns true:ボタン表示、false:ボタン非表示
   */
  protected duplicateButtonDisplayFlag(rowData: any): boolean {
    if (
      this.loginUser.admit === '1' ||
      this.loginUser.department_code === '99999'
    ) {
      return true;
    }
    return false;
  }

  /**
   * 削除ボタン出力表示判定
   * @param rowData 行データ
   * @returns true:ボタン表示、false:ボタン非表示
   */
  protected deleteButtonDisplayFlag(rowData: any): boolean {
    if (
      this.loginUser.admit === '1' ||
      this.loginUser.department_code === '99999'
    ) {
      return true;
    }
    return false;
  }

  /**
   * 新規登録画面表示
   */
  protected new() {
    // 新規登録画面表示
    this.generateInputComponent.initial(
      GENERATE_INPUT_TYPE.NEW,
      API_URL_INPUT_INFORMATION,
      API_URL_INFORMATION,
      INFORMATION_TEMPLATE.NEW_INPUT_TEMPLATE_ID
    );
  }

  /**
   * 編集画面表示
   * @param pkeyId IDコード(クリックされたID)
   */
  protected edit(pkeyId: string) {
    // 編集画面表示
    this.generateInputComponent.initial(
      GENERATE_INPUT_TYPE.EDIT,
      API_URL_INPUT_INFORMATION,
      API_URL_INFORMATION,
      INFORMATION_TEMPLATE.EDIT_INPUT_TEMPLATE_ID,
      pkeyId
    );
  }

  /**
   * 複製画面表示
   * @param pkeyId IDコード(クリックされたID)
   */
  protected duplicate(pkeyId: string) {
    // 複製画面表示
    this.generateInputComponent.initial(
      GENERATE_INPUT_TYPE.DUPLICATE,
      API_URL_INPUT_INFORMATION,
      API_URL_INFORMATION,
      INFORMATION_TEMPLATE.DUPLICATE_INPUT_TEMPLATE_ID,
      pkeyId
    );
  }

  /**
   * 削除処理
   * @param pkeyId IDコード(クリックされたID)
   */
  protected delete(pkeyId: string) {
    // 削除ダイアログにより削除処理
    this.deleteDialogService
      .deleteDialog(
        API_URL_INFORMATION,
        this.commonService.getPkeyColumnName(this.columnOrder),
        pkeyId
      )
      .subscribe((reloadID) => {
        // 検索結果一覧から該当データを削除
        this.searchResultsList = this.commonService.deleteArrayObjectValue(
          this.searchResultsList,
          this.getPkeyColumn(),
          reloadID
        );
      });
  }

  /**
   * 再検索処理＆データ置換
   * @param pkeyId コード値
   */
  public searchReplacement(pkeyId: string) {
    // 画面カスタムロードフラグをON(ロード中状態)
    this.loadingState.customLoadStart(
      new LoadData({
        loadingText: this.commonService.msg(MESSAGE_CODE.S00004),
      })
    );

    /* データが新規,複製の場合 */
    // 返却されたコード値が存在するか否か
    if (!pkeyId) {
      // 返却されたコード値が存在しない場合

      // 検索処理を実施(テーブル状態初期化,全検索)
      this.searchResult();

      return;
    }

    /* データが編集かつソート実施済みの場合 */
    // テーブル状態がソート済みか否か
    if (this.table.sortField) {
      // テーブル状態がソート済みの場合

      // 検索処理を実施(テーブル状態初期化,検索条件あり)
      this.searchResult(this.generateSearchItems);

      return;
    }

    /* データが編集かつソート未実施の場合 */
    /* お知らせマスタ一覧再取得処理(画面用) */
    this.dbOperationService
      .getSingleData(
        API_URL_INFORMATION,
        INFORMATION_TEMPLATE.SEARCH_RESULTS_TEMPLATE_ID,
        this.getPkeyColumn(),
        pkeyId
      )
      .subscribe((response) => {
        /* 検索結果一覧から該当データを更新 */
        // 検索結果一覧格納先から対象のデータの行数を抽出
        const indexTargetData = this.commonService.getRowNumberArrayObject(
          this.searchResultsList,
          this.getPkeyColumn(),
          pkeyId
        );

        // 検索結果一覧情報のJSONをオブジェクトに置換する
        this.searchResultsList[indexTargetData] = response.body[0];

        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadEnd();
      });
  }

  /**
   * CSV出力
   */
  protected exportCSV() {
    // csvファイル名の設定
    const fileName =
      INFORMATION_CONSTANT.CSV_FILENAME +
      CONSTANT.UNDERBAR +
      this.datePipe.transform(new Date(), DATE_FORMAT.DATETIME_HYPHEN);

    // CSVファイル出力
    this.exportFileService.exportTemplateCsv(
      fileName,
      null,
      API_URL_INFORMATION,
      INFORMATION_TEMPLATE.CSV_TEMPLATE_ID,
      this.generateSearchItems
    );
  }

  /**
   * プライマリーカラムを取得
   */
  protected getPkeyColumn(): string {
    return this.commonService.getPkeyColumn(this.columnOrder);
  }
}
