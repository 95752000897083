import { Injectable } from '@angular/core';
import { DIALOG_DEFAULT_CONSTANT } from './constant';

/**
 * 確認ダイアログボタン変更クラス
 */
@Injectable()
export class ConfirmDialogData {
  // 確認ダイアログ情報
  public confirmDialogInformation: ConfirmDialogInformation =
    new ConfirmDialogInformation();

  constructor() {}

  /**
   * 確認ダイアログ情報を設定
   * @param confirmDialogInformation 確認ダイアログ情報
   */
  public changeConfirmDialog(
    confirmDialogInformation: ConfirmDialogInformation
  ): void {
    // ダイアログ情報を格納
    this.confirmDialogInformation = confirmDialogInformation;
  }
}

/** 確認ダイアログ情報オブジェクト */
export class ConfirmDialogInformation {
  // 'はい'ボタン
  private _yesText: string = DIALOG_DEFAULT_CONSTANT.yes;

  // 'いいえ'ボタン
  private _noText: string = DIALOG_DEFAULT_CONSTANT.no;

  constructor(init?: Partial<ConfirmDialogInformation>) {
    Object.assign(this, init);
  }

  set yesText(yesText: string) {
    this._yesText = yesText;
  }

  get yesText(): string {
    return this._yesText;
  }

  set noText(noText: string) {
    this._noText = noText;
  }

  get noText(): string {
    return this._noText;
  }
}
