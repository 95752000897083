/**
 * 設定テンプレートID
 */
/**
 * TOP
 */
// TOP画面
export const TOP = {
  // 入力項目生成テンプレートID
  INFORMATION: 1508,
};

/** --------------------------------------------------------- */

/**
 * マスタ管理
 */
// 営業日マスタ画面
export const BIZDAY_TEMPLATE = {
  // 検索項目生成テンプレートID
  SEARCH_TEMPLATE_ID: 1601,
  // 一覧項目テンプレートID
  SEARCH_RESULTS_TEMPLATE_ID: 1602,
  // 表示項目生成テンプレートID
  OUTPUT_TEMPLATE_ID: 1603,
  // CSV出力テンプレートID
  CSV_TEMPLATE_ID: 1604,
  // 新規入力項目生成テンプレートID
  NEW_INPUT_TEMPLATE_ID: 1105,
  // 編集入力項目生成テンプレートID
  EDIT_INPUT_TEMPLATE_ID: 1105,
  // 複製入力項目生成テンプレートID
  DUPLICATE_INPUT_TEMPLATE_ID: 1105,
};

// 得意先マスタ画面
export const CUSTOMER_TEMPLATE = {
  // 検索項目生成テンプレートID
  SEARCH_TEMPLATE_ID: 1601,
  // 一覧項目テンプレートID
  SEARCH_RESULTS_TEMPLATE_ID: 1602,
  // 表示項目生成テンプレートID
  OUTPUT_TEMPLATE_ID: 1603,
  // CSV出力テンプレートID
  CSV_TEMPLATE_ID: 1604,
  // 新規入力項目生成テンプレートID
  NEW_INPUT_TEMPLATE_ID: 1605,
  // 編集入力項目生成テンプレートID
  EDIT_INPUT_TEMPLATE_ID: 1606,
  // 複製入力項目生成テンプレートID
  DUPLICATE_INPUT_TEMPLATE_ID: 1607,
};

// 届先マスタ画面
export const DELIVERY_TEMPLATE = {
  // 検索項目生成テンプレートID
  SEARCH_TEMPLATE_ID: 1201,
  // 一覧項目テンプレートID
  SEARCH_RESULTS_TEMPLATE_ID: 1202,
  // 一覧項目内部情報テンプレートID
  SEARCH_RESULTS_INFORMATION_TEMPLATE_ID: 1203,

  // 管理ユーザ
  // 表示項目生成テンプレートID
  MANAGEMENT_OUTPUT_TEMPLATE_ID: 1204,
  // CSV出力テンプレートID
  MANAGEMENT_CSV_TEMPLATE_ID: 1205,
  // 新規入力項目生成テンプレートID
  MANAGEMENT_NEW_INPUT_TEMPLATE_ID: 1206,
  // 編集入力項目生成テンプレートID
  MANAGEMENT_EDIT_INPUT_TEMPLATE_ID: 1207,
  // 複製入力項目生成テンプレートID
  MANAGEMENT_DUPLICATE_INPUT_TEMPLATE_ID: 1208,

  // 承認ユーザ
  // 表示項目生成テンプレートID
  APPROVAL_OUTPUT_TEMPLATE_ID: 1209,
  // CSV出力テンプレートID
  APPROVAL_CSV_TEMPLATE_ID: 1210,
  // 新規入力項目生成テンプレートID
  APPROVAL_NEW_INPUT_TEMPLATE_ID: 1211,
  // 編集入力項目生成テンプレートID
  APPROVAL_EDIT_INPUT_TEMPLATE_ID: 1212,
  // 複製入力項目生成テンプレートID
  APPROVAL_DUPLICATE_INPUT_TEMPLATE_ID: 1213,

  // 一般ユーザ
  // 検索項目生成テンプレートID
  GENERAL_SEARCH_TEMPLATE_ID: 1214,
  // 表示項目生成テンプレートID
  GENERAL_OUTPUT_TEMPLATE_ID: 1215,
  // CSV出力テンプレートID
  GENERAL_CSV_TEMPLATE_ID: 1216,
  // 新規入力項目生成テンプレートID
  GENERAL_NEW_INPUT_TEMPLATE_ID: 1217,
  // 編集入力項目生成テンプレートID
  GENERAL_EDIT_INPUT_TEMPLATE_ID: 1218,
  // 複製入力項目生成テンプレートID
  GENERAL_DUPLICATE_INPUT_TEMPLATE_ID: 1219,
};

// 届先グループマスタ画面
export const DELIVERY_GROUP_TEMPLATE = {
  // 検索項目生成テンプレートID
  SEARCH_TEMPLATE_ID: 1701,
  // 一覧項目テンプレートID
  SEARCH_RESULTS_TEMPLATE_ID: 1702,
  // 表示項目生成テンプレートID
  OUTPUT_TEMPLATE_ID: 1703,
  // CSV出力テンプレートID
  CSV_TEMPLATE_ID: 1704,
  // 新規入力項目生成テンプレートID
  NEW_INPUT_TEMPLATE_ID: 1705,
  // 編集入力項目生成テンプレートID
  EDIT_INPUT_TEMPLATE_ID: 1706,
  // 複製入力項目生成テンプレートID
  DUPLICATE_INPUT_TEMPLATE_ID: 1707,
};

// 組織マスタ画面
export const DEPARTMENT_TEMPLATE = {
  // 検索項目生成テンプレートID
  SEARCH_TEMPLATE_ID: 1301,
  // 一覧項目テンプレートID
  SEARCH_RESULTS_TEMPLATE_ID: 1302,
  // 表示項目生成テンプレートID
  OUTPUT_TEMPLATE_ID: 1303,
  // CSV出力テンプレートID
  CSV_TEMPLATE_ID: 1304,
  // 新規入力項目生成テンプレートID
  NEW_INPUT_TEMPLATE_ID: 1305,
  // 編集入力項目生成テンプレートID
  EDIT_INPUT_TEMPLATE_ID: 1306,
  // 複製入力項目生成テンプレートID
  DUPLICATE_INPUT_TEMPLATE_ID: 1307,
};

// お知らせマスタ画面
export const INFORMATION_TEMPLATE = {
  // 検索項目生成テンプレートID
  SEARCH_TEMPLATE_ID: 1501,
  // 一覧項目テンプレートID
  SEARCH_RESULTS_TEMPLATE_ID: 1502,
  // 表示項目生成テンプレートID
  OUTPUT_TEMPLATE_ID: 1503,
  // CSV出力テンプレートID
  CSV_TEMPLATE_ID: 1504,
  // 新規入力項目生成テンプレートID
  NEW_INPUT_TEMPLATE_ID: 1505,
  // 編集入力項目生成テンプレートID
  EDIT_INPUT_TEMPLATE_ID: 1506,
  // 複製入力項目生成テンプレートID
  DUPLICATE_INPUT_TEMPLATE_ID: 1507,
};

// 販促資材マスタ画面
export const ITEM_TEMPLATE = {
  // 検索項目生成テンプレートID
  SEARCH_TEMPLATE_ID: 1101,
  // 一覧項目テンプレートID
  SEARCH_RESULTS_TEMPLATE_ID: 1102,
  // 表示項目生成テンプレートID
  OUTPUT_TEMPLATE_ID: 1103,
  // CSV出力テンプレートID
  CSV_TEMPLATE_ID: 1104,
  // 新規入力項目生成テンプレートID
  NEW_INPUT_TEMPLATE_ID: 1105,
  // 編集入力項目生成テンプレートID
  EDIT_INPUT_TEMPLATE_ID: 1106,
  // 複製入力項目生成テンプレートID
  DUPLICATE_INPUT_TEMPLATE_ID: 1107,
  // 在庫管理用表示項目生成テンプレートID
  // TODO 森永では未使用
  STOCK_OUTPUT_TEMPLATE_ID: 1108,
  // オリジナル画像ダウンロードテンプレートID
  OUTPUT_IMAGE_TEMPLATE_ID: 1109,
};

// 販促資材斡旋マスタ画面
export const ITEM_MEDIATION_TEMPLATE = {
  // 検索項目生成テンプレートID
  SEARCH_TEMPLATE_ID: 1801,
  // 一覧項目テンプレートID
  SEARCH_RESULTS_TEMPLATE_ID: 1802,
  // 表示項目生成テンプレートID
  OUTPUT_TEMPLATE_ID: 1803,
  // 表示項目生成(販促資材マスタ)テンプレートID
  ITEM_OUTPUT_TEMPLATE_ID: 1103,
  // CSV出力テンプレートID
  CSV_TEMPLATE_ID: 1804,
  // 新規入力項目生成テンプレートID
  NEW_INPUT_TEMPLATE_ID: 1805,
  // 編集入力項目生成テンプレートID
  EDIT_INPUT_TEMPLATE_ID: 1806,
  // 複製入力項目生成テンプレートID
  DUPLICATE_INPUT_TEMPLATE_ID: 1807,
};

// ユーザーマスタ画面
export const USER_TEMPLATE = {
  // 検索項目生成テンプレートID
  SEARCH_TEMPLATE_ID: 1401,
  // 一覧項目テンプレートID
  SEARCH_RESULTS_TEMPLATE_ID: 1402,
  // 表示項目生成テンプレートID
  OUTPUT_TEMPLATE_ID: 1403,
  // CSV出力テンプレートID
  CSV_TEMPLATE_ID: 1404,
  // 新規入力項目生成テンプレートID
  NEW_INPUT_TEMPLATE_ID: 1405,
  // 編集入力項目生成テンプレートID
  EDIT_INPUT_TEMPLATE_ID: 1406,
  // 複製入力項目生成テンプレートID
  DUPLICATE_INPUT_TEMPLATE_ID: 1407,
};

/** --------------------------------------------------------- */

/**
 * 在庫管理
 */
// 製作指示画面
export const PRODUCTION_INSTRUCTION_TEMPLATE = {
  // 検索項目生成テンプレートID
  SEARCH_TEMPLATE_ID: 601,
  // 一覧項目テンプレートID
  SEARCH_RESULTS_TEMPLATE_ID: 602,
  // 表示項目生成テンプレートID
  OUTPUT_TEMPLATE_ID: 603,
  // CSV出力テンプレートID
  CSV_TEMPLATE_ID: 604,
  // 入力項目生成テンプレートID
  INPUT_TEMPLATE_ID: 605,
};

// 在庫メンテナンスファイル登録画面
export const REGISTER_STOCK_MAINTENANCE_TEMPLATE = {
  // 確認画面ヘッダーテンプレートID
  CONFIRMATION_HEADER_TEMPLATE_ID: 309,
};

// 在庫メンテナンス管理画面
export const STOCK_MAINTENANCE_TEMPLATE = {
  // 検索項目生成テンプレートID
  SEARCH_TEMPLATE_ID: 301,
  // 一覧項目テンプレートID
  SEARCH_RESULTS_TEMPLATE_ID: 302,
  // 表示項目生成テンプレートID
  OUTPUT_TEMPLATE_ID: 303,
  // CSV出力テンプレートID
  CSV_TEMPLATE_ID: 304,
  // 編集入力項目生成テンプレートID
  EDIT_INPUT_TEMPLATE_ID: 307,
};

/** --------------------------------------------------------- */

/**
 * 発注管理
 */
// 発注選択画面
export const WORK_ORDER_TEMPLATE = {
  // 販促資材一覧
  ITEM_TEMPLATE_ID: 109,
  // 届先一覧
  DELIVERIES_TEMPLATE_ID: 110,
  // 注文情報（WORK）
  INPUT_TEMPLATE_ID: 111,
};

// 発注届先画面
export const DELIVERIES_SEARCH_TEMPLATE = {
  // 検索項目生成テンプレートID
  SEARCH_TEMPLATE_ID: 111,
  // 一覧項目テンプレートID
  SEARCH_RESULTS_TEMPLATE_ID: 112,

  // 管理ユーザ
  // 表示項目生成テンプレートID
  MANAGEMENT_OUTPUT_TEMPLATE_ID: 113,
  // CSV出力テンプレートID
  MANAGEMENT_CSV_TEMPLATE_ID: 114,

  // 承認ユーザ
  // 表示項目生成テンプレートID
  APPROVAL_OUTPUT_TEMPLATE_ID: 118,
  // CSV出力テンプレートID
  APPROVAL_CSV_TEMPLATE_ID: 119,

  // 一般ユーザ
  // 表示項目生成テンプレートID
  GENERAL_OUTPUT_TEMPLATE_ID: 120,
  // CSV出力テンプレートID
  GENERAL_CSV_TEMPLATE_ID: 121,
};

// 発注販促資材画面
export const ITEM_SEARCH_TEMPLATE = {
  // カテゴリ:通常
  // 検索項目生成テンプレートID
  NORMAL_SEARCH_TEMPLATE_ID: 101,
  // 一覧項目テンプレートID
  NORMAL_SEARCH_RESULTS_TEMPLATE_ID: 102,
  // CSV出力テンプレートID
  NORMAL_CSV_TEMPLATE_ID: 108,

  // カテゴリ:新規
  // 検索項目生成テンプレートID
  NEW_SEARCH_TEMPLATE_ID: 103,
  // 一覧項目テンプレートID
  NEW_SEARCH_RESULTS_TEMPLATE_ID: 104,
  // CSV出力テンプレートID
  NEW_CSV_TEMPLATE_ID: 109,

  // カテゴリ:送込
  // 検索項目生成テンプレートID
  SEND_SEARCH_TEMPLATE_ID: 105,
  // 一覧項目テンプレートID
  SEND_SEARCH_RESULTS_TEMPLATE_ID: 106,
  // CSV出力テンプレートID
  SEND_CSV_TEMPLATE_ID: 110,

  // 表示項目生成テンプレートID
  OUTPUT_TEMPLATE_ID: 107,
};

// 発注実績画面
export const ORDER_HISTORY_TEMPLATE = {
  /* 一覧画面 */
  // 検索項目生成テンプレートID
  SEARCH_TEMPLATE_ID: 201,
  // 一覧項目テンプレートID
  SEARCH_RESULTS_TEMPLATE_ID: 202,
  // 一覧項目内部情報テンプレートID
  SEARCH_RESULTS_INFORMATION_TEMPLATE_ID: 203,

  // 一般ユーザ
  // 表示項目生成テンプレートID
  GENERAL_OUTPUT_TEMPLATE_ID: 211,
  // CSV出力テンプレートID
  GENERAL_CSV_TEMPLATE_ID: 212,

  // 管理・承認ユーザ
  // 検索項目生成テンプレートID
  MANAGEMENT_OUTPUT_TEMPLATE_ID: 204,
  // CSV出力テンプレートID
  MANAGEMENT_CSV_TEMPLATE_ID: 205,

  // 入力項目生成テンプレートID
  INPUT_TEMPLATE_ID: 208,
  // 入力項目生成テンプレートID(カテゴリ:出荷済み)
  SHIPPED_INPUT_TEMPLATE_ID: 209,

  /* 承認画面 */
  // カテゴリ:配送依頼
  // 入力項目生成テンプレートID
  APPROVAL_REQUEST_INPUT_TEMPLATE_ID: 206,
  // 表示項目生成テンプレートID
  APPROVAL_REQUEST_OUTPUT_TEMPLATE_ID: 207,
  // 金額情報(表示項目生成)テンプレートID
  MONEY_OUTPUT_TEMPLATE_ID: 213,
};

// 注文登録画面
export const ORDER_REGISTER_TEMPLATE = {
  // 検索項目生成テンプレートID
  SEARCH_TEMPLATE_ID: 101,
  // 一覧項目テンプレートID
  SEARCH_RESULTS_TEMPLATE_ID: 102,
  // 表示項目生成テンプレートID
  OUTPUT_TEMPLATE_ID: 103,
  // CSV出力テンプレートID
  CSV_TEMPLATE_ID: 104,
  // 入力項目生成テンプレートID
  INPUT_TEMPLATE_ID: 105,
};

// 発注画面
export const QUANTITY_TEMPLATE = {
  // 一覧項目テンプレートID
  SEARCH_RESULTS_TEMPLATE_ID: 115,
  // 一覧項目内部情報テンプレートID
  SEARCH_RESULTS_INFORMATION_TEMPLATE_ID: 116,
  // 入力項目生成テンプレートID
  INPUT_TEMPLATE_ID: 117,
  // 入力項目初期表示テンプレートID
  INPUT_DEFAULT_TEMPLATE_ID: 122,
};

/** --------------------------------------------------------- */

/**
 * 計画
 */
// 計画一覧画面
export const PROJECT_LIST_TEMPLATE = {
  // 一般ユーザ
  // 検索項目生成テンプレートID
  GENERAL_SEARCH_TEMPLATE_ID: 402,
  // 一覧項目テンプレートID
  GENERAL_SEARCH_RESULTS_TEMPLATE_ID: 404,
  // 表示項目生成テンプレートID
  GENERAL_OUTPUT_TEMPLATE_ID: 406,
  // CSV出力テンプレートID
  GENERAL_CSV_TEMPLATE_ID: 408,

  // 管理・承認ユーザ
  // 検索項目生成テンプレートID
  MANAGEMENT_SEARCH_TEMPLATE_ID: 401,
  // 一覧項目テンプレートID
  MANAGEMENT_SEARCH_RESULTS_TEMPLATE_ID: 403,
  // 表示項目生成テンプレートID
  MANAGEMENT_OUTPUT_TEMPLATE_ID: 405,
  // CSV出力テンプレートID
  MANAGEMENT_CSV_TEMPLATE_ID: 407,
  // 入力項目生成テンプレートID
  MANAGEMENT_INPUT_TEMPLATE_ID: 409,
  // 編集入力項目生成テンプレートID
  EDIT_INPUT_TEMPLATE_ID: 410,

  // 複製入力項目生成テンプレートID
  DUPLICATE_INPUT_TEMPLATE_ID: 413,

  // CSV/TSV出力(在庫メンテナンス)テンプレートID
  CSV_STOCK_MAINTENANCE: 905,
};

// 計画数確認画面
export const PROJECT_NUMBER_CONFIRMATION_TEMPLATE = {
  // 共通
  // 計画変更
  PROJECT_CHANGE_INPUT_TEMPLATE_ID: 551,
  // イベント一覧項目テンプレートID
  EVENT_SEARCH_RESULTS_TEMPLATE_ID: 552,
  // イベント表示項目生成テンプレートID
  EVENT_OUTPUT_TEMPLATE_ID: 553,

  // 一般ユーザ・承認ユーザ
  // 検索項目生成テンプレートID
  GENERAL_SEARCH_TEMPLATE_ID: 555,
  // 一覧項目テンプレートID
  GENERAL_SEARCH_RESULTS_TEMPLATE_ID: 557,
  // CSV出力テンプレートID
  GENERAL_CSV_TEMPLATE_ID: 557,

  // 管理
  // 検索項目生成テンプレートID
  MANAGEMENT_SEARCH_TEMPLATE_ID: 554,
  // 一覧項目テンプレートID
  MANAGEMENT_SEARCH_RESULTS_TEMPLATE_ID: 556,
  // CSV出力テンプレートID
  MANAGEMENT_CSV_TEMPLATE_ID: 556,

  // 表示項目生成テンプレートID
  OUTPUT_TEMPLATE_ID: 558,
};

// 計画数入力画面
export const PROJECT_NUMBER_INPUT_TEMPLATE = {
  // 一般ユーザ
  // 計画変更
  GENERAL_PROJECT_CHANGE_INPUT_TEMPLATE_ID: 502,

  // 管理
  // 計画変更
  MANAGEMENT_PROJECT_CHANGE_INPUT_TEMPLATE_ID: 501,

  // イベント一覧項目テンプレートID
  EVENT_SEARCH_RESULTS_TEMPLATE_ID: 503,
  // イベント表示項目生成テンプレートID
  EVENT_OUTPUT_TEMPLATE_ID: 504,

  // 検索項目生成テンプレートID
  SEARCH_TEMPLATE_ID: 505,
  // 一覧項目テンプレートID
  SEARCH_RESULTS_TEMPLATE_ID: 506,
  // 表示項目生成テンプレートID
  OUTPUT_TEMPLATE_ID: 507,
  // 入力項目生成テンプレートID
  INPUT_TEMPLATE_ID: 508,
  // 入力項目初期表示テンプレートID
  INPUT_DEFAULT_TEMPLATE_ID: 509,
};

/** --------------------------------------------------------- */

/**
 * サマリー
 */
//サマリー一覧画面
export const SUMMARY_LIST_TEMPLATE = {
  // 検索項目生成テンプレートID
  SEARCH_TEMPLATE_ID: 1101,
  // 一覧項目テンプレートID
  SEARCH_RESULTS_TEMPLATE_ID: 2102,
  // 一覧項目テンプレートID
  SEARCH_RESULTS_CONTENT_TEMPLATE_ID: 2103,

  // 表示項目生成テンプレートID
  OUTPUT_TEMPLATE_ID: 1103,
  // CSV出力テンプレートID
  CSV_TEMPLATE_ID: 1104,
  // 新規入力項目生成テンプレートID
  NEW_INPUT_TEMPLATE_ID: 1105,
  // 編集入力項目生成テンプレートID
  EDIT_INPUT_TEMPLATE_ID: 1106,
  // 複製入力項目生成テンプレートID
  DUPLICATE_INPUT_TEMPLATE_ID: 1107,
};

// サマリー詳細画面
// TODO テンプレート仮置き
export const SUMMARY_DISPLAY_TEMPLATE = {
  // サマリー目次テンプレートID
  SUMMARY_TITLE_DISPLAY_TEMPLATE_ID: 9901,
  // サマリー表示項目生成テンプレートID
  SUMMARY_OUTPUT_TEMPLATE_ID: 9902,

  // サマリーコンテンツ目次テンプレートID
  SUMMARY_CONTENT_TITLE_DISPLAY_TEMPLATE_ID: 1902,
  // サマリーコンテンツ表示項目生成テンプレートID
  SUMMARY_CONTENT_OUTPUT_TEMPLATE_ID: 1903,

  // サマリーコンテンツ新規入力項目生成テンプレートID
  SUMMARY_CONTENT_NEW_INPUT_TEMPLATE_ID: 1905,
  // サマリーコンテンツ編集入力項目生成テンプレートID
  SUMMARY_CONTENT_EDIT_INPUT_TEMPLATE_ID: 1906,
  // サマリー回覧新規入力項目生成テンプレートID
  SUMMARY_CIRCULATION_NEW_INPUT_TEMPLATE_ID: 2005,
  // サマリー回覧更新入力項目生成テンプレートID
  SUMMARY_CIRCULATION_EDIT_INPUT_TEMPLATE_ID: 2006,
};

// サマリー回覧画面
export const SUMMARY_CIRCULATION_TEMPLATE = {
  // サマリーコンテンツ表示項目生成テンプレートID
  SUMMARY_CONTENT_OUTPUT_TEMPLATE_ID: 1903,

  // ファイル情報表示項目生成テンプレートID
  SUMMARY_CONTENT_FILE_OUTPUT_TEMPLATE_ID: 2601,

  // サマリー回覧新規表示項目生成テンプレートID
  SUMMARY_CIRCULATION_OUTPUT_TEMPLATE_ID: 2003,
  // 回答者追加入力項目生成テンプレートID
  ANSWER_MEMBER_INPUT_TEMPLATE_ID: 2205,
  // 回答入力項目生成テンプレートID
  ANSWER_INPUT_TEMPLATE_ID: 2208,
  // 回覧終了入力項目生成テンプレートID
  CIRCULATION_END_INPUT_TEMPLATE_ID: 2209,
};

/** --------------------------------------------------------- */

/**
 * 回覧
 */
// 回覧グループマスタ画面
export const CIRCULATION_GROUP_TEMPLATE = {
  // 検索項目生成テンプレートID
  SEARCH_TEMPLATE_ID: 2301,
  // 一覧項目テンプレートID
  SEARCH_RESULTS_TEMPLATE_ID: 2302,
  // 一覧子項目テンプレートID
  SEARCH_RESULTS_CHILD_TEMPLATE_ID: 2308,
  // 表示項目生成テンプレートID
  OUTPUT_TEMPLATE_ID: 2303,
  // CSV出力テンプレートID
  CSV_TEMPLATE_ID: 2302,
  // 新規入力項目生成テンプレートID
  NEW_INPUT_TEMPLATE_ID: 2305,
  // 編集入力項目生成テンプレートID
  EDIT_INPUT_TEMPLATE_ID: 2306,
  // 複製入力項目生成テンプレートID
  DUPLICATE_INPUT_TEMPLATE_ID: 2307,
};

// 回覧ルートマスタ画面
export const CIRCULATION_ROOT_TEMPLATE = {
  // 検索項目生成テンプレートID
  SEARCH_TEMPLATE_ID: 2401,
  // 一覧項目テンプレートID
  SEARCH_RESULTS_TEMPLATE_ID: 2502,
  // 一覧子項目テンプレートID
  SEARCH_RESULTS_CHILD_TEMPLATE_ID: 2503,
  // 表示項目生成テンプレートID
  OUTPUT_TEMPLATE_ID: 2403,
  // CSV出力テンプレートID
  CSV_TEMPLATE_ID: 2403,
  // 新規入力項目生成テンプレートID
  NEW_INPUT_TEMPLATE_ID: 2405,
  // 編集入力項目生成テンプレートID
  EDIT_INPUT_TEMPLATE_ID: 2406,
  // 複製入力項目生成テンプレートID
  DUPLICATE_INPUT_TEMPLATE_ID: 2407,
};

/** --------------------------------------------------------- */

// My回覧一覧
export const GET_MYCIRCULATION_TEMPLATE = {
  // My回覧テンプレートID
  GET_MYCIRCULATION_TEMPLATE_ID: 2008,
};

/** --------------------------------------------------------- */

/**
 * 事務局
 */
// 情報出力画面
export const SECRETARIAT_OUTPUT_TEMPLATE = {
  // 一覧項目テンプレートID
  SEARCH_RESULTS_TEMPLATE_ID: 901,
  // ファイル出力用テンプレートID
  SEARCH_RESULTS_FILE_TEMPLATE_ID: 902,
  // CSV/TSV出力(注文情報)テンプレートID
  CSV_ORDER_TEMPLATE_ID: 904,
  // CSV/TSV出力(注文情報:送込)テンプレートID
  CSV_SEND_ORDER_TEMPLATE_ID: 904,
  // CSV/TSV出力(在庫メンテナンス)テンプレートID
  CSV_STOCK_MAINTENANCE: 905,
};

// 発送ステータスファイル登録画面
export const REGISTER_SHIPPING_RESULT_TEMPLATE = {
  // 確認画面ヘッダーテンプレートID
  CONFIRMATION_HEADER_TEMPLATE_ID: 701,
};
