import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  API_URL_DELIVERY,
  API_URL_DEPARTMENT,
  API_URL_ITEM,
  API_URL_ORDER,
  API_URL_USER,
} from 'manager/http-constants_key';
import {
  DELIVERY_TEMPLATE,
  DEPARTMENT_TEMPLATE,
  ITEM_TEMPLATE,
  ORDER_HISTORY_TEMPLATE,
  USER_TEMPLATE,
} from 'manager/template-constant';
import { combineLatest, Subject } from 'rxjs';
import { CONSTANT, DATE_FORMAT } from 'src/app/shared/constant/constant';
import { ExportFileService } from 'src/app/shared/html-parts/export-file/export-file.service';
import { DELIVERY_CONSTANT } from '../master/delivery/constant';
import { DEPARTMENT_CONSTANT } from '../master/department/constant';
import { ITEM_CONSTANT } from '../master/item/constant';
import { USER_CONSTANT } from '../master/user/constant';
import { ORDER_PERFORMANCE_CONSTANT } from '../order/order-history/constant';

@Component({
  selector: 'app-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss'],
})

/**
 * リンク画面
 */
export class LinkComponent implements OnInit {
  constructor(
    public datePipe: DatePipe,
    private exportFileService: ExportFileService
  ) {}

  ngOnInit(): void {}

  /**
   * ファイル出力ボタン出力表示判定
   * @returns true:ボタン表示、false:ボタン非表示
   */
  public exportButtonDisplayFlag(): boolean {
    return true;
  }

  /**
   * CSV出力(一括)
   */
  protected exportAllCSV() {
    // csvファイル名の設定
    const fileName =
      '一括CSVファイル' +
      CONSTANT.UNDERBAR +
      this.datePipe.transform(new Date(), DATE_FORMAT.DATETIME);

    // CSVファイル出力
    // 非同期同時実行リスト
    const task: Subject<any>[] = [
      /* CSV出力(アイテム) */
      this.exportFileService.exportTemplateCsv(
        ITEM_CONSTANT.CSV_FILENAME +
          CONSTANT.UNDERBAR +
          this.datePipe.transform(new Date(), DATE_FORMAT.DATETIME_HYPHEN),
        null,
        API_URL_ITEM,
        ITEM_TEMPLATE.CSV_TEMPLATE_ID,
        null,
        false,
        true
      ),
      /* CSV出力(届先) */
      this.exportFileService.exportTemplateCsv(
        DELIVERY_CONSTANT.CSV_FILENAME +
          CONSTANT.UNDERBAR +
          this.datePipe.transform(new Date(), DATE_FORMAT.DATETIME_HYPHEN),
        DELIVERY_CONSTANT.TARGET_TABLE,
        API_URL_DELIVERY,
        DELIVERY_TEMPLATE.GENERAL_CSV_TEMPLATE_ID,
        null,
        false,
        true
      ),
      /* CSV出力(注文履歴) */
      this.exportFileService.exportTemplateCsv(
        ORDER_PERFORMANCE_CONSTANT.CSV_FILENAME +
          CONSTANT.UNDERBAR +
          this.datePipe.transform(new Date(), DATE_FORMAT.DATETIME_HYPHEN),
        ORDER_PERFORMANCE_CONSTANT.TARGET_TABLE,
        API_URL_ORDER,
        ORDER_HISTORY_TEMPLATE.GENERAL_CSV_TEMPLATE_ID,
        null,
        false,
        true
      ),
      /* CSV出力(組織) */
      this.exportFileService.exportTemplateCsv(
        DEPARTMENT_CONSTANT.CSV_FILENAME +
          CONSTANT.UNDERBAR +
          this.datePipe.transform(new Date(), DATE_FORMAT.DATETIME_HYPHEN),
        null,
        API_URL_DEPARTMENT,
        DEPARTMENT_TEMPLATE.CSV_TEMPLATE_ID,
        null,
        false,
        true
      ),
      /* CSV出力(ユーザ) */
      this.exportFileService.exportTemplateCsv(
        USER_CONSTANT.CSV_FILENAME +
          CONSTANT.UNDERBAR +
          this.datePipe.transform(new Date(), DATE_FORMAT.DATETIME_HYPHEN),
        null,
        API_URL_USER,
        USER_TEMPLATE.CSV_TEMPLATE_ID,
        null,
        false,
        true
      ),
    ];
    // 非同期同時実行
    combineLatest(task).subscribe((fileInformationList) => {
      // ZIPファイルダウンロード
      this.exportFileService.zipFileDownload(
        fileName,
        fileInformationList[0],
        fileInformationList[1],
        fileInformationList[2],
        fileInformationList[3],
        fileInformationList[4]
      );
    });
  }

  /**
   * CSV出力(アイテム)
   */
  protected exportItemCSV() {
    // csvファイル名の設定
    const fileName =
      ITEM_CONSTANT.CSV_FILENAME +
      CONSTANT.UNDERBAR +
      this.datePipe.transform(new Date(), DATE_FORMAT.DATETIME_HYPHEN);

    // CSVファイル出力
    this.exportFileService.exportTemplateCsv(
      fileName,
      null,
      API_URL_ITEM,
      ITEM_TEMPLATE.CSV_TEMPLATE_ID
    );
  }

  /**
   * CSV出力(届先)
   */
  protected exportDeliveryCSV() {
    // csvファイル名の設定
    const fileName =
      DELIVERY_CONSTANT.CSV_FILENAME +
      CONSTANT.UNDERBAR +
      this.datePipe.transform(new Date(), DATE_FORMAT.DATETIME_HYPHEN);

    // CSVファイル出力
    this.exportFileService.exportTemplateCsv(
      fileName,
      DELIVERY_CONSTANT.TARGET_TABLE,
      API_URL_DELIVERY,
      DELIVERY_TEMPLATE.GENERAL_CSV_TEMPLATE_ID
    );
  }

  /**
   * CSV出力(注文履歴)
   */
  protected exportOrderHistoryCSV() {
    // csvファイル名の設定
    const fileName =
      ORDER_PERFORMANCE_CONSTANT.CSV_FILENAME +
      CONSTANT.UNDERBAR +
      this.datePipe.transform(new Date(), DATE_FORMAT.DATETIME_HYPHEN);

    // CSVファイル出力
    this.exportFileService.exportTemplateCsv(
      fileName,
      ORDER_PERFORMANCE_CONSTANT.TARGET_TABLE,
      API_URL_ORDER,
      ORDER_HISTORY_TEMPLATE.GENERAL_CSV_TEMPLATE_ID
    );
  }

  /**
   * CSV出力(組織)
   */
  protected exportDepartmentCSV() {
    // csvファイル名の設定
    const fileName =
      DEPARTMENT_CONSTANT.CSV_FILENAME +
      CONSTANT.UNDERBAR +
      this.datePipe.transform(new Date(), DATE_FORMAT.DATETIME_HYPHEN);

    // CSVファイル出力
    this.exportFileService.exportTemplateCsv(
      fileName,
      null,
      API_URL_DEPARTMENT,
      DEPARTMENT_TEMPLATE.CSV_TEMPLATE_ID
    );
  }

  /**
   * CSV出力(ユーザ)
   */
  protected exportUserCSV() {
    // csvファイル名の設定
    const fileName =
      USER_CONSTANT.CSV_FILENAME +
      CONSTANT.UNDERBAR +
      this.datePipe.transform(new Date(), DATE_FORMAT.DATETIME_HYPHEN);

    // CSVファイル出力
    this.exportFileService.exportTemplateCsv(
      fileName,
      null,
      API_URL_USER,
      USER_TEMPLATE.CSV_TEMPLATE_ID
    );
  }
}
