import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

/** モジュール */
// ルーティング
import { PagesRoutingModule } from './pages-routing.module';

// 共通
import { SharedModule } from 'src/app//shared/shared.module';
import { LibraryModule } from 'src/app/library/library.module';

/** コンポーネント */
// TOP
import { TopComponent } from './top/top.component';
import { NoticeComponent } from './top/notice/notice.component';
import { NoticeListComponent } from './top/notice/notice-list/notice-list.component';
import { MyCirculationlistComponent } from './top/mycirculation-list/mycirculation-list.component';

// MASTER
import { BizdayComponent } from './master/bizday/bizday.component';
import { CustomerComponent } from './master/customer/customer.component';
import { DeliveryComponent } from './master/delivery/delivery.component';
import { DeliveryGroupComponent } from './master/delivery-group/delivery-group.component';
import { DepartmentComponent } from './master/department/department.component';
import { InformationComponent } from './master/information/information.component';
import { ItemComponent } from './master/item/item.component';
import { ItemDetailComponent } from './master/item/item-detail/item-detail.component';
import { StockComponent } from './master/item/stock/stock.component';
import { ItemMediationComponent } from './master/item-mediation/item-mediation.component';
import { UserComponent } from './master/user/user.component';

// STOCK
import { ProductionInstructionComponent } from './stock/production-instruction/production-instruction.component';
import { RegisterStockMaintenanceComponent } from './stock/register-stock-maintenance/register-stock-maintenance.component';
import { StockMaintenanceComponent } from './stock/stock-maintenance/stock-maintenance.component';

// ORDER
import { BreadcrumbComponent } from './order/breadcrumb/breadcrumb.component';
import { CartComponent } from './order/cart/cart.component';
import { CartDeliveryComponent } from './order/cart/cart-delivery/cart-delivery.component';
import { CartItemComponent } from './order/cart/cart-item/cart-item.component';
import { DeliverySearchComponent } from './order/delivery-search/delivery-search.component';
import { ItemSearchComponent } from './order/item-search/item-search.component';
import { OrderRegisterComponent } from './order/order-register/order-register.component';
import { QuantityComponent } from './order/quantity/quantity.component';
import { OrderHistoryComponent } from './order/order-history/order-history.component';
import { UpdateStatusComponent } from './order/order-history/update-status/update-status.component';

// SECRETARIAT
import { RegisterShippingResultComponent } from './secretariat/register-shipping-result/register-shipping-result.component';
import { OutputComponent } from './secretariat/output/output.component';

// PROJECT
import { ProjectListComponent } from './project/project-list/project-list.component';
import { ProjectNumberConfirmationComponent } from './project/project-number-confirmation/project-number-confirmation.component';
import { ProjectNumberInputComponent } from './project/project-number-input/project-number-input.component';
import { ProjectManagementDetailComponent } from './project/project-list/project-management-detail/project-management-detail.component';

// SUMMARY
import { SummaryComponent } from './summary/summary-list/summary-list.component';
import { SummaryDisplayComponent } from './summary/summary-display/summary-display.component';
import { SummaryContentDisplayComponent } from './summary/summary-display/summary-content-display.component';
import { SummaryDisplayDetailComponent } from './summary/summary-display/summary-display-detail/summary-display-detail.component';
import { SummaryDisplayInputComponent } from './summary/summary-display/summary-display-input/summary-display-input.component';
import { SummaryContentDisplayInputComponent } from './summary/summary-display/summary-content-display-input/summary-content-display-input.component';
import { SummaryCirculationComponent } from './summary/summary-circulation/summary-circulation.component';
import { SummaryCirculationAnswerComponent } from './summary/summary-circulation/summary-circulation-answer/summary-circulation-answer.component';
import { SummaryCirculationEndComponent } from './summary/summary-circulation/summary-circulation-end/summary-circulation-end.component';
import { AddAnswerMemberComponent } from './summary/summary-circulation/add-answer-member/add-answer-member.component';

// CIRCULATION
import { CirculationGroupComponent } from './circulation/circulation-group/circulation-group.component';
import { AddCirculationGroupMemberComponent } from './circulation/circulation-group/add-circulation-group-member/add-circulation-group-member.component';
import { CirculationRootComponent } from './circulation/circulation-root/circulation-root.component';
import { AddCirculationRootComponent } from './circulation/circulation-root/add-circulation-root/add-circulation-root.component';

// REPORT
import { CostDetailsReportComponent } from './report/cost-details-report/cost-details-report.component';
import { InventoryReportComponent } from './report/inventory-report/inventory-report.component';

// LINK
import { LinkComponent } from './link/link.component';

// MESSAGE
import { MessageComponent } from './message/message.component';

@NgModule({
  declarations: [
    // TOP
    TopComponent,
    NoticeComponent,
    NoticeListComponent,
    MyCirculationlistComponent,

    // MASTER
    BizdayComponent,
    CustomerComponent,
    DeliveryComponent,
    DeliveryGroupComponent,
    DepartmentComponent,
    InformationComponent,
    ItemComponent,
    ItemDetailComponent,
    StockComponent,
    ItemMediationComponent,
    UserComponent,

    // STOCK
    ProductionInstructionComponent,
    RegisterStockMaintenanceComponent,
    StockMaintenanceComponent,

    // ORDER
    BreadcrumbComponent,
    CartComponent,
    CartDeliveryComponent,
    CartItemComponent,
    DeliverySearchComponent,
    ItemSearchComponent,
    OrderRegisterComponent,
    QuantityComponent,
    OrderHistoryComponent,
    UpdateStatusComponent,

    // SECRETARIAT
    RegisterShippingResultComponent,
    OutputComponent,

    // PROJECT
    ProjectListComponent,
    ProjectNumberConfirmationComponent,
    ProjectNumberInputComponent,
    ProjectManagementDetailComponent,

    // SUMMARY
    SummaryComponent,
    SummaryDisplayComponent,
    SummaryContentDisplayComponent,
    SummaryDisplayDetailComponent,
    SummaryDisplayInputComponent,
    SummaryContentDisplayInputComponent,
    SummaryCirculationComponent,
    SummaryCirculationAnswerComponent,
    SummaryCirculationEndComponent,
    AddAnswerMemberComponent,

    // CIRCULATION
    CirculationGroupComponent,
    AddCirculationGroupMemberComponent,
    CirculationRootComponent,
    AddCirculationRootComponent,

    // REPORT
    CostDetailsReportComponent,
    InventoryReportComponent,

    // LINK
    LinkComponent,

    // MESSAGE
    MessageComponent,
  ],
  imports: [CommonModule, PagesRoutingModule, SharedModule, LibraryModule],
})
export class PagesModule {}
