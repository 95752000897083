import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { DbOperationService } from 'src/app/shared/service/db-operation.service';
import {
  API_URL_ORDER_ITEM,
  API_URL_SEARCH_ORDER_ITEM,
  API_URL_WORK_ORDER,
  API_URL_WORK_ORDER_ITEM,
} from 'manager/http-constants_key';
import { Router } from '@angular/router';
import { GenerateDisplayComponent } from 'src/app/shared/generate/generate-display/generate-display.component';
import { GenerateDisplay } from 'src/app/shared/generate/generate-display/generate-display-information/generate-display-information';
import { ITEM_SEARCH_TEMPLATE } from 'manager/template-constant';
import { CommonService } from 'src/app/shared/service/common.service';
import { CONSTANT, DATE_FORMAT } from 'src/app/shared/constant/constant';
import { DatePipe } from '@angular/common';
import { ITEM_SEARCH_CONSTANT } from './constant';
import {
  MessageData,
  ToastMessageData,
} from 'src/app/shared/html-parts/message-common/message-data';
import {
  LoadData,
  LoadingState,
} from 'src/app/shared/html-parts/loading/loading-state';
import { MESSAGE_CODE } from 'src/app/shared/constant/message-constant';
import { TOAST } from 'src/app/shared/constant/primeng-constants';
import { ExportFileService } from 'src/app/shared/html-parts/export-file/export-file.service';
import { Table } from 'primeng/table';

@Component({
  selector: 'app-item-search',
  templateUrl: './item-search.component.html',
  styleUrls: ['../order.component.scss', './item-search.component.scss'],
})

/**
 * 発注販促資材画面
 */
export class ItemSearchComponent implements OnInit {
  /** 詳細画面 */
  @ViewChild(GenerateDisplayComponent)
  generateDisplayItemsComponent: GenerateDisplayComponent;

  /** テーブル状態 */
  @ViewChild('table') table: Table;

  // 検索項目生成エンドポイント
  public searchEndPoint: string = API_URL_SEARCH_ORDER_ITEM;

  // 検索項目生成テンプレートID
  searchTemplateId: number = ITEM_SEARCH_TEMPLATE.NORMAL_SEARCH_TEMPLATE_ID;

  /* 画面用プロパティ */
  // 画面ヘッダー情報格納先
  columnOrder: any[] = new Array();

  // 画面検索結果一覧格納先
  searchResultsList: any[] = new Array();

  // カート選択リスト格納先
  cartSelected: any[] = new Array();

  // 検索条件保持
  generateSearchItems: Object;

  constructor(
    private dbOperationService: DbOperationService,
    private messageData: MessageData,
    private router: Router,
    private commonService: CommonService,
    public datePipe: DatePipe,
    private loadingState: LoadingState,
    private exportFileService: ExportFileService
  ) {}

  ngOnInit() {
    // 画面ロードフラグをON(ロード中状態)
    this.loadingState.loadStart();

    /* ヘッダー情報取得処理(画面用) */
    this.dbOperationService
      .getHeaderList(ITEM_SEARCH_TEMPLATE.NORMAL_SEARCH_RESULTS_TEMPLATE_ID)
      .subscribe((response) => {
        this.columnOrder = response.body;
      });

    // 検索処理を実施
    this.searchResult();
  }

  /**
   * 検索処理
   * @param generateSearchItems 検索条件
   */
  public searchResult(generateSearchItems?: Object) {
    // カート選択リストを初期化
    this.cartSelected = new Array();

    // 画面カスタムロードフラグをON(ロード中状態)
    this.loadingState.customLoadStart(
      new LoadData({
        loadingText: this.commonService.msg(MESSAGE_CODE.S00003),
      })
    );

    // CSV出力用に検索条件を保持
    this.generateSearchItems = generateSearchItems;

    /* 販促資材マスタ一覧取得処理(画面用) */
    this.dbOperationService
      .getData(
        API_URL_ORDER_ITEM,
        ITEM_SEARCH_TEMPLATE.NORMAL_SEARCH_RESULTS_TEMPLATE_ID,
        generateSearchItems
      )
      .subscribe((response) => {
        // コード値の一覧情報が存在するか否か
        if (!this.commonService.checkNoneResponse(response)) {
          // 販促資材マスタ一覧情報のJSONをオブジェクトに格納する
          this.searchResultsList = response.body;
        } else {
          this.searchResultsList = new Array();
        }

        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadSleepEnd(0.3);
      });

    // テーブル状態が存在するか否か
    if (this.table) {
      // テーブル状態が存在する場合

      // テーブル状態をリセット
      this.table.reset();
    }
  }

  /**
   * 詳細情報画面表示
   * @param pkeyId IDコード(クリックされたID)
   */
  protected detail(pkeyId: string) {
    // 詳細画面表示
    let generateDisplay: GenerateDisplay = new GenerateDisplay();
    generateDisplay.endPoint = API_URL_ORDER_ITEM;
    generateDisplay.templateId = ITEM_SEARCH_TEMPLATE.OUTPUT_TEMPLATE_ID;
    this.generateDisplayItemsComponent.initial(generateDisplay, pkeyId);
  }

  /**
   * カート追加
   */
  public addCart() {
    // カートが選択されているか否か
    if (!this.cartSelected.length) {
      // カートが選択されていない場合

      // 警告メッセージ
      this.messageData.toastMessage(
        new ToastMessageData({
          severity: TOAST.WARN,
          summary: this.commonService.msg(MESSAGE_CODE.E00015),
          detail: this.commonService.msg(MESSAGE_CODE.E00016, '資材'),
        })
      );

      return;
    }

    // 画面カスタムロードフラグをON(ロード中状態)
    this.loadingState.customLoadStart(
      new LoadData({
        loadingText: this.commonService.msg(MESSAGE_CODE.I00012),
      })
    );

    // カート選択リスト格納先から資材コードのみを取得する
    const code = this.commonService.createArrayGetArrayObject(
      this.cartSelected,
      this.commonService.getPkeyColumn(this.columnOrder)
    );

    // カート登録
    this.dbOperationService
      .insertData(API_URL_WORK_ORDER_ITEM, {
        item_code: code.join(CONSTANT.COMMA),
        // TODO 森永用APIで必須の為、暫定対応
        category1: 1,
      })
      .subscribe((response) => {
        // レスポンスを判定
        if (
          this.messageData.responseToastMessage(
            response,
            this.commonService.msg(MESSAGE_CODE.I00014),
            this.commonService.msg(MESSAGE_CODE.I00015, '資材')
          )
        ) {
          // レスポンスが正常終了の場合

          // 注文情報(WORK)登録
          this.dbOperationService
            .insertData(API_URL_WORK_ORDER, {
              // TODO 森永用APIで必須の為、暫定対応
              category1: 1,
            })
            .subscribe((response) => {
              // 画面ロードフラグをOFF(ロード終了)
              this.loadingState.loadEnd();

              // レスポンスを判定
              if (this.commonService.checkRunningNormallyResponse(response)) {
                // レスポンスが正常終了の場合

                // 発注管理_数量へ遷移
                this.router.navigate(['pages/order/quantity']);
              } else {
                // 発注管理_届先選択へ遷移
                this.router.navigate(['pages/order/delivery-search']);
              }
            });
        }
      });
  }

  /**
   * CSV出力
   */
  protected exportCSV() {
    // csvファイル名の設定
    const fileName =
      ITEM_SEARCH_CONSTANT.CSV_FILENAME +
      CONSTANT.UNDERBAR +
      this.datePipe.transform(new Date(), DATE_FORMAT.DATETIME_HYPHEN);

    // CSVファイル出力
    this.exportFileService.exportTemplateCsv(
      fileName,
      null,
      API_URL_ORDER_ITEM,
      ITEM_SEARCH_TEMPLATE.NORMAL_CSV_TEMPLATE_ID,
      this.generateSearchItems
    );
  }

  /**
   * プライマリーカラムを取得
   */
  protected getPkeyColumn(): string {
    return this.commonService.getPkeyColumn(this.columnOrder);
  }

  /**
   * Newマーク表示
   * @param rowData 行データ
   * @returns true:Newマーク表示、false:Newマーク非表示
   */
  protected outputNewMark(rowData: any): boolean {
    // システム更新日付が取得できない場合
    if (!rowData.update_datetime) {
      return false;
    }

    // 1週間前(現在時間から7日前を取得)
    const nowDate = this.datePipe.transform(
      new Date().setDate(new Date().getDate() - 7),
      DATE_FORMAT.DATE
    );

    // システム更新日付が1週間前より前か否か
    if (
      nowDate >=
      this.datePipe.transform(rowData.update_datetime, DATE_FORMAT.DATE)
    ) {
      // システム更新日付が1週間前より後の場合

      return false;
    }

    return true;
  }

  /**
   * 画面スクロール実行時に実行
   */
  @HostListener('window:scroll', ['$event'])
  protected onScroll(event) {
    // スクロール位置の高さが200以上の場合
    if (window.scrollY > 200) {
      // "スクロールカート追加ボタン"を表示
      document.getElementById('addCartButton').classList.add('show');
    } else {
      // "スクロールカート追加ボタン"を非表示
      document.getElementById('addCartButton').classList.remove('show');
    }
  }
}
