/**
 * API URL取得用キー
 */

/**
 * 共通
 */
// ログインユーザ情報取得
export const USER_AUTHINFOS = 'USER_AUTHINFOS';
// ヘッダー項目生成
export const HEADER_LIST = 'HEADER_LIST';
// バリデーションチェック
export const API_URL_VALIDATION_RESULT = 'API_URL_VALIDATION_RESULT';
// バリデーションチェック(複数)
export const API_URL_MULTIVALIDATION_RESULT = 'API_URL_MULTIVALIDATION_RESULT';
// 画像登録
export const API_URL_UPLOAD_IMAGE = 'API_URL_UPLOAD_IMAGE';
// ファイル登録
export const API_URL_UPLOAD_CSV = 'API_URL_UPLOAD_CSV';
// ファイル情報登録
export const API_URL_REGIST_DATA = 'API_URL_REGIST_DATA';
// ブロック実行必要回数の取得
export const API_URL_RUN_COUNT = 'API_URL_RUN_COUNT';
// 辞書値取得
export const API_URL_DICVALUES = 'API_URL_DICVALUES';
// 全辞書値取得
export const API_URL_DIC_LISTS = 'API_URL_DIC_LISTS';
// 情報出力
// TODO 現在は注文情報(ORDER)のみ対応
export const API_URL_OUTPUT = 'API_URL_OUTPUT';

/**
 * マスタ画面
 */
// お知らせマスタ管理／確認(GET)
export const API_URL_INFORMATIONS2 = 'API_URL_INFORMATIONS2';
// お知らせマスタ管理／REST API(GET:一覧/GET:詳細/POST:登録/PUT:更新/DELETE:削除)
export const API_URL_INFORMATION = 'API_URL_INFORMATION';
// お知らせマスタ管理／検索画面(GET)
export const API_URL_SEARCH_INFORMATION = 'API_URL_SEARCH_INFORMATION';
// お知らせマスタ管理／登録画面(GET)
export const API_URL_INPUT_INFORMATION = 'API_URL_INPUT_INFORMATION';

// ユーザマスタ管理／REST API(GET:一覧/GET:詳細/POST:登録/PUT:更新/DELETE:削除)
export const API_URL_USER = 'API_URL_USER';
// ユーザマスタ管理／検索画面(GET)
export const API_URL_SEARCH_USER = 'API_URL_SEARCH_USER';
// ユーザマスタ管理／登録画面(GET)
export const API_URL_INPUT_USER = 'API_URL_INPUT_USER';

// 営業日マスタ管理／REST API(GET:一覧/GET:詳細/POST:登録/PUT:更新/DELETE:削除)
export const API_URL_BIZDAY = 'API_URL_BIZDAY';
// 営業日マスタ管理／検索画面(GET)
export const API_URL_SEARCH_BIZDAY = 'API_URL_SEARCH_BIZDAY';
// 営業日マスタ管理／登録画面(GET)
export const API_URL_INPUT_BIZDAY = 'API_URL_INPUT_BIZDAY';

// 組織マスタ管理／REST API(GET:一覧/GET:詳細/POST:登録/PUT:更新/DELETE:削除)
export const API_URL_DEPARTMENT = 'API_URL_DEPARTMENT';
// 組織マスタ管理／検索画面(GET)
export const API_URL_SEARCH_DEPARTMENT = 'API_URL_SEARCH_DEPARTMENT';
// 組織マスタ管理／登録画面(GET)
export const API_URL_INPUT_DEPARTMENT = 'API_URL_INPUT_DEPARTMENT';

// 在庫調整(POST:入庫登録/PUT:在庫調整)
export const API_URL_STOCK = 'API_URL_STOCK';

// 届先マスタ管理／REST API(GET:一覧/GET:詳細/POST:登録/PUT:更新/DELETE:削除)
export const API_URL_DELIVERY = 'API_URL_DELIVERY';
// 届先マスタ管理／検索画面(GET)
export const API_URL_SEARCH_DELIVERY = 'API_URL_SEARCH_DELIVERY';
// 届先マスタ管理／登録画面(GET)
export const API_URL_INPUT_DELIVERY = 'API_URL_INPUT_DELIVERY';

// 販促資材マスタ管理／REST API(GET:一覧/GET:詳細/POST:登録/PUT:更新/DELETE:削除)
export const API_URL_ITEM = 'API_URL_ITEM';
// 販促資材マスタ管理／検索画面(GET)
export const API_URL_SEARCH_ITEM = 'API_URL_SEARCH_ITEM';
// 販促資材マスタ管理／登録画面(GET)
export const API_URL_INPUT_ITEM = 'API_URL_INPUT_ITEM';

// 販促資材斡旋マスタ管理／REST API(GET:一覧/GET:詳細/POST:登録/PUT:更新/DELETE:削除)
export const API_URL_ITEM_MEDIATION = 'API_URL_ITEM_MEDIATION';
// 販促資材斡旋マスタ管理／検索画面(GET)
export const API_URL_SEARCH_ITEM_MEDIATION = 'API_URL_SEARCH_ITEM_MEDIATION';
// 販促資材斡旋マスタ管理／登録画面(GET)
export const API_URL_INPUT_ITEM_MEDIATION = 'API_URL_INPUT_ITEM_MEDIATION';

// 得意先マスタ管理／REST API(GET:一覧/GET:詳細/POST:登録/PUT:更新/DELETE:削除)
export const API_URL_CUSTOMER = 'API_URL_CUSTOMER';
// 得意先マスタ管理／検索画面(GET)
export const API_URL_SEARCH_CUSTOMER = 'API_URL_SEARCH_CUSTOMER';
// 得意先マスタ管理／登録画面(GET)
export const API_URL_INPUT_CUSTOMER = 'API_URL_INPUT_CUSTOMER';

// 届先グループマスタ管理／REST API(GET:一覧/GET:詳細/POST:登録/PUT:更新/DELETE:削除)
export const API_URL_DELIVERY_GROUP = 'API_URL_DELIVERY_GROUP';
// 届先グループマスタ管理／検索画面(GET)
export const API_URL_SEARCH_DELIVERY_GROUP = 'API_URL_SEARCH_DELIVERY_GROUP';
// 届先グループマスタ管理／登録画面(GET)
export const API_URL_INPUT_DELIVERY_GROUP = 'API_URL_INPUT_DELIVERY_GROUP';

/**
 * 注文画面
 */
// 注文情報／REST API(GET:一覧/GET:詳細/POST:登録)
export const API_URL_ORDER = 'API_URL_ORDER';
// 注文情報／検索画面(GET)
export const API_URL_SEARCH_ORDER = 'API_URL_SEARCH_ORDER';
// 注文情報／登録画面(GET)
export const API_URL_INPUT_ORDER = 'API_URL_INPUT_ORDER';
// 注文情報／詳細（発注者情報）(GET)
export const API_URL_USER_DEPARTMENT = 'API_URL_USER_DEPARTMENT';

// 発注管理／REST API(GET:詳細/POST:登録/DELETE:削除)
export const API_URL_WORK_ORDER = 'API_URL_WORK_ORDER';
// 発注管理／更新(PUT)
export const API_URL_ORDER_STATUS = 'API_URL_ORDER_STATUS';
// 発注管理／更新(PUT)
export const API_URL_ORDER_CANCEL = 'API_URL_ORDER_CANCEL';
// 発注管理／登録画面(GET)
export const API_URL_INPUT_WORK_ORDER = 'API_URL_INPUT_WORK_ORDER';
// 発注管理／登録画面(GET)
export const API_URL_INPUT_ORDER_ITEM = 'API_URL_INPUT_ORDER_ITEM';

// 発注管理_届先／REST API(GET:一覧/GET:詳細)
export const API_URL_ORDER_DELIVERIE = 'API_URL_ORDER_DELIVERIE';
// 発注管理_届先／検索画面(GET)
export const API_URL_SEARCH_ORDER_DELIVERIE = 'API_URL_SEARCH_ORDER_DELIVERIE';
// 発注管理_届先／REST API(GET:一覧/POST:登録/DELETE:削除)
export const API_URL_WORK_ORDER_DELIVERIE = 'API_URL_WORK_ORDER_DELIVERIE';

// 発注管理_販促資材／REST API(GET:一覧)
export const API_URL_WORK_ORDER_ITEM_STOCK_INFO =
  'API_URL_WORK_ORDER_ITEM_STOCK_INFO';
// 発注管理_販促資材／REST API(GET:一覧/GET:詳細)
export const API_URL_ORDER_ITEM = 'API_URL_ORDER_ITEM';
// 発注管理_販促資材／検索画面(GET)
export const API_URL_SEARCH_ORDER_ITEM = 'API_URL_SEARCH_ORDER_ITEM';
// 発注管理_販促資材／REST API(POST:登録/DELETE:削除)
export const API_URL_WORK_ORDER_ITEM = 'API_URL_WORK_ORDER_ITEM';
// 発注管理／注文関連WORK削除(DELETE)
export const API_URL_SUB_WORK_ORDERS = 'API_URL_SUB_WORK_ORDERS';

// 注文情報/一括承認(PUT)
export const API_URL_APPROVAL_ORDER = 'API_URL_APPROVAL_ORDER';

/**
 * 在庫メンテナンス画面
 */
// 在庫メンテナンス管理／REST API(GET:一覧/GET:詳細/POST:登録/PUT:更新/DELETE:削除)
export const API_URL_STOCK_MAINTENANCE = 'API_URL_STOCK_MAINTENANCE';
// 在庫メンテナンス管理／検索画面(GET)
export const API_URL_SEARCH_STOCK_MAINTENANCE =
  'API_URL_SEARCH_STOCK_MAINTENANCE';
// 在庫メンテナンス管理／登録画面(GET)
export const API_URL_INPUT_STOCK_MAINTENANCE =
  'API_URL_INPUT_STOCK_MAINTENANCE';
// 在庫メンテナンス管理／更新(PUT)
export const API_URL_STOCK_MAINTENANCES_CANCEL =
  'API_URL_STOCK_MAINTENANCES_CANCEL';

// 製作指示／REST API(GET:一覧/GET:詳細)
export const API_URL_STOCK_PLANS = 'API_URL_STOCK_PLANS';
// 製作指示／検索画面(GET)
export const API_URL_SEARCH_STOCK_PLANS = 'API_URL_SEARCH_STOCK_PLANS';
// 製作指示／登録画面(GET)
export const API_URL_INPUT_STOCK_PLANS = 'API_URL_INPUT_STOCK_PLANS';
// 製作指示／製作指示登録(入庫予定登録)
export const API_URL_STOCK_PLAN = 'API_URL_STOCK_PLAN';

/**
 * 計画情報画面
 */
// 計画情報一覧／REST API(GET:一覧/GET:詳細/POST:登録/PUT:更新/DELETE:削除)
export const API_URL_EVENT = 'API_URL_EVENT';
// 計画情報一覧／検索画面(GET)
export const API_URL_SEARCH_EVENT = 'API_URL_SEARCH_EVENT';
// 計画情報一覧／登録画面(GET)
export const API_URL_INPUT_EVENT = 'API_URL_INPUT_EVENT';
// 計画情報／更新(PUT)
export const API_URL_EVENTPLANINFODELIVERYGROUPS_STATUS =
  'API_URL_EVENTPLANINFODELIVERYGROUPS_STATUS';

// 計画数入力・確認／イベント名プルダウン(GET)
export const API_URL_QUANTITY_PLAN_EVENTS = 'API_URL_QUANTITY_PLAN_EVENTS';

// 計画数入力／REST API(GET:一覧/GET:詳細/POST:登録)
export const API_URL_QUANTITY_PLANS = 'API_URL_QUANTITY_PLANS';
// 計画数入力／検索画面(GET)
export const API_URL_SEARCH_QUANTITY_PLANS = 'API_URL_SEARCH_QUANTITY_PLANS';
// 計画数入力／登録画面(GET)
export const API_URL_INPUT_QUANTITY_PLANS = 'API_URL_INPUT_QUANTITY_PLANS';

// 計画数確認／REST API(GET:一覧/GET:詳細)
export const API_URL_EVENT_PLAN_INFO_DELIVERY_GROUPS =
  'API_URL_EVENT_PLAN_INFO_DELIVERY_GROUPS';
// 計画数確認／検索画面(GET)
export const API_URL_SEARCH_EVENT_PLAN_INFO_DELIVERY_GROUPS =
  'API_URL_SEARCH_EVENT_PLAN_INFO_DELIVERY_GROUPS';

/**
 * 帳票画面
 */
// 棚卸帳票／検索画面(GET)
export const API_URL_SEARCH_INVENTORY_SUMMARYS =
  'API_URL_SEARCH_INVENTORY_SUMMARYS';
// 棚卸帳票／IMD棚卸集計表作成・ダウンロード(GET)
export const API_URL_INVENTORY_SUMMARYS = 'API_URL_INVENTORY_SUMMARYS';
// IMD費用明細帳票／検索画面(GET)
export const API_URL_SEARCH_COST_SUMMARYS = 'API_URL_SEARCH_COST_SUMMARYS';
// IMD費用明細帳票／IMD費用明細作成・ダウンロード(GET)
export const API_URL_COST_SUMMARYS = 'API_URL_COST_SUMMARYS';

/**
 * サマリー画面
 */
// コンテンツ／REST API(GET:詳細/POST:登録/PUT:更新/DELETE:削除)
export const API_URL_CONTENTS = 'API_URL_CONTENTS';
// コンテンツ／登録画面(GET)
export const API_URL_INPUT_CONTENTS = 'API_URL_INPUT_CONTENTS';
// コンテンツリスト／コンテンツリスト情報取得(GET)
export const API_URL_CONTENTS_LIST = 'API_URL_CONTENTS_LIST';
// 回覧／REST API(GET:詳細/POST:登録/PUT:更新/DELETE:削除)
export const API_URL_CIRCULATIONS = 'API_URL_CIRCULATIONS';
// 回覧／登録画面(GET)
export const API_URL_INPUT_CIRCULATIONS = 'API_URL_INPUT_CIRCULATIONS';
// 回覧リスト／回覧リスト情報取得(GET)
export const API_URL_CIRCULATIONS_LIST = 'API_URL_CIRCULATIONS_LIST';
// 回覧（開始条件判定）(GET)
export const API_URL_CIRCULATIONS_IS_STARTABLE =
  'API_URL_CIRCULATIONS_IS_STARTABLE';
// メンバー／REST API(POST:登録)
export const API_URL_MEMBERS = 'API_URL_MEMBERS';
// メンバー／登録画面(GET)
export const API_URL_INPUT_MEMBERS = 'API_URL_INPUT_MEMBERS';
// メンバー／回答登録(PUT)
export const API_URL_MEMBERS_ANSWER = 'API_URL_MEMBERS_ANSWER';
// メンバー／メンバーリスト情報取得(GET)
export const API_URL_MEMBERS_LIST = 'API_URL_MEMBERS_LIST';
// ファイル情報連携／REST API(GET:詳細)
export const API_URL_FILES = 'API_URL_FILES';
// AIステータスチェック(GET)
export const API_URL_AI_COOPERATION_STATUS = 'API_URL_AI_COOPERATION_STATUS';
// AIファイルアップロード(PUT)
export const API_URL_AI_COOPERATION_FILE_UPLOAD =
  'API_URL_AI_COOPERATION_FILE_UPLOAD';
// 回覧／ファイルID取得(GET)
export const API_URL_CIRCULATIONS_FILEID = 'API_URL_CIRCULATIONS_FILEID';
// 商品管理（承認者取得）(GET)
export const API_URL_ITEMS_APPROVER = 'API_URL_ITEMS_APPROVER';
// 商品管理（グループメンバー取得）(GET)
export const API_URL_ITEMS_GROUP_MEMBER = 'API_URL_ITEMS_GROUP_MEMBER';

/**
 * 回覧画面
 */
// 回覧グループマスタ管理／REST API(GET:一覧/GET:詳細/POST:登録/PUT:更新/DELETE:削除)
export const API_URL_GROUPS = 'API_URL_GROUPS';
// 回覧グループマスタ管理_拡張一覧
export const API_URL_GROUP_LISTS = 'API_URL_GROUP_LISTS';
// 回覧グループマスタ管理／検索画面(GET)
export const API_URL_SEARCH_GROUPS = 'API_URL_SEARCH_GROUPS';
// 回覧グループマスタ管理／登録画面(GET)
export const API_URL_INPUT_GROUPS = 'API_URL_INPUT_GROUPS';
// 回覧グループマスタ管理／回覧グループメンバー（ユーザ情報取得）(GET)
export const API_URL_GROUP_MEMBERS_GET_USER_INFO =
  'API_URL_GROUP_MEMBERS_GET_USER_INFO';
// 回覧グループメンバー管理／REST API(GET:詳細/POST:登録)
export const API_URL_GROUP_MEMBERS = 'API_URL_GROUP_MEMBERS';
// 回覧グループマスタ管理／回覧グループメンバー（入力チェック）(POST)
export const API_URL_GROUP_MEMBERS_CHECK_INPUT =
  'API_URL_GROUP_MEMBERS_CHECK_INPUT';

// 回覧ルート管理／REST API(GET:一覧/GET:詳細/POST:登録/PUT:更新/DELETE:削除)
export const API_URL_ROUTES = 'API_URL_ROUTES';
// 回覧ルート管理_拡張一覧
export const API_URL_ROUTE_GROUP_LISTS = 'API_URL_ROUTE_GROUP_LISTS';
// 回覧ルート管理／検索画面(GET)
export const API_URL_SEARCH_ROUTES = 'API_URL_SEARCH_ROUTES';
// 回覧ルート管理／登録画面(GET)
export const API_URL_INPUT_ROUTES = 'API_URL_INPUT_ROUTES';
// 回覧ルート管理／全回覧ルート(GET)
export const API_URL_ROUTE_GROUPS_GET_GROUP_INFO =
  'API_URL_ROUTE_GROUPS_GET_GROUP_INFO';
// 回覧ルート管理／REST API(GET:詳細/POST:登録)
export const API_URL_ROUTE_GROUPS = 'API_URL_ROUTE_GROUPS';
// 回覧ルート管理／回覧ルート（入力チェック）(POST)
export const API_URL_ROUTE_GROUPS_CHECK_INPUT =
  'API_URL_ROUTE_GROUPS_CHECK_INPUT';

/**
 * 業務バリデーションチェック
 */
// 注文情報履歴／編集時業務チェック(POST)
export const API_URL_CHECK_EDIT_ORDER_RESULT =
  'API_URL_CHECK_EDIT_ORDER_RESULT';

/**
 * サマリー
 */
// 販促資材マスタ管理／REST API(GET:一覧/GET:詳細/POST:登録/PUT:更新/DELETE:削除)
export const API_URL_SUMMARYLIST = 'API_URL_SUMMARYLIST';

/**
 * My回覧
 */
// トップ画面／REST API(GET:一覧)
export const API_URL_MYCIRCULATION = 'API_URL_MYCIRCULATION';
