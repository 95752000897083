<!-- メイン領域-->
<div class="main-container">
  <!-- メインヘッダー-->
  <div class="header p-d-flex">
    <!-- 戻るボタン-->
    <button
      type="button"
      pButton
      icon="pi pi-angle-left"
      label="戻る"
      (click)="return()"
      class="p-mr-2 summaryCirculationDefaultButton"
    ></button>
    <!-- 回答ボタン-->
    <button
      type="button"
      pButton
      label="回答"
      (click)="circulationAnswer()"
      class="p-mr-2 p-button-success summaryCirculationDefaultButton"
      *ngIf="answerButtonDisplayFlag()"
    ></button>
    <!-- 回覧終了ボタン-->
    <button
      type="button"
      pButton
      label="回覧終了"
      (click)="circulationEnd()"
      class="p-mr-2 p-button-warning summaryCirculationDefaultButton"
      *ngIf="endSummaryCirculationDisplayFlag()"
    ></button>
    <!-- 回覧更新ボタン -->
    <button
      type="button"
      pButton
      label="回覧更新"
      (click)="updateSummaryCirculation()"
      class="p-mr-2 summaryCirculationDefaultButton"
      *ngIf="updateSummaryCirculationDisplayFlag()"
    ></button>
  </div>
  <!-- サマリー回覧-->
  <div class="circulation">
    <!-- 詳細情報-->
    <div class="display">
      <app-generate-display-information></app-generate-display-information>

      <!-- AIファイル連携 -->
      <div *ngIf="fileInformationList.length">
        <!-- AIファイル連携ボタン -->
        <button
          type="button"
          pButton
          label="AIファイル連携"
          (click)="uploadAiFile()"
          class="p-mr-2 p-button-danger summaryCirculationDefaultButton"
          *ngIf="uploadAiFileDisplayFlag()"
        ></button>

        <!-- AIステータスチェックボタン -->
        <!-- ファイル未連携または校閲完了時は不活性 -->
        <button
          type="button"
          pButton
          (click)="checkAiFile()"
          label="ステータス更新"
          class="p-mr-2 p-button-danger summaryCirculationDefaultButton"
          [disabled]="fileInformationList[0].data === '' || fileInformationList[0].data_multi[0] ==='2'"
          *ngIf="checkAiFileDisplayFlag()"
        ></button>

        <!-- ファイル情報出力領域 -->
        <p-table
          [value]="fileInformationList"
          [loading]="fileInformationloading"
          styleClass="p-datatable-sm"
          *ngIf="fileInformationDisplayFlag()"
        >
          <!-- ファイル情報タイトル -->
          <ng-template pTemplate="header">
            <tr class="p-col-2">
              <th colspan="2">ファイル情報</th>
            </tr>
          </ng-template>

          <!-- ファイル情報出力箇所 -->
          <ng-template pTemplate="body" let-fileInformation>
            <tr>
              <!-- カラム名称 -->
              <td class="table-body-label">
                {{ fileInformation.column_name }}
              </td>

              <!-- カラム判定 -->
              <td
                class="table-body-data"
                [ngSwitch]="fileInformation?.input_type"
              >
                <span *ngSwitchCase="'URL'">
                  <!-- URLの場合 -->
                  <a [href]="fileInformation.data.url" target="_blank">
                    {{ fileInformation.data.name }}
                  </a>
                </span>
                <span *ngSwitchDefault>
                  <!-- 上記以外の場合の場合 -->
                  {{ fileInformation.data }}
                </span>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>

    <!-- 回覧状況-->
    <div class="circulation-status">
      <div class="p-d-flex p-jc-between circulationStatusHeader">
        <div class="p-as-center"><label class="label">回覧状況</label></div>
        <div>
          <!-- 回答者追加ボタン -->
          <button
            type="button"
            pButton
            label="回答者追加"
            (click)="addAnswerMember()"
            class="p-mr-2 summaryCirculationDefaultButton"
            *ngIf="addAnswerMemberDisplayFlag()"
          ></button>
          <!-- 画面更新ボタン-->
          <button
            type="button"
            pButton
            icon="pi pi-refresh"
            (click)="ngOnInit()"
            class="p-mr-2 p-button-info refresh"
          ></button>
        </div>
      </div>

      <!-- 回覧状況表示領域-->
      <p-table
        #table
        [value]="circulationStatusList"
        [paginator]="true"
        [rows]="3"
        [showCurrentPageReport]="true"
        [loading]="circulationStatusloading"
        currentPageReportTemplate="{first}~{last}部門表示({totalRecords}部門中)"
        styleClass="circulation-status-table"
      >
        <!-- 回覧状況ヘッダー表示-->
        <ng-template pTemplate="header">
          <tr class="p-col-2">
            <th>回答者</th>
            <th>回答状況</th>
            <th>回答日</th>
            <th>コメント</th>
          </tr>
        </ng-template>

        <!-- 回覧状況ボディ表示-->
        <ng-template pTemplate="body" let-circulationStatus>
          <tr class="circulationDepartment">
            <!-- 部門 -->
            <td colspan="4">
              <i class="pi pi-tablet"></i>
              {{ circulationStatus.department_name }}
            </td>
          </tr>
          <!-- メンバー -->
          <ng-container *ngFor="let member of circulationStatus.member">
            <tr>
              <!-- 回答者 -->
              <td>
                <i class="pi pi-user"></i>
                {{ member.user_name }}
              </td>
              <!-- 回答状況 -->
              <td>
                <ng-container
                  *ngFor="
                    let dicValues of dicValuesList;
                    index as dicValueIndex
                  "
                >
                  <label
                    [class.answerlabel-ok]="
                      dicValues.sp_code == member.answer &&
                      dicValues.sp_code == '1'
                    "
                    [class.answerlabel-ng]="
                      dicValues.sp_code == member.answer &&
                      dicValues.sp_code == '2'
                    "
                    [class.answerlabel-other]="
                      dicValues.sp_code == member.answer &&
                      dicValues.sp_code == '3'
                    "
                  >
                    {{ dicValues.sp_name }}
                  </label>

                  <label *ngIf="dicValueIndex + 1 != dicValuesList.length"
                    >／</label
                  >
                </ng-container>
              </td>
              <!-- 回答日 -->
              <td>
                {{ member.answer_date }}
              </td>
              <!-- コメント -->
              <td>
                {{ member.comment }}
              </td>
            </tr>
          </ng-container>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>

<!-- サマリー回覧.回答画面ダイアログ-->
<app-summary-circulation-answer
  (reloadID)="getCirculationStatus()"
></app-summary-circulation-answer>

<!-- サマリー回覧.回覧終了画面ダイアログ-->
<app-summary-circulation-end
  (reloadID)="getSummaryCirculatiDisplay()"
></app-summary-circulation-end>

<!-- サマリー回覧.回答者登録画面ダイアログ-->
<app-add-answer-member
  (reloadID)="getCirculationStatus()"
></app-add-answer-member>

<!-- サマリー詳細.入力画面 -->
<!-- 回覧更新時に使用 -->
<app-summary-content-display-input
  (reloadID)="getSummaryCirculatiDisplay()"
></app-summary-content-display-input>
