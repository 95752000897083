export const CIRCULATION_ROOT_CONSTANT = {
  // csvファイル名
  CSV_FILENAME: 'circulation-root',
  // 対象テーブル
  TARGET_TABLE: 'customer',
  // グループIDカラム
  GROUP_ID: 'group_id',
  // グループ名
  GROUP_NAME: 'group_name',
};
