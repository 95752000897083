import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  API_URL_AI_COOPERATION_STATUS,
  API_URL_AI_COOPERATION_FILE_UPLOAD,
  API_URL_CIRCULATIONS_IS_STARTABLE,
  API_URL_MEMBERS_LIST,
  API_URL_CIRCULATIONS_FILEID,
  API_URL_ITEMS_GROUP_MEMBER,
} from 'manager/http-constants_key';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CommonService } from 'src/app/shared/service/common.service';

@Injectable({
  providedIn: 'root',
})

/**
 * サマリー回覧サービス
 */
export class SummaryCirculationService {
  constructor(private http: HttpClient, private commonService: CommonService) {}

  /**
   * 回覧（開始条件判定）取得
   * @param circulationId 回覧ID
   */
  public getcirculationsIsStartable(circulationId: string): Observable<any> {
    // LambdaのEndpointを生成
    const url = this.commonService.url(
      API_URL_CIRCULATIONS_IS_STARTABLE,
      circulationId
    );

    // 回覧（開始条件判定）結果を返却する
    return this.http
      .get(url, { observe: 'response' })
      .pipe(map((res: HttpResponse<any>) => res));
  }

  /**
   * メンバーリスト取得
   * @param circulationId 回覧ID
   */
  public getMembersList(circulationId: string): Observable<any> {
    // LambdaのEndpointを生成
    const url = this.commonService.url(
      API_URL_MEMBERS_LIST,
      '?circulation_id=',
      circulationId
    );

    // コンテンツリストを返却する
    return this.http
      .get(url, { observe: 'response' })
      .pipe(map((res: HttpResponse<any>) => res));
  }

  /**
   * AIファイルアップロード
   * @param fileId ファイルID
   */
  public uploadAiFile(fileId: string): Observable<any> {
    // LambdaのEndpointを生成
    const url = this.commonService.url(
      API_URL_AI_COOPERATION_FILE_UPLOAD,
      fileId
    );

    // AIファイルアップロード結果を返却する
    return this.http
      .get(url, { observe: 'response' })
      .pipe(map((res: HttpResponse<any>) => res));
  }

  /**
   * AIステータスチェック
   * @param fileId ファイルID
   */
  public checkAiStatus(fileId: string): Observable<any> {
    // LambdaのEndpointを生成
    const url = this.commonService.url(API_URL_AI_COOPERATION_STATUS, fileId);

    // AIステータスチェック結果を返却する
    return this.http
      .get(url, { observe: 'response' })
      .pipe(map((res: HttpResponse<any>) => res));
  }

  /**
   * 回覧（ファイルID）取得
   * @param circulationId 回覧ID
   */
  public getFileId(circulationId: string): Observable<any> {
    // LambdaのEndpointを生成
    const url = this.commonService.url(
      API_URL_CIRCULATIONS_FILEID,
      circulationId
    );

    // 回覧（ファイルID）結果を返却する
    return this.http
      .get(url, { observe: 'response' })
      .pipe(map((res: HttpResponse<any>) => res));
  }

  /**
   * 回覧のグループメンバー取得
   * @param itemId 案件ID
   * @param circulationId 回覧ID
   */
  public getGroupMember(
    itemId: string,
    circulationId: string
  ): Observable<any> {
    // LambdaのEndpointを生成
    const url = this.commonService.url(
      API_URL_ITEMS_GROUP_MEMBER,
      itemId,
      '?circulation_id=',
      circulationId,
      '&role=',
      '1,2,3'
    );

    // 回覧のグループメンバーリストを返却する
    return this.http
      .get(url, { observe: 'response' })
      .pipe(map((res: HttpResponse<any>) => res));
  }
}
