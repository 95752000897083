/**
 * API URL(開発用)
 */

/**
 * 共通
 */
// ログインユーザ情報取得
export const USER_AUTHINFOS =
  'https://63p8453s40.execute-api.ap-northeast-1.amazonaws.com/dev/userauthinfos/';
// ヘッダー項目生成
export const HEADER_LIST =
  'https://00bsv67uv1.execute-api.ap-northeast-1.amazonaws.com/dev/headerlist/';
// バリデーションチェック
export const API_URL_VALIDATION_RESULT =
  'https://63p8453s40.execute-api.ap-northeast-1.amazonaws.com/dev/validationresult/';
// バリデーションチェック(複数)
export const API_URL_MULTIVALIDATION_RESULT =
  'https://63p8453s40.execute-api.ap-northeast-1.amazonaws.com/dev/multivalidationresult/';
// 画像登録
export const API_URL_UPLOAD_IMAGE =
  'https://63p8453s40.execute-api.ap-northeast-1.amazonaws.com/dev/uploadimage/';
// ファイル登録
export const API_URL_UPLOAD_CSV =
  'https://63p8453s40.execute-api.ap-northeast-1.amazonaws.com/dev/uploadcsv/';
// ファイル情報登録
export const API_URL_REGIST_DATA =
  'https://63p8453s40.execute-api.ap-northeast-1.amazonaws.com/dev/registdata/';
// ブロック実行必要回数の取得
export const API_URL_RUN_COUNT =
  'https://1a9ysdigng.execute-api.ap-northeast-1.amazonaws.com/dev/runcount/';
// 辞書値取得
export const API_URL_DICVALUES =
  'https://63p8453s40.execute-api.ap-northeast-1.amazonaws.com/dev/dicvalues/';
// 全辞書値取得
export const API_URL_DIC_LISTS =
  'https://63p8453s40.execute-api.ap-northeast-1.amazonaws.com/dev/diclists/';
// 情報出力
// TODO 現在は注文情報(ORDER)のみ対応
export const API_URL_OUTPUT =
  'https://63p8453s40.execute-api.ap-northeast-1.amazonaws.com/dev/output/';

/**
 * マスタ画面
 */
// お知らせマスタ管理／確認(GET)
export const API_URL_INFORMATIONS2 =
  'https://e3p9lq9bqe.execute-api.ap-northeast-1.amazonaws.com/dev/informations2';
// お知らせマスタ管理／REST API(GET:一覧/GET:詳細/POST:登録/PUT:更新/DELETE:削除)
export const API_URL_INFORMATION =
  'https://e3p9lq9bqe.execute-api.ap-northeast-1.amazonaws.com/dev/informations/';
// お知らせマスタ管理／検索画面(GET)
export const API_URL_SEARCH_INFORMATION =
  'https://e3p9lq9bqe.execute-api.ap-northeast-1.amazonaws.com/dev/informations/search/';
// お知らせマスタ管理／登録画面(GET)
export const API_URL_INPUT_INFORMATION =
  'https://e3p9lq9bqe.execute-api.ap-northeast-1.amazonaws.com/dev/informations/input/';

// ユーザマスタ管理／REST API(GET:一覧/GET:詳細/POST:登録/PUT:更新/DELETE:削除)
export const API_URL_USER =
  'https://e3p9lq9bqe.execute-api.ap-northeast-1.amazonaws.com/dev/users/';
// ユーザマスタ管理／検索画面(GET)
export const API_URL_SEARCH_USER =
  'https://e3p9lq9bqe.execute-api.ap-northeast-1.amazonaws.com/dev/users/search/';
// ユーザマスタ管理／登録画面(GET)
export const API_URL_INPUT_USER =
  'https://e3p9lq9bqe.execute-api.ap-northeast-1.amazonaws.com/dev/users/input/';

// 営業日マスタ管理／REST API(GET:一覧/GET:詳細/POST:登録/PUT:更新/DELETE:削除)
export const API_URL_BIZDAY =
  'https://d0wjs9ytal.execute-api.ap-northeast-1.amazonaws.com/dev/bizdays/';
// 営業日マスタ管理／検索画面(GET)
export const API_URL_SEARCH_BIZDAY =
  'https://d0wjs9ytal.execute-api.ap-northeast-1.amazonaws.com/dev/bizdays/search/';
// 営業日マスタ管理／登録画面(GET)
export const API_URL_INPUT_BIZDAY =
  'https://d0wjs9ytal.execute-api.ap-northeast-1.amazonaws.com/dev/bizdays/input/';

// 組織マスタ管理／REST API(GET:一覧/GET:詳細/POST:登録/PUT:更新/DELETE:削除)
export const API_URL_DEPARTMENT =
  'https://e3p9lq9bqe.execute-api.ap-northeast-1.amazonaws.com/dev/departments/';
// 組織マスタ管理／検索画面(GET)
export const API_URL_SEARCH_DEPARTMENT =
  'https://e3p9lq9bqe.execute-api.ap-northeast-1.amazonaws.com/dev/departments/search/';
// 組織マスタ管理／登録画面(GET)
export const API_URL_INPUT_DEPARTMENT =
  'https://e3p9lq9bqe.execute-api.ap-northeast-1.amazonaws.com/dev/departments/input/';

// 在庫調整(POST:入庫登録/PUT:在庫調整)
export const API_URL_STOCK =
  'https://sspo5kz2r8.execute-api.ap-northeast-1.amazonaws.com/dev/stock/';

// 届先マスタ管理／REST API(GET:一覧/GET:詳細/POST:登録/PUT:更新/DELETE:削除)
export const API_URL_DELIVERY =
  'https://d0wjs9ytal.execute-api.ap-northeast-1.amazonaws.com/dev/deliveries/';
// 届先マスタ管理／検索画面(GET)
export const API_URL_SEARCH_DELIVERY =
  'https://d0wjs9ytal.execute-api.ap-northeast-1.amazonaws.com/dev/deliveries/search/';
// 届先マスタ管理／登録画面(GET)
export const API_URL_INPUT_DELIVERY =
  'https://d0wjs9ytal.execute-api.ap-northeast-1.amazonaws.com/dev/deliveries/input/';

// 販促資材マスタ管理／REST API(GET:一覧/GET:詳細/POST:登録/PUT:更新/DELETE:削除)
export const API_URL_ITEM =
  'https://00bsv67uv1.execute-api.ap-northeast-1.amazonaws.com/dev/items/';
// 販促資材マスタ管理／検索画面(GET)
export const API_URL_SEARCH_ITEM =
  'https://00bsv67uv1.execute-api.ap-northeast-1.amazonaws.com/dev/items/search/';
// 販促資材マスタ管理／登録画面(GET)
export const API_URL_INPUT_ITEM =
  'https://00bsv67uv1.execute-api.ap-northeast-1.amazonaws.com/dev/items/input/';

// 販促資材斡旋マスタ管理／REST API(GET:一覧/GET:詳細/POST:登録/PUT:更新/DELETE:削除)
export const API_URL_ITEM_MEDIATION =
  'https://z57p3ieoqa.execute-api.ap-northeast-1.amazonaws.com/dev/itemmediations/';
// 販促資材斡旋マスタ管理／検索画面(GET)
export const API_URL_SEARCH_ITEM_MEDIATION =
  'https://z57p3ieoqa.execute-api.ap-northeast-1.amazonaws.com/dev/itemmediations/search/';
// 販促資材斡旋マスタ管理／登録画面(GET)
export const API_URL_INPUT_ITEM_MEDIATION =
  'https://z57p3ieoqa.execute-api.ap-northeast-1.amazonaws.com/dev/itemmediations/input/';

// 得意先マスタ管理／REST API(GET:一覧/GET:詳細/POST:登録/PUT:更新/DELETE:削除)
export const API_URL_CUSTOMER =
  'https://z57p3ieoqa.execute-api.ap-northeast-1.amazonaws.com/dev/customers/';
// 得意先マスタ管理／検索画面(GET)
export const API_URL_SEARCH_CUSTOMER =
  'https://z57p3ieoqa.execute-api.ap-northeast-1.amazonaws.com/dev/customers/search/';
// 得意先マスタ管理／登録画面(GET)
export const API_URL_INPUT_CUSTOMER =
  'https://z57p3ieoqa.execute-api.ap-northeast-1.amazonaws.com/dev/customers/input/';

// 届先グループマスタ管理／REST API(GET:一覧/GET:詳細/POST:登録/PUT:更新/DELETE:削除)
export const API_URL_DELIVERY_GROUP =
  'https://z57p3ieoqa.execute-api.ap-northeast-1.amazonaws.com/dev/deliverygroups/';
// 届先グループマスタ管理／検索画面(GET)
export const API_URL_SEARCH_DELIVERY_GROUP =
  'https://z57p3ieoqa.execute-api.ap-northeast-1.amazonaws.com/dev/deliverygroups/search/';
// 届先グループマスタ管理／登録画面(GET)
export const API_URL_INPUT_DELIVERY_GROUP =
  'https://z57p3ieoqa.execute-api.ap-northeast-1.amazonaws.com/dev/deliverygroups/input/';

/**
 * 注文画面
 */
// 注文情報／REST API(GET:一覧/GET:詳細/POST:登録)
export const API_URL_ORDER =
  'https://pbmte74119.execute-api.ap-northeast-1.amazonaws.com/dev/orders/';
// 注文情報／検索画面(GET)
export const API_URL_SEARCH_ORDER =
  'https://pbmte74119.execute-api.ap-northeast-1.amazonaws.com/dev/orders/search/';
// 注文情報／登録画面(GET)
export const API_URL_INPUT_ORDER =
  'https://pbmte74119.execute-api.ap-northeast-1.amazonaws.com/dev/orders/input/';
// 注文情報／詳細（発注者情報）(GET)
export const API_URL_USER_DEPARTMENT =
  'https://pbmte74119.execute-api.ap-northeast-1.amazonaws.com/dev/userdepartments/';

// 発注管理／REST API(GET:詳細/POST:登録/DELETE:削除)
export const API_URL_WORK_ORDER =
  'https://pbmte74119.execute-api.ap-northeast-1.amazonaws.com/dev/workorders/';
// 発注管理／更新(PUT)
export const API_URL_ORDER_STATUS =
  'https://pbmte74119.execute-api.ap-northeast-1.amazonaws.com/dev/orders/{0}/status';
// 発注管理／更新(PUT)
export const API_URL_ORDER_CANCEL =
  'https://pbmte74119.execute-api.ap-northeast-1.amazonaws.com/dev/orders/{0}/cancel/';
// 発注管理／登録画面(GET)
export const API_URL_INPUT_WORK_ORDER =
  'https://pbmte74119.execute-api.ap-northeast-1.amazonaws.com/dev/workorders/input/';
// 発注管理／登録画面(GET)
export const API_URL_INPUT_ORDER_ITEM =
  'https://pbmte74119.execute-api.ap-northeast-1.amazonaws.com/dev/orderitems/input/';

// 発注管理_届先／REST API(GET:一覧/GET:詳細)
export const API_URL_ORDER_DELIVERIE =
  'https://pbmte74119.execute-api.ap-northeast-1.amazonaws.com/dev/orderdeliveries/';
// 発注管理_届先／検索画面(GET)
export const API_URL_SEARCH_ORDER_DELIVERIE =
  'https://pbmte74119.execute-api.ap-northeast-1.amazonaws.com/dev/orderdeliveries/search/';
// 発注管理_届先／REST API(GET:一覧/POST:登録/DELETE:削除)
export const API_URL_WORK_ORDER_DELIVERIE =
  'https://pbmte74119.execute-api.ap-northeast-1.amazonaws.com/dev/workorderdeliveries/';

// 発注管理_販促資材／REST API(GET:一覧)
export const API_URL_WORK_ORDER_ITEM_STOCK_INFO =
  'https://pbmte74119.execute-api.ap-northeast-1.amazonaws.com/dev/workitemstockinfo/';
// 発注管理_販促資材／REST API(GET:一覧/GET:詳細)
export const API_URL_ORDER_ITEM =
  'https://pbmte74119.execute-api.ap-northeast-1.amazonaws.com/dev/orderitems/';
// 発注管理_販促資材／検索画面(GET)
export const API_URL_SEARCH_ORDER_ITEM =
  'https://pbmte74119.execute-api.ap-northeast-1.amazonaws.com/dev/orderitems/search/';
// 発注管理_販促資材／REST API(POST:登録/DELETE:削除)
export const API_URL_WORK_ORDER_ITEM =
  'https://pbmte74119.execute-api.ap-northeast-1.amazonaws.com/dev/workorderitems/';
// 発注管理／注文関連WORK削除(DELETE)
export const API_URL_SUB_WORK_ORDERS =
  'https://pbmte74119.execute-api.ap-northeast-1.amazonaws.com/dev/subworkorders/';
// 注文情報/一括承認(PUT)
export const API_URL_APPROVAL_ORDER =
  'https://pbmte74119.execute-api.ap-northeast-1.amazonaws.com/dev/orders/bulk/approval/';

/**
 * 在庫メンテナンス画面
 */
// 在庫メンテナンス管理／REST API(GET:一覧/GET:詳細/POST:登録/PUT:更新/DELETE:削除)
export const API_URL_STOCK_MAINTENANCE =
  'https://e3p9lq9bqe.execute-api.ap-northeast-1.amazonaws.com/dev/stockmaintenances/';
// 在庫メンテナンス管理／検索画面(GET)
export const API_URL_SEARCH_STOCK_MAINTENANCE =
  'https://e3p9lq9bqe.execute-api.ap-northeast-1.amazonaws.com/dev/stockmaintenances/search/';
// 在庫メンテナンス管理／登録画面(GET)
export const API_URL_INPUT_STOCK_MAINTENANCE =
  'https://e3p9lq9bqe.execute-api.ap-northeast-1.amazonaws.com/dev/stockmaintenances/input/';
// 在庫メンテナンス管理／更新(PUT)
export const API_URL_STOCK_MAINTENANCES_CANCEL =
  'https://e3p9lq9bqe.execute-api.ap-northeast-1.amazonaws.com/dev/stockmaintenances/{0}/cancel/';

// 製作指示／REST API(GET:一覧/GET:詳細)
export const API_URL_STOCK_PLANS =
  'https://sspo5kz2r8.execute-api.ap-northeast-1.amazonaws.com/dev/stockplans/';
// 製作指示／検索画面(GET)
export const API_URL_SEARCH_STOCK_PLANS =
  'https://sspo5kz2r8.execute-api.ap-northeast-1.amazonaws.com/dev/stockplans/search/';
// 製作指示／登録画面(GET)
export const API_URL_INPUT_STOCK_PLANS =
  'https://sspo5kz2r8.execute-api.ap-northeast-1.amazonaws.com/dev/stockplans/input/';
// 製作指示／製作指示登録(入庫予定登録)
export const API_URL_STOCK_PLAN =
  'https://sspo5kz2r8.execute-api.ap-northeast-1.amazonaws.com/dev/stockplan/';

/**
 * 計画情報画面
 */
// 計画情報一覧／REST API(GET:一覧/GET:詳細/POST:登録/PUT:更新/DELETE:削除)
export const API_URL_EVENT =
  'https://4dyil0mf6e.execute-api.ap-northeast-1.amazonaws.com/dev/events/';
// 計画情報一覧／検索画面(GET)
export const API_URL_SEARCH_EVENT =
  'https://4dyil0mf6e.execute-api.ap-northeast-1.amazonaws.com/dev/events/search/';
// 計画情報一覧／登録画面(GET)
export const API_URL_INPUT_EVENT =
  'https://4dyil0mf6e.execute-api.ap-northeast-1.amazonaws.com/dev/events/input/';
// 計画情報／更新(PUT)
export const API_URL_EVENTPLANINFODELIVERYGROUPS_STATUS =
  'https://g2jqp83gx8.execute-api.ap-northeast-1.amazonaws.com/dev/eventplaninfodeliverygroups/{0}/status';

// 計画数入力・確認／イベント名プルダウン(GET)
export const API_URL_QUANTITY_PLAN_EVENTS =
  'https://g2jqp83gx8.execute-api.ap-northeast-1.amazonaws.com/dev/inputquantityplanevents/';

// 計画数入力／REST API(GET:一覧/GET:詳細/POST:登録)
export const API_URL_QUANTITY_PLANS =
  'https://g2jqp83gx8.execute-api.ap-northeast-1.amazonaws.com/dev/quantityplans/';
// 計画数入力／検索画面(GET)
export const API_URL_SEARCH_QUANTITY_PLANS =
  'https://g2jqp83gx8.execute-api.ap-northeast-1.amazonaws.com/dev/quantityplans/search/';
// 計画数入力／登録画面(GET)
export const API_URL_INPUT_QUANTITY_PLANS =
  'https://g2jqp83gx8.execute-api.ap-northeast-1.amazonaws.com/dev/quantityplans/input/';

// 計画数確認／REST API(GET:一覧/GET:詳細)
export const API_URL_EVENT_PLAN_INFO_DELIVERY_GROUPS =
  'https://g2jqp83gx8.execute-api.ap-northeast-1.amazonaws.com/dev/eventplaninfodeliverygroups/';
// 計画数確認／検索画面(GET)
export const API_URL_SEARCH_EVENT_PLAN_INFO_DELIVERY_GROUPS =
  'https://g2jqp83gx8.execute-api.ap-northeast-1.amazonaws.com/dev/eventplaninfodeliverygroups/search/';

/**
 * 帳票画面
 */
// 棚卸帳票／検索画面(GET)
export const API_URL_SEARCH_INVENTORY_SUMMARYS =
  'https://zmb5ny9w8h.execute-api.ap-northeast-1.amazonaws.com/dev/inventorysummarys/search/';
// 棚卸帳票／IMD棚卸集計表作成・ダウンロード(GET)
export const API_URL_INVENTORY_SUMMARYS =
  'https://zmb5ny9w8h.execute-api.ap-northeast-1.amazonaws.com/dev/inventorysummarys/';
// IMD費用明細帳票／検索画面(GET)
export const API_URL_SEARCH_COST_SUMMARYS =
  'https://zmb5ny9w8h.execute-api.ap-northeast-1.amazonaws.com/dev/searchcostsummarys/';
// IMD費用明細帳票／IMD費用明細作成・ダウンロード(GET)
export const API_URL_COST_SUMMARYS =
  'https://zmb5ny9w8h.execute-api.ap-northeast-1.amazonaws.com/dev/costsummarys/';

/**
 * サマリー画面
 */
// コンテンツ／REST API(GET:詳細/POST:登録/PUT:更新/DELETE:削除)
export const API_URL_CONTENTS =
  'https://evaqe598b9.execute-api.ap-northeast-1.amazonaws.com/dev/contents/';
// コンテンツ／登録画面(GET)
export const API_URL_INPUT_CONTENTS =
  'https://evaqe598b9.execute-api.ap-northeast-1.amazonaws.com/dev/contents/input/';
// コンテンツリスト／コンテンツリスト情報取得(GET)
export const API_URL_CONTENTS_LIST =
  'https://evaqe598b9.execute-api.ap-northeast-1.amazonaws.com/dev/contents/list/';
// 回覧／REST API(GET:詳細/POST:登録/PUT:更新/DELETE:削除)
export const API_URL_CIRCULATIONS =
  'https://evaqe598b9.execute-api.ap-northeast-1.amazonaws.com/dev/circulations/';
// 回覧／登録画面(GET)
export const API_URL_INPUT_CIRCULATIONS =
  'https://evaqe598b9.execute-api.ap-northeast-1.amazonaws.com/dev/circulations/input/';
// 回覧リスト／回覧リスト情報取得(GET)
export const API_URL_CIRCULATIONS_LIST =
  'https://evaqe598b9.execute-api.ap-northeast-1.amazonaws.com/dev/circulations/list/';
// 回覧（開始条件判定）(GET)
export const API_URL_CIRCULATIONS_IS_STARTABLE =
  'https://evaqe598b9.execute-api.ap-northeast-1.amazonaws.com/dev/circulations/{0}/isStartable/';
// メンバー／REST API(POST:登録)
export const API_URL_MEMBERS =
  'https://evaqe598b9.execute-api.ap-northeast-1.amazonaws.com/dev/members/';
// メンバー／登録画面(GET)
export const API_URL_INPUT_MEMBERS =
  'https://evaqe598b9.execute-api.ap-northeast-1.amazonaws.com/dev/members/input/';
// メンバー／回答登録(PUT)
export const API_URL_MEMBERS_ANSWER =
  'https://evaqe598b9.execute-api.ap-northeast-1.amazonaws.com/dev/members/{0}/answer/';
// メンバー／メンバーリスト情報取得(GET)
export const API_URL_MEMBERS_LIST =
  'https://evaqe598b9.execute-api.ap-northeast-1.amazonaws.com/dev/members/list/';
// ファイル情報連携／REST API(GET:詳細)
export const API_URL_FILES =
  'https://evaqe598b9.execute-api.ap-northeast-1.amazonaws.com/dev/files/';
// AI連携／AIステータスチェック(GET)
export const API_URL_AI_COOPERATION_STATUS =
  'https://73wm89fnhf.execute-api.ap-northeast-1.amazonaws.com/dev/aicooperation/{0}/status/';
// AI連携／AIファイルアップロード(GET)
export const API_URL_AI_COOPERATION_FILE_UPLOAD =
  'https://73wm89fnhf.execute-api.ap-northeast-1.amazonaws.com/dev/aicooperation/';
// 回覧（ファイルID取得）(GET)
export const API_URL_CIRCULATIONS_FILEID =
  'https://evaqe598b9.execute-api.ap-northeast-1.amazonaws.com/dev/circulations/{0}/fileid';
// 商品管理（承認者取得）(GET)
export const API_URL_ITEMS_APPROVER =
  'https://evaqe598b9.execute-api.ap-northeast-1.amazonaws.com/dev/items/{0}/approver';
// 商品管理（グループメンバー取得）(GET)
export const API_URL_ITEMS_GROUP_MEMBER =
  'https://dehqxtpim0.execute-api.ap-northeast-1.amazonaws.com/dev/items/{0}/groupmember';

/**
 * 回覧画面
 */
// 回覧グループマスタ管理／REST API(GET:一覧/GET:詳細/POST:登録/PUT:更新/DELETE:削除)
export const API_URL_GROUPS =
  'https://evaqe598b9.execute-api.ap-northeast-1.amazonaws.com/dev/groups/';
// 回覧グループマスタ管理_拡張一覧
export const API_URL_GROUP_LISTS =
  'https://evaqe598b9.execute-api.ap-northeast-1.amazonaws.com/dev/grouplists/';
// 回覧グループマスタ管理／検索画面(GET)
export const API_URL_SEARCH_GROUPS =
  'https://evaqe598b9.execute-api.ap-northeast-1.amazonaws.com/dev/groups/search/';
// 回覧グループマスタ管理／登録画面(GET)
export const API_URL_INPUT_GROUPS =
  'https://evaqe598b9.execute-api.ap-northeast-1.amazonaws.com/dev/groups/input/';
// 回覧グループマスタ管理／回覧グループメンバー（ユーザ情報取得）(GET)
export const API_URL_GROUP_MEMBERS_GET_USER_INFO =
  'https://evaqe598b9.execute-api.ap-northeast-1.amazonaws.com/dev/groupmembers/getuserinfo/';
// 回覧グループメンバー管理／REST API(GET:詳細/POST:登録)
export const API_URL_GROUP_MEMBERS =
  'https://evaqe598b9.execute-api.ap-northeast-1.amazonaws.com/dev/groupmembers/';
// 回覧グループマスタ管理／回覧グループメンバー（入力チェック）(POST)
export const API_URL_GROUP_MEMBERS_CHECK_INPUT =
  'https://evaqe598b9.execute-api.ap-northeast-1.amazonaws.com/dev/groupmembers/checkinput/';

// 回覧ルート管理／REST API(GET:一覧/GET:詳細/POST:登録/PUT:更新/DELETE:削除)
export const API_URL_ROUTES =
  'https://evaqe598b9.execute-api.ap-northeast-1.amazonaws.com/dev/routes/';
// 回覧ルート管理_拡張一覧
export const API_URL_ROUTE_GROUP_LISTS =
  'https://evaqe598b9.execute-api.ap-northeast-1.amazonaws.com/dev/routegrouplists/';
// 回覧ルート管理／検索画面(GET)
export const API_URL_SEARCH_ROUTES =
  'https://evaqe598b9.execute-api.ap-northeast-1.amazonaws.com/dev/routes/search/';
// 回覧ルート管理／登録画面(GET)
export const API_URL_INPUT_ROUTES =
  'https://evaqe598b9.execute-api.ap-northeast-1.amazonaws.com/dev/routes/input/';
// 回覧ルート管理／全回覧ルート(GET)
export const API_URL_ROUTE_GROUPS_GET_GROUP_INFO =
  'https://evaqe598b9.execute-api.ap-northeast-1.amazonaws.com/dev/routegroups/getgroupinfo/';
// 回覧ルート管理／REST API(GET:詳細/POST:登録)
export const API_URL_ROUTE_GROUPS =
  'https://evaqe598b9.execute-api.ap-northeast-1.amazonaws.com/dev/routegroups/';
// 回覧ルート管理／回覧ルート（入力チェック）(POST)
export const API_URL_ROUTE_GROUPS_CHECK_INPUT =
  'https://evaqe598b9.execute-api.ap-northeast-1.amazonaws.com/dev/routegroups/checkinput/';

/**
 * 業務バリデーションチェック
 */
// 注文情報履歴／編集時業務チェック(POST)
export const API_URL_CHECK_EDIT_ORDER_RESULT =
  'https://pbmte74119.execute-api.ap-northeast-1.amazonaws.com/dev/checkeditorderresult/';

/**
 * サマリー
 */
// 販促資材マスタ管理／REST API(GET:一覧/GET:詳細/POST:登録/PUT:更新/DELETE:削除)
export const API_URL_SUMMARYLIST =
  'https://evaqe598b9.execute-api.ap-northeast-1.amazonaws.com/dev/summarylists/';

/**
 * My回覧
 */
// トップ画面／REST API(GET:一覧)
export const API_URL_MYCIRCULATION =
  'https://evaqe598b9.execute-api.ap-northeast-1.amazonaws.com/dev/circulations/mycirculation/';
