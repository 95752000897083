import { Component, OnInit } from '@angular/core';
import { DbOperationService } from 'src/app/shared/service/db-operation.service';
import {
  API_URL_WORK_ORDER_ITEM_STOCK_INFO,
  API_URL_WORK_ORDER_ITEM,
} from 'manager/http-constants_key';
import { WORK_ORDER_TEMPLATE } from 'manager/template-constant';
import { CommonService } from 'src/app/shared/service/common.service';
import { LoadingState } from 'src/app/shared/html-parts/loading/loading-state';
import { DeleteDialogService } from 'src/app/shared/html-parts/confirm-dialog/delete-dialog/delete-dialog.service';

@Component({
  selector: 'app-cart-item',
  templateUrl: './cart-item.component.html',
})

/**
 * 発注選択画面 販促資材リスト
 */
export class CartItemComponent implements OnInit {
  /* 画面用プロパティ */
  // 画面ヘッダー情報格納先
  columnOrder: any[] = new Array();

  // 画面検索結果一覧格納先
  searchResultsList: any[] = new Array();

  constructor(
    private dbOperationService: DbOperationService,
    private commonService: CommonService,
    private loadingState: LoadingState,
    private deleteDialogService: DeleteDialogService
  ) {}

  ngOnInit(): void {
    // 画面ロードフラグをON(ロード中状態)
    this.loadingState.loadStart();

    // ヘッダー情報取得処理(画面用)
    this.dbOperationService
      .getHeaderList(WORK_ORDER_TEMPLATE.ITEM_TEMPLATE_ID)
      .subscribe((response) => {
        this.columnOrder = response.body;
      });

    // 検索処理を実施
    this.searchResultCartItems();
  }

  /**
   * 検索処理
   * @param generateSearchItems 検索条件
   */
  public searchResultCartItems() {
    // 販促資材(WORK)一覧取得処理(画面用)
    this.dbOperationService
      .getData(
        API_URL_WORK_ORDER_ITEM_STOCK_INFO,
        WORK_ORDER_TEMPLATE.ITEM_TEMPLATE_ID,
        null
      )
      .subscribe((response) => {
        // コード値の一覧情報が存在するか否か
        if (!this.commonService.checkNoneResponse(response)) {
          // 販促資材(WORK)一覧情報のJSONをオブジェクトに格納する
          this.searchResultsList = response.body;
        } else {
          this.searchResultsList = new Array();
        }

        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadSleepEnd(0.3);
      });
  }

  /**
   * 削除処理
   * @param pkeyId IDコード(クリックされたID)
   */
  protected delete(pkeyId: string) {
    // 削除ダイアログにより削除処理
    this.deleteDialogService
      .deleteDialog(
        API_URL_WORK_ORDER_ITEM,
        this.commonService.getPkeyColumnName(this.columnOrder),
        pkeyId
      )
      .subscribe((reloadID) => {
        // 検索結果一覧から該当データを削除
        this.searchResultsList = this.commonService.deleteArrayObjectValue(
          this.searchResultsList,
          this.commonService.getPkeyColumnName(this.columnOrder),
          reloadID
        );
      });
  }
}
