<div class="register-stock-maintenance">
  <!-- 業務変更プルダウン-->
  <div class="dropDownArea">
    製作指示&nbsp;:&nbsp;
    <p-dropdown
      name="workChangeDropdown"
      styleClass="workChangeDropdown"
      [options]="workChangeInput?.dic_value"
      (onChange)="workChange()"
      optionLabel="name"
      optionValue="code"
      appendTo="body"
      [formControl]="workType"
    >
    </p-dropdown>
  </div>

  <app-csv-upload
    buttonName="在庫メンテナンス登録"
    tableId="stock_maintenance"
    [execType]="execType"
    [csvUploadHeaderTemplateId]="csvUploadHeaderTemplateId"
    [toleranceExtension]="toleranceExtension"
    [addData]="addData"
    fileName="在庫メンテナンスファイル"
    (captureFlag)="disabledWorkChange($event)"
  ></app-csv-upload>
</div>
