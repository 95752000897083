import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  API_URL_GROUP_MEMBERS,
  API_URL_GROUP_MEMBERS_GET_USER_INFO,
} from 'manager/http-constants_key';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CommonService } from 'src/app/shared/service/common.service';

@Injectable({
  providedIn: 'root',
})

/**
 * 回覧グループマスタサービス
 */
export class CirculationGroupService {
  constructor(private http: HttpClient, private commonService: CommonService) {}

  /**
   * 回覧グループメンバー対象ユーザ一覧取得
   */
  public getUser(): Observable<any> {
    // LambdaのEndpointを生成
    const url = this.commonService.url(API_URL_GROUP_MEMBERS_GET_USER_INFO);

    // コンテンツリストを返却する
    return this.http
      .get(url, { observe: 'response' })
      .pipe(map((res: HttpResponse<any>) => res));
  }

  /**
   * 登録済み回覧グループメンバー取得
   * @param groupId 回覧グループID
   */
  public getGroupMembers(groupId: string): Observable<any> {
    // LambdaのEndpointを生成
    const url = this.commonService.url(API_URL_GROUP_MEMBERS, groupId);

    // コンテンツリストを返却する
    return this.http
      .get(url, { observe: 'response' })
      .pipe(map((res: HttpResponse<any>) => res));
  }
}
