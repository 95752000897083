export const SECRETARIAT_OUTPUT_CONSTANT = {
  // ID値
  // 注文情報(通常,新規)
  ID_ORDER_NORMAL: 1,
  // 注文情報(送込)
  ID_ORDER_SEND: 2,
  // 在庫メンテナンス
  ID_STOCK_MAINTENANCE: 3,

  // 出力ID
  OUTPUT_ID: 'output_id',
  // 出力対象
  OUTPUT_NAME: 'output_name',
};

