<!-- コンテンツ情報-->
<label class="label">コンテンツ情報</label>
<!-- サマリー目次情報表示領域-->
<p-table
  [value]="summaryContentDisplayList"
  styleClass="p-datatable-striped summary-display"
>
  <!-- サマリー目次ボディ表示-->
  <ng-template pTemplate="body" let-summaryDisplay let-rowIndex="rowIndex">
    <!-- サマリー目次行出力-->
    <tr *ngIf="rowIndex < summaryRowContentDisplayNumber">
      <!-- サマリー目次列出力-->
      <ng-container *ngFor="let colNumber of summaryColContentDisplayNumber">
        <!-- カラム名称 -->
        <td class="summaryDisplayLabel">
          <!-- サマリー情報が存在するか否か -->
          <label
            *ngIf="
              summaryContentDisplayList[
                rowIndex + colNumber * summaryRowContentDisplayNumber
              ]
            "
          >
            <!-- サマリー情報が存在する場合 -->
            <!-- カラム名称を出力 -->
            {{
              summaryContentDisplayList[
                rowIndex + colNumber * summaryRowContentDisplayNumber
              ].column_name
            }}
          </label>
        </td>
        <!-- カラム内容 -->
        <td class="summaryDisplayData">
          <!-- サマリー情報が存在するか否か -->
          <label
            *ngIf="
              summaryContentDisplayList[
                rowIndex + colNumber * summaryRowContentDisplayNumber
              ]
            "
          >
            <!-- サマリー情報が存在する場合 -->

            <!-- カラム内容を出力 -->
            <!-- 数値出力 -->
            <span
              *ngIf="
                summaryContentDisplayList[
                  rowIndex + colNumber * summaryRowContentDisplayNumber
                ].input_type == 'NUMBER'
              "
            >
              {{
                summaryContentDisplayList[
                  rowIndex + colNumber * summaryRowContentDisplayNumber
                ].data | number
              }}
            </span>
            <!-- 文字出力 -->
            <span
              *ngIf="
                summaryContentDisplayList[
                  rowIndex + colNumber * summaryRowContentDisplayNumber
                ].input_type == 'VARCHAR'
              "
            >
              {{
                summaryContentDisplayList[
                  rowIndex + colNumber * summaryRowContentDisplayNumber
                ].data
              }}
            </span>
          </label>
        </td>
      </ng-container>

      <!-- ボタン表示領域 -->
      <ng-container *ngIf="rowIndex == 0">
        <td
          class="summaryDisplayButtonArea"
          [attr.rowspan]="summaryRowContentDisplayNumber"
        >
          <!-- 詳細ボタン-->
          <button
            type="button"
            pButton
            label="詳細"
            (click)="detail()"
            class="p-mr-2 summaryDisplayDefaultButton p-button-outlined"
          ></button>
          <!-- 更新ボタン-->
          <button
            type="button"
            pButton
            label="更新"
            (click)="update()"
            class="p-mr-2 summaryDisplayDefaultButton"
            *ngIf="updateButtonDisplayFlag()"
          ></button>
          <!-- 削除ボタン-->
          <button
            type="button"
            pButton
            label="削除"
            (click)="delete()"
            class="p-mr-2 summaryDisplayDefaultButton"
            *ngIf="deleteButtonDisplayFlag()"
          ></button>
        </td>
      </ng-container>
    </tr>
  </ng-template>
</p-table>
<!-- 回覧登録ボタン-->
<button
  type="button"
  pButton
  label="回覧登録"
  (click)="insertCirculation()"
  class="p-mr-2 summaryDisplayDefaultButton"
  *ngIf="insertCirculationDisplayFlag()"
></button>

<p-dataView
  [value]="summaryCirculationDisplayList"
  emptyMessage="回覧は存在しません"
  styleClass="circulation-display"
>
  <!-- 回覧リスト-->
  <ng-template let-circulation pTemplate="listItem">
    <div class="p-col-12">
      <!-- 回覧情報-->
      <div class="circulationListItem">
        <div class="circulationListDetail p-d-flex">
          <!-- 回覧名称-->
          <div
            class="circulationName"
            [class.endCirculation]="
              circulation.data.end_status == 1 ||
              circulation.data.end_status == 2 ||
              circulation.data.end_status == 3
            "
          >
            <div class="circulationLabel">
              {{ circulation.circulation_name }}
            </div>
          </div>
          <!-- 回覧情報-->
          <div class="circulationDetail">
            <!-- 回覧情報エリア-->
            <div class="detailArea p-as-start p-mt-auto">
              <div class="p-d-flex p-jc-between">
                <div class="comment">
                  <div>{{ circulation.data.comment }}</div>
                </div>
                <div>
                  <div>登録日：{{ circulation.data.create_datetime }}</div>
                  <div>回覧期日：{{ circulation.data.deadline_date }}</div>
                </div>
              </div>
            </div>
            <!-- 回覧ボタンエリア-->
            <div class="circulationButtonArea p-d-flex p-jc-between p-as-end">
              <div>
                <!-- 回覧状況ボタン-->
                <button
                  type="button"
                  pButton
                  label="回覧状況"
                  (click)="circulationStatus(circulation)"
                  class="p-mr-2 summaryDisplayDefaultButton"
                ></button>
                <!-- 回覧更新ボタン -->
                <button
                  type="button"
                  pButton
                  label="回覧更新"
                  (click)="updateSummaryCirculation(circulation)"
                  class="p-mr-2 summaryDisplayDefaultButton"
                  *ngIf="updateSummaryCirculationDisplayFlag(circulation)"
                ></button>
              </div>
              <div>
                <!-- 終了ラベル -->
                <div
                  class="end-circulation-type"
                  *ngIf="
                    circulation.data.end_status == 1 ||
                    circulation.data.end_status == 2 ||
                    circulation.data.end_status == 3
                  "
                >
                  <div class="end-type">回覧終了</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</p-dataView>

<!-- サマリー詳細.詳細画面 -->
<app-summary-display-detail></app-summary-display-detail>

<!-- サマリー詳細.入力画面 -->
<!-- 回覧登録時に使用 -->
<app-summary-content-display-input
  (reloadID)="getSummaryCirculationDisplayList()"
></app-summary-content-display-input>

<!-- 登録画面[入力項目生成(アイテムアダプター)]-->
<!-- コンテンツ編集時に使用 -->
<app-generate-input
  (reloadID)="getSummaryContentDisplay()"
></app-generate-input>
