import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_URL_ITEMS_GROUP_MEMBER } from 'manager/http-constants_key';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CommonService } from 'src/app/shared/service/common.service';

@Injectable({
  providedIn: 'root',
})

/**
 * サマリーコンテンツ詳細サービス
 */
export class SummaryDisplayContentService {
  constructor(private http: HttpClient, private commonService: CommonService) {}

  /**
   * 承認者リスト取得
   * @param itemId 案件ID
   * @param contentId コンテンツID
   */
  public getGroupMember(itemId: string, contentId: string): Observable<any> {
    // LambdaのEndpointを生成
    const url = this.commonService.url(
      API_URL_ITEMS_GROUP_MEMBER,
      itemId,
      '?content_id=',
      contentId,
      '&role=',
      '1'
    );

    // 承認者リストを返却する
    return this.http
      .get(url, { observe: 'response' })
      .pipe(map((res: HttpResponse<any>) => res));
  }
}
